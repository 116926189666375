const jobs = [
  {
    id: 1,
    jobTitle: "User Experience Designer",
    location: "London, UK",
    createdAt: "1617828660000",
    experienceLevel: "Entry Level",
    employmentType: "Full-Time",
    Applications: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 12, 11],
    salary: {
      start: '10000',
      end: '45000'
    },
    candidates: [
      {
        fullName: "Jamaine Chowdry",
        photoUrl: "https://tapobucket.s3.eu-west-2.amazonaws.com/26c9151bbe.jpg",
        experience: [
          {
            company: {
              name: 'Senior UX'
            },
            startDate: "2021-04-27T21:20:46.402Z",
            endDate: "2021-04-27T21:21:39.376Z",
            workHere: true
          },
          {
            company: {
              name: 'Senior UX'
            },
            startDate: "2020-01-01T21:21:50.000Z",
            endDate: "2021-04-27T21:21:50.840Z",
            workHere: false
          },
        ],
        education: [
          {
            degree: 'User Experience Desing',
            school: 'University of Londo',
            startDate: "2021-01-01T21:22:34.000Z",
            endDate: "2021-04-27T21:22:34.433Z"
          },
          {
            degree: 'User Experience Desing',
            school: 'University of Londo',
            startDate: "2021-01-01T21:22:34.000Z",
            endDate: "2021-04-27T21:22:34.433Z"
          }
        ],
        shortlisted: false,
        country: "United Kingdom",
        city: "Leeds"
      },
      {
        fullName: "Yenifer Valderrama",
        photoUrl: "https://tapobucket.s3.eu-west-2.amazonaws.com/7575b66341.jpg",
        experience: [
          {
            company: {
              name: 'Senior UX'
            },
            startDate: "2021-04-27T21:20:46.402Z",
            endDate: "2021-04-27T21:21:39.376Z",
            workHere: true
          },
          {
            company: {
              name: 'Senior UX'
            },
            startDate: "2020-01-01T21:21:50.000Z",
            endDate: "2021-04-27T21:21:50.840Z",
            workHere: false
          },
          {
            company: {
              name: 'Senior UX'
            },
            startDate: "2020-01-01T21:21:50.000Z",
            endDate: "2021-04-27T21:21:50.840Z",
            workHere: false
          },
          {
            company: {
              name: 'Senior UX'
            },
            startDate: "2020-01-01T21:21:50.000Z",
            endDate: "2021-04-27T21:21:50.840Z",
            workHere: false
          },
          {
            company: {
              name: 'Senior UX'
            },
            startDate: "2020-01-01T21:21:50.000Z",
            endDate: "2021-04-27T21:21:50.840Z",
            workHere: false
          },
        ],
        education: [],
        shortlisted: true,
        country: "United Kingdom",
        city: "Leeds"
      },
      {
        fullName: "Simon Jackson",
        photoUrl: "https://tapobucket.s3.eu-west-2.amazonaws.com/90d834c59d.jpg",
        experience: [
          {
            company: {
              name: 'Senior UX'
            },
            startDate: "2021-01-27T21:20:46.402Z",
            endDate: "2021-04-27T21:21:39.376Z",
            workHere: false
          },
          {
            company: {
              name: 'Senior UX'
            },
            startDate: "2020-01-01T21:21:50.000Z",
            endDate: "2021-04-27T21:21:50.840Z",
            workHere: false
          }
        ],
        education: [
          {
            degree: 'User Experience Desing',
            school: 'University of Londo',
            startDate: "2021-01-01T21:22:34.000Z",
            endDate: "2021-04-27T21:22:34.433Z"
          }
        ],
        shortlisted: true,
        country: "United Kingdom",
        city: "Leeds"
      }
    ]
  },
  {
    id: 2,
    jobTitle: "Seniour Growth Analyst",
    location: "London, UK",
    createdAt: "1618089520000",
    experienceLevel: "Senior",
    employmentType: "Full-Time",
    Applications: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 12, 11, 13, 14, 15, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 12, 11, 13, 14, 15, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 12, 11, 13, 14, 15],
    salary: {
      start: '25000',
      end: '87000'
    },
    closingDate: '1622406395',
    candidates: [
      {
        fullName: "Jamaine Chowdry",
        photoUrl: "https://tapobucket.s3.eu-west-2.amazonaws.com/26c9151bbe.jpg",
        experience: [
          {
            company: {
              name: 'Senior UX'
            },
            startDate: "2021-04-27T21:20:46.402Z",
            endDate: "2021-04-27T21:21:39.376Z",
            workHere: true
          },
          {
            company: {
              name: 'Senior UX'
            },
            startDate: "2020-01-01T21:21:50.000Z",
            endDate: "2021-04-27T21:21:50.840Z",
            workHere: false
          },
        ],
        education: [
          {
            degree: 'User Experience Desing',
            school: 'University of Londo',
            startDate: "2021-01-01T21:22:34.000Z",
            endDate: "2021-04-27T21:22:34.433Z"
          },
          {
            degree: 'User Experience Desing',
            school: 'University of Londo',
            startDate: "2021-01-01T21:22:34.000Z",
            endDate: "2021-04-27T21:22:34.433Z"
          }
        ],
        shortlisted: true,
        country: "United Kingdom",
        city: "Leeds",
      },
      {
        fullName: "Yenifer Valderrama",
        photoUrl: "https://tapobucket.s3.eu-west-2.amazonaws.com/7575b66341.jpg",
        experience: [
          {
            company: {
              name: 'Senior UX'
            },
            startDate: "2021-04-27T21:20:46.402Z",
            endDate: "2021-04-27T21:21:39.376Z",
            workHere: true
          },
          {
            company: {
              name: 'Senior UX'
            },
            startDate: "2020-01-01T21:21:50.000Z",
            endDate: "2021-04-27T21:21:50.840Z",
            workHere: false
          },
          {
            company: {
              name: 'Senior UX'
            },
            startDate: "2020-01-01T21:21:50.000Z",
            endDate: "2021-04-27T21:21:50.840Z",
            workHere: false
          },
          {
            company: {
              name: 'Senior UX'
            },
            startDate: "2020-01-01T21:21:50.000Z",
            endDate: "2021-04-27T21:21:50.840Z",
            workHere: false
          },
          {
            company: {
              name: 'Senior UX'
            },
            startDate: "2020-01-01T21:21:50.000Z",
            endDate: "2021-04-27T21:21:50.840Z",
            workHere: false
          },
        ],
        education: [],
        shortlisted: false,
        country: "United Kingdom",
        city: "Leeds"
      },
      {
        fullName: "Simon Jackson",
        photoUrl: "https://tapobucket.s3.eu-west-2.amazonaws.com/90d834c59d.jpg",
        experience: [
          {
            company: {
              name: 'Senior UX'
            },
            startDate: "2021-01-27T21:20:46.402Z",
            endDate: "2021-04-27T21:21:39.376Z",
            workHere: false
          },
          {
            company: {
              name: 'Senior UX'
            },
            startDate: "2020-01-01T21:21:50.000Z",
            endDate: "2021-04-27T21:21:50.840Z",
            workHere: false
          }
        ],
        education: [
          {
            degree: 'User Experience Desing',
            school: 'University of Londo',
            startDate: "2021-01-01T21:22:34.000Z",
            endDate: "2021-04-27T21:22:34.433Z"
          }
        ],
        shortlisted: false,
        country: "United Kingdom",
        city: "Leeds"
      }
    ]
  },
  {
    id: 3,
    jobTitle: "Front End Developer",
    location: "London, UK",
    createdAt: "1617571120000",
    experienceLevel: "Senior",
    employmentType: "Full-Time",
    Applications: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 12, 11, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22],
    salary: {
      start: '56000',
      end: '100000'
    },
    candidates: [
      {
        fullName: "Jamaine Chowdry",
        photoUrl: "https://tapobucket.s3.eu-west-2.amazonaws.com/26c9151bbe.jpg",
        experience: [
          {
            company: {
              name: 'Senior UX'
            },
            startDate: "2021-04-27T21:20:46.402Z",
            endDate: "2021-04-27T21:21:39.376Z",
            workHere: true
          },
          {
            company: {
              name: 'Senior UX'
            },
            startDate: "2020-01-01T21:21:50.000Z",
            endDate: "2021-04-27T21:21:50.840Z",
            workHere: false
          },
        ],
        education: [
          {
            degree: 'User Experience Desing',
            school: 'University of Londo',
            startDate: "2021-01-01T21:22:34.000Z",
            endDate: "2021-04-27T21:22:34.433Z"
          },
          {
            degree: 'User Experience Desing',
            school: 'University of Londo',
            startDate: "2021-01-01T21:22:34.000Z",
            endDate: "2021-04-27T21:22:34.433Z"
          }
        ],
        shortlisted: false,
        country: "United Kingdom",
        city: "Leeds"
      },
      {
        fullName: "Yenifer Valderrama",
        photoUrl: "https://tapobucket.s3.eu-west-2.amazonaws.com/7575b66341.jpg",
        experience: [
          {
            company: {
              name: 'Senior UX'
            },
            startDate: "2021-04-27T21:20:46.402Z",
            endDate: "2021-04-27T21:21:39.376Z",
            workHere: true
          },
          {
            company: {
              name: 'Senior UX'
            },
            startDate: "2020-01-01T21:21:50.000Z",
            endDate: "2021-04-27T21:21:50.840Z",
            workHere: false
          },
          {
            company: {
              name: 'Senior UX'
            },
            startDate: "2020-01-01T21:21:50.000Z",
            endDate: "2021-04-27T21:21:50.840Z",
            workHere: false
          },
          {
            company: {
              name: 'Senior UX'
            },
            startDate: "2020-01-01T21:21:50.000Z",
            endDate: "2021-04-27T21:21:50.840Z",
            workHere: false
          },
          {
            company: {
              name: 'Senior UX'
            },
            startDate: "2020-01-01T21:21:50.000Z",
            endDate: "2021-04-27T21:21:50.840Z",
            workHere: false
          },
        ],
        education: [],
        shortlisted: true,
        country: "United Kingdom",
        city: "Leeds"
      },
      {
        fullName: "Simon Jackson",
        photoUrl: "https://tapobucket.s3.eu-west-2.amazonaws.com/90d834c59d.jpg",
        experience: [
          {
            company: {
              name: 'Senior UX'
            },
            startDate: "2021-01-27T21:20:46.402Z",
            endDate: "2021-04-27T21:21:39.376Z",
            workHere: false
          },
          {
            company: {
              name: 'Senior UX'
            },
            startDate: "2020-01-01T21:21:50.000Z",
            endDate: "2021-04-27T21:21:50.840Z",
            workHere: false
          }
        ],
        education: [
          {
            degree: 'User Experience Desing',
            school: 'University of Londo',
            startDate: "2021-01-01T21:22:34.000Z",
            endDate: "2021-04-27T21:22:34.433Z"
          }
        ],
        shortlisted: false,
        country: "United Kingdom",
        city: "Leeds"
      }
    ]
  }
]

const interviews = [
  {
    title: "Experience Designer Job Position",
    duration: "9:00AM - 10:00AM",
    createdAt: "1618855323000",
    user: {
      name: "Jamaine Chowdry",
      photoUrl: "https://tapobucket.s3.eu-west-2.amazonaws.com/26c9151bbe.jpg",
    },
  },
  {
    title: "Seniour Growth Specialist Position",
    duration: "9:00AM - 10:00AM",
    createdAt: "1619546523000",
    user: {
      name: "Austin Evans",
      photoUrl: "https://tapobucket.s3.eu-west-2.amazonaws.com/3f92fab2b4.jpg",
    },
  },
  {
    title: "Seniour Growth Specialist Position",
    duration: "9:00AM - 10:00AM",
    createdAt: "1619546523000",
    user: {
      name: "Austin Evans",
      photoUrl: "https://tapobucket.s3.eu-west-2.amazonaws.com/3f92fab2b4.jpg",
    },
  },
];


const admins = [
  {
    id: 1,
    fullName: "Jamaine Chowdry",
    photoUrl: "https://tapobucket.s3.eu-west-2.amazonaws.com/26c9151bbe.jpg",
    roles: [
      { as: "admin" }
    ]
  },
  {
    id: 2,
    fullName: "Austin Evans",
    avatarUrl: "https://tapobucket.s3.eu-west-2.amazonaws.com/3f92fab2b4.jpg",
    roles: [
      { as: "Hiring Manager" }
    ]
  },
  {
    id: 3,
    fullName: "Kammie Lewis",
    photoUrl: "https://tapobucket.s3.eu-west-2.amazonaws.com/26c9151bbe.jpg",
    roles: [
      { as: "Hiring Manager" }
    ]
  },
  {
    id: 4,
    fullName: "Gaston Caldera",
    photoUrl: "https://tapobucket.s3.eu-west-2.amazonaws.com/e3659a87ba.jpg",
    roles: [
      { as: "Divisional Manager" }
    ]
  }
]

const allSkills = [
  { value: "General", label: "User Interface Design" },
  { value: "General", label: "Foreign Languages" },
  { value: "General", label: "Social Media" },
  { value: "General", label: "Teaching" },
  { value: "General", label: "Project Management" },
  { value: "General", label: "Computer Technology" },
  { value: "General", label: "Accounting & Finance" },
  { value: "General", label: "Business & Data Analysis" },
  { value: "General", label: "Nursing" },
  { value: "General", label: "Economics" },
  { value: "General", label: "Automotive Services, Parts and Design" },
  { value: "General", label: "SEO/SEM Marketing" },
  { value: "General", label: "Cloud and Distributed Computing" },
  { value: "General", label: "Data Presentation" },
  { value: "General", label: "Database Management and Software" },
  { value: "General", label: "Electronic and Electrical Engineering" },
  { value: "General", label: "Copywriting" },
  { value: "General", label: "Statistical Analysis and Data Mining" },
  { value: "General", label: "PHP" },
  { value: "General", label: "CSS/HTML" },
  { value: "General", label: "Ruby" },
  { value: "General", label: "Python" },
  { value: "General", label: "Perl" },
  { value: "General", label: "C/C++" },
  { value: "General", label: "JavaScript" },
  { value: "General", label: "Java" },
  { value: "General", label: "Engineering" },
  { value: "General", label: "Technical Writing" },
  { value: "General", label: "Human Resources (HR)" },
  { value: "General", label: "Objective C" },
  { value: "General", label: "Google Analytics" },
  { value: "General", label: "Android Development" },
  { value: "General", label: "IOS Development" },
  { value: "General", label: "Design Research" },
  { value: "Tools", label: "Adobe Xd" },
  { value: "Tools", label: "Adobe Illustrator" },
  { value: "Tools", label: "Wireframes" },
  { value: "Soft skills", label: "Communication" },
  { value: "Soft skills", label: "Collaboration" },
  { value: "Soft skills", label: "Research" },
  { value: "Soft skills", label: "Public Speaking" },
  { value: "Soft skills", label: "Cultural Intelligence" },
  { value: "Soft skills", label: "Analytical Thinking" },
  { value: "Soft skills", label: "Multi-tasking" },
  { value: "Soft skills", label: "Conflict Management" },
  { value: "Soft skills", label: "Empathy" },
  { value: "Soft skills", label: "Self-starter" },
  { value: "Soft skills", label: "Strategic Planning" },
];

const myConv = [
  {
    id: '12345',
    name: 'gaston caldera',
    userOne: '54321',
    userTwo: '12345',
    isConnect: true,
    photoUrl: 'https://tapobucket.s3.eu-west-2.amazonaws.com/db70a30f18.jpg',
    sentAt: "1621278380"
  },
  {
    id: '123456789',
    name: 'Joshua Fortune Eki',
    userOne: '54321',
    userTwo: '12345',
    isConnect: true,
    photoUrl: 'https://tapobucket.s3.eu-west-2.amazonaws.com/26c9151bbe.jpg',
    sentAt: "1621278380"
  },
  {
    id: '123451234',
    name: 'Dumnoi Ikechukwu',
    userOne: '54321',
    userTwo: '12345',
    isConnect: false,
    photoUrl: 'https://tapobucket.s3.eu-west-2.amazonaws.com/af25b80544.jpg',
    sentAt: "1621278380"
  },
]

const chats = [
  {
    name: "Gaston",
    sentAt: '1621105580',
    messageContent: 'Hello world',
    senderId: '123456',
    photoUrl: 'https://tapobucket.s3.eu-west-2.amazonaws.com/af25b80544.jpg',
  },
  {
    name: "Pedro",
    sentAt: '1621278380',
    messageContent: 'Hello world Hello worldHello worldHello world  Hello world Hello world Hello world Hello world Hello world Hello world Hello world Hello world Hello world',
    senderId: '123',
    photoUrl: 'https://tapobucket.s3.eu-west-2.amazonaws.com/26c9151bbe.jpg',
  },
  {
    name: "Gaston",
    sentAt: '1621278380',
    messageContent: 'Hello world',
    senderId: '123456',
    photoUrl: 'https://tapobucket.s3.eu-west-2.amazonaws.com/af25b80544.jpg',
  },
  {
    name: "Gaston",
    sentAt: '1621278380',
    messageContent: 'Hello world',
    senderId: '123456',
    photoUrl: 'https://tapobucket.s3.eu-west-2.amazonaws.com/af25b80544.jpg',
  },
  {
    name: "Pedro",
    sentAt: '1621278380',
    messageContent: 'Hello world',
    senderId: '123',
    photoUrl: 'https://tapobucket.s3.eu-west-2.amazonaws.com/26c9151bbe.jpg',
  },
  {
    name: "Gaston",
    sentAt: '1621278380',
    messageContent: 'Hello world',
    senderId: '123456',
    photoUrl: 'https://tapobucket.s3.eu-west-2.amazonaws.com/af25b80544.jpg',
  }
]




export { jobs, interviews, admins, allSkills, myConv, chats }

