import React from "react";
import { makeStyles } from "@material-ui/core";
import AppText from "../atoms/AppText";

export default function AppInput({
    label,
    onChange,
    value,
    name,
    onBlur,
    placeholder,
    editable = true,
    onKeyUp,
    hasError = false,
    onlyNumber = false,
    height
}) {
    const useSyles = makeStyles({
        label: { marginBottom: "10px" },
        root: {
            appearance: "none",
            borderRadius: "4px",
            border: hasError ? "1px solid #E6492D" : "1px solid #D8DCE6",
            color: name === "email" && !editable ? "#ddd" : "#3E3F42",
            fontFamily: "Roboto",
            height: height,
            marginTop: "-2px",
            outline: "none",
            padding: "10px",
            resize: "none",
            whiteSpace: "pre-line",
            width: "100%",
            "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
                border: "none",
            },
            "& .MuiOutlinedInput-notchedOutline": {
                border: "none",
                maxHeight: "500px",
            }
        }
    });
    const classes = useSyles();

    const handleChange = (e) => {
        if (onlyNumber) {
            if (e.target.value.match(/^[0-9]{0,4}$/g)) {
                onChange(e)
            }
        }
        else {
            onChange(e)
        }
    }
    return (
        <div>
            {label !== undefined && (
                <div className={classes.label}>
                    <AppText size='_12' color='_c50' weight='_medium'>
                        {label}
                    </AppText>
                </div>
            )}

            <div>
                <textarea
                    value={value}
                    name={name}
                    placeholder={placeholder}
                    onChange={(e) => handleChange(e)}
                    onBlur={onBlur}
                    className={`${classes.root} _inp`}
                    onKeyUp={onKeyUp}
                />
            </div>
        </div>
    );
}
