import React from "react";
import AppText from "../atoms/AppText";
import SmartphoneIcon from '@material-ui/icons/Smartphone';
import ArrowRightAltRoundedIcon from '@material-ui/icons/ArrowRightAltRounded';
import DesktopWindowsRoundedIcon from '@material-ui/icons/DesktopWindowsRounded';

export default function Mobile() {
    return (
        <div className={'w-100 d-flex justify-content-center align-items-center'} style={{ height: '92vh' }}>
            <div className={'card_style w-90'} style={{ maxWidth: "380px"}}>
                <div className={'d-flex w-100 pt-3 px-3 justify-content-center align-items-center'}>
                    <span
                        style={{
                            borderRadius: "100%",
                            border: "2px solid #9EA0A580",
                            height: '56px',
                            width: "56px",
                            background: "#9EA0A50D"
                        }}
                        className={'d-flex justify-content-center align-items-center mb-3'}
                    >
                        <SmartphoneIcon style={{ color: "#9EA0A580", fontSize: "24px" }} />
                    </span>
                    <span
                        style={{
                            height: '56px',
                            width: "56px",
                        }}
                        className={'d-flex justify-content-center align-items-center mb-3'}
                    >
                        <ArrowRightAltRoundedIcon style={{ color: "#9EA0A580", fontSize: "24px" }} />
                    </span>
                    <span
                        style={{
                            borderRadius: "100%",
                            border: "2px solid #03CA9880",
                            height: '56px',
                            width: "56px",
                            background: "#03CA980D"
                        }}
                        className={'d-flex justify-content-center align-items-center mb-3'}
                    >
                        <DesktopWindowsRoundedIcon style={{ color: "#62cbb1", fontSize: "24px" }} />
                    </span>
                </div>
                <div className={'px-4 pb-3'}>
                    <AppText size='_20' width={'_medium'} align={'_center'}>Log into your Recruiter account via a PC or Desktop</AppText>
                </div>
                <div className={'px-4 pb-5'}>
                    <AppText size='_14' align={'_center'}>Our Recruiter platform is only available on the web for Desktop or PC for now. We will let you know when it is available for mobile.</AppText>
                </div>
            </div>
        </div>
    );
}
