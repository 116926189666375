import React, { useEffect, useState } from "react";
import { Grid } from '@mui/material'
import { useQuery, gql } from "@apollo/client";
import { useHistory } from 'react-router-dom'
import JobsListExternal from "../../../components/molecules/JobsListExternal";
import AppText from "../../../components/atoms/AppText";
import ExternalLoading from "../../../components/orgnisms/ExternalLoading"

const GET_MY_JOBS = gql`
  query($input: ID!) {
    getCompanyJobs(companyId: $input) {
      id
        jobTitle
        location
        closingDate
        createdAt
        experienceLevel
        employmentType
        applicationsCount
        salaryRange {
          defaultType
          start
          end
        }
        company {
          logo
          name
        }
    }
  }
`;


export default function JobsList() {
  const history = useHistory()
  const companyId = history.location.pathname.replace('/jobs/', '')
  const [myJobs, setMyJobs] = useState([])
  const {
    loading,
    data: jobsData,
  } = useQuery(GET_MY_JOBS, {
    variables: {
      input: companyId
    }
  });

  useEffect(() => {
    if (!loading && jobsData) {
      setMyJobs(jobsData.getCompanyJobs)
    }
  }, [loading, jobsData])

  return (
    <Grid
      container
      direction="column"
      justifyContent="center"
    >
      {loading ? (
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
          p={2}
          sx={{ width: "100%" }}
        >
          <ExternalLoading />
        </Grid>
      ) : (
        <>
          <Grid
            container
            direction="column"
            sx={{ width: "100%" }}
          >
            {myJobs.map((job) => {
              return (
                <JobsListExternal job={job} moreInfo={true} />
              )
            })}
          </Grid>
          <Grid
            container
            direction="row"
            alignItems="center"
            justifyContent="flex-end"
            p={1}
          >
            <AppText size={'_12'} color={'_c50'}>Powered by TapRecruit</AppText>
          </Grid>
        </>
      )}
    </Grid>
  );
}
