import React, { useState, useMemo } from 'react'

const AccountSetupContext = React.createContext()

export const AccountSetupContextProvider = (props) => {
    const [accountSetup, setAccountSetup] = useState({})
    
    const value = useMemo(() => {
        return {
            accountSetup,
            setAccountSetup
        }
    }, [accountSetup])

    return <AccountSetupContext.Provider value={value} {...props} />
}

export const useAccountSetup = () => {
    const context = React.useContext(AccountSetupContext)
    if (!context) {
        return null
    }
    return context
}
