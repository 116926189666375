import React, { useEffect, useRef, useState } from "react";
import { useMediaQuery } from "react-responsive";
import { useLocation, useParams } from "react-router-dom";
import { gql, useQuery, useMutation, useSubscription } from "@apollo/client";
import { useSelector, useDispatch } from "react-redux";
import Linkify from 'react-linkify';
import { useFormik } from "formik";
import * as Yup from "yup";
import AppText from "../../../components/atoms/AppText";
import AppButton from "../../../components/molecules/AppButton";
import { Avatar, TextareaAutosize } from "@material-ui/core";
import { setChats } from "../ChatsDucks";
import { getTime, getWindowDimensions, randomColor, returnInitals, UpperCaseFirst } from '../../../utils/functions/index'

const GET_CHATS = gql`
  query ($id: ID!) {
    getChats(convoId: $id) {
      id
      messageType
      conversationId
      messageContent
      senderId
      receiverId
      sentAt
      seen
    }
  }
`;

const SEND_MESSEGE = gql`
  mutation ($input: MessageInput) {
    sendMessage(input: $input) {
      id
    }
  }
`;

const GRAB_MESSAGES = gql`
  subscription ($id: ID!) {
    messageListener(conversationId: $id) {
      messageContent
      messageType
      receiverId
      senderId
      sentAt
    }
  }
`;

const SEND_STATUS_NOTIFICATION = gql`
  mutation ($input: NoticationInput) {
    sendNotification(input: $input) {
      id
    }
  }
`;

const SET_SEEN = gql`
  mutation ($input: ChatSeenInput) {
    setChatSeen(input: $input)
  }
`;


export default function ChatArea() {
  const isMobile = useMediaQuery({ maxWidth: 768 });
  const screenSize = getWindowDimensions()
  const dispatch = useDispatch();

  const messageAreaRef = useRef(null);
  const { id } = useParams()
  const { state } = useLocation();
  const { user } = useSelector((state) => state.app);
  const [chats, setChat] = useState([]);
  const [mouseMoveCount, setCount] = useState(0);

  const { loading: loadingChat, data: dataChat } = useQuery(GET_CHATS, {
    variables: { id: id }, pollInterval: 500,
  });

  const [sendStatusNotification] = useMutation(SEND_STATUS_NOTIFICATION);
  const [sendMessage] = useMutation(SEND_MESSEGE);
  const { loading, data, error: subError } = useSubscription(GRAB_MESSAGES, {
    variables: { id: id },
    fetchPolicy: "no-cache",
  });
  const [setChateen] = useMutation(SET_SEEN);

  const formik = useFormik({
    initialValues: {
      message: "",
    },
    validationSchema: Yup.object().shape({
      message: Yup.string().required(),
    }),
    onSubmit: (value, { resetForm }) => {
      resetForm();
      if (value.message !== chats[chats.length - 1]?.messageContent) {
        sendMessage({
          variables: {
            input: {
              senderId: user.id,
              receiverId: state.seeker.id,
              conversationId: id,
              messageType: "text",
              messageContent: value.message,
            },
          },
        })
          .then(() => {
            sendStatusNotification({
              variables: {
                input: {
                  string: value.message,
                  action: 1,
                  toUser: state.seeker.id,
                  senderFullName: user.fullName,
                  senderPhotoUrl: user.avatarUrl || "",
                },
              },
            });
            resetForm();
          });
      }
    },
  });

  const scrollToRef = () => {
    const divToScrollTo = document.getElementById(`bottom`)
    divToScrollTo.scrollIntoView();
  };

  const onEnterPress = (e) => {
    if (e.keyCode === 13 && e.shiftKey === false) {
      e.preventDefault();
      formik.submitForm()
    }
  }

  useEffect(() => {
    setChateen({ variables: { input: { manager: user.id, conversationId: id } } });
  }, [user.id, setChateen, id, mouseMoveCount]);

  useEffect(() => {
    if (!loading && !subError) {
      setChat([...chats, data.messageListener]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading, data]);

  useEffect(() => {
    if (!loadingChat && dataChat) {
      setChat(dataChat.getChats);
      dispatch(setChats(dataChat.getChats))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadingChat, dataChat, dispatch]);

  useEffect(() => {
    scrollToRef();
  }, [chats])

  return (
    <div className='w-100' onMouseMove={() => setCount(mouseMoveCount + 1)} style={{ height: screenSize.height - 50 }}>
      <header className='chat_header d-flex align-items-center justify-content-between botm ltm px-4 mt-5'>
        <AppText size='_16'>{state?.seeker ? `${state?.seeker?.firstName} ${state?.seeker?.lastName}` : "Tap User"}</AppText>
      </header>
      <div className='px-4 _scr ltm pt-2' ref={messageAreaRef} style={{ maxHeight: screenSize.height - 196 }}>
        {loadingChat && <AppText>loading...</AppText>}
        {!loadingChat && dataChat
          ? chats.map((chat, i) => {
            if (chat.conversationId !== id) {
              return null
            }
            else if (chats[i - 1]?.messageContent === chat.messageContent) {
              return null
            }
            return (
              <div
                key={i}
                className={`d-flex flex-column ${user.id === chat.senderId ? "align-items-end" : "align-items-start"
                  }`}
              >
                {chats[i - 1]?.senderId !== chat.senderId && (
                  <div className={"d-flex align-items-center"}>
                    <Avatar
                      style={{
                        height: "28px",
                        width: "28px",
                        marginRight: "15px",
                        backgroundColor: randomColor(user.id === chat.senderId ? user.fullName : `${state?.seeker?.firstName} ${state?.seeker?.lastName}`)
                      }}
                      src={user.id === chat.senderId ? user.avatarUrl : state?.seeker?.photoUrl}

                    >
                      <AppText size='_14' color='_c0'>
                        {user.id === chat.senderId ?
                          returnInitals(user.fullName)
                          : returnInitals(`${UpperCaseFirst(state?.seeker?.firstName)} ${UpperCaseFirst(state?.seeker?.lastName)}`)
                        }
                      </AppText>
                    </Avatar>
                    <span className={"d-flex align-items-center mr-3"}>
                      <AppText size='_12' weight={"_medium"}>
                        {user.id === chat.senderId
                          ? user.fullName
                          : `${state?.seeker?.firstName} ${state?.seeker?.lastName}`}
                      </AppText>
                    </span>
                    <AppText size='_12' color='_c90'>
                      {getTime(chat.sentAt)}
                    </AppText>
                  </div>
                )}
                <span
                  className={user.id === chat.senderId ? "_myMsg px-3 py-1" : "_themMsg px-3 py-1"}
                >
                  <AppText size='_14'><Linkify>{chat.messageContent}</Linkify></AppText>
                </span>
              </div>
            )
          })
          : null}
        <div className='mt-3' ref={messageAreaRef} id={'bottom'} />
      </div>
      <div className={`bleft _boz px-4 py-3`} style={{ background: '#fbfbfd' }}>
        <form onSubmit={formik.handleSubmit} className='position-relative'>
          <div className={`_h55 d-flex align-items-center`}>
            <TextareaAutosize
              name='message'
              rowsMax={4}
              placeholder='Type a message…'
              value={formik.values.message}
              onChange={formik.handleChange}
              className={`_inp ${isMobile ? 'w-70' : 'w-90'} p-2 px-3`}
              onKeyDown={onEnterPress}
            />
          </div>
          {/* <div className='_sep_ mx-2' />
          <div className='_h55 d-flex align-items-center'></div> */}
          <div className='_sbut'>
            <AppButton type='submit'>
              <AppText size='_14' color='_c0'>
                Send
              </AppText>
            </AppButton>
          </div>
        </form>
      </div>
    </div >
  );
}
