import React from "react";
import { BrowserRouter, Switch } from "react-router-dom";
import { useSelector } from "react-redux";
import { fire } from "../firebase";
import { useDispatch } from "react-redux";
import { useLazyQuery, gql, useMutation } from "@apollo/client";
import { useMediaQuery } from "react-responsive";

import SetupStack from "./account-setup";
import AuthStack from "./auth";
import EmailVerification from "./auth/Templates/EmailVerification";
import HomeStack from "./home";
import Loading from "../components/orgnisms/Loading";
import HeaderLogin from "../components/orgnisms/HeaderLogin";
import { changeAppState, setUser } from "./ducks";
import Mobile from "../components/molecules/Mobile";

const GET_CURRENT_MANAGER = gql`
  query ($uid: String!) {
    getCurrentManager(uid: $uid) {
      id
      emailVerified
      accountComplete
      fullName
      email
      firstName
      lastName
      country
      city
      avatarUrl
      notificationsLastSeen
      roles {
        company {
          id
          name
          size
          services
          websiteLink
          instagramLink
          facebookLink
          linkedinLink
          twitterLink
          culture
          logo
          header
          founded
          bio
          overview
          location
          paidCareerPages {
            paid
            stripeCustomerId
          }
        }
        as
      }
    }
  }
`;

const MEMBER_SIGNIN = gql`
  query ($email: String!) {
    canMemberSignin(email: $email)
  }
`;
const SEND_MANAGER_OUT = gql`
  mutation ($email: String!) {
    sendManagerOut(email: $email)
  }
`;

export default function Router() {
  const isDesktop = useMediaQuery({ minWidth: 992 });
  const dispatch = useDispatch();
  const { loggedin, accountCompleted, emailverified } = useSelector(
    (state) => state.app
  );
  const [appLoaded, setAppLoaded] = React.useState(false);
  const [getUser, { loading, data }] = useLazyQuery(GET_CURRENT_MANAGER, {
    pollInterval: "2000",
  });
  const [canMemberSignin, { loading: memberLoading, data: memberData }] =
    useLazyQuery(MEMBER_SIGNIN);
  const [sendManagerOut] = useMutation(SEND_MANAGER_OUT);

  React.useEffect(() => {
    fire.auth().onAuthStateChanged((user) => {
      if (user) {
        getUser({
          variables: { uid: user.uid },
        });
        if (!loading && data) {
          if (data.getCurrentManager) {
            dispatch(changeAppState({ loggedin: true }));
            if (data.getCurrentManager.emailVerified) {
              dispatch(changeAppState({ emailverified: true }));
            }
            if (
              data.getCurrentManager.emailVerified &&
              data.getCurrentManager.accountComplete
            ) {
              // is account completed?
              if (data.getCurrentManager.roles.length) {
                const { as } = data.getCurrentManager.roles[0];
                if (as.trim() === "Admin" || "Member") {
                  dispatch(setUser(data.getCurrentManager));
                  dispatch(changeAppState({ emailverified: true }));
                  dispatch(changeAppState({ accountCompleted: true }));
                  setAppLoaded(true);
                }
              }
            } else {
              if (data.getCurrentManager.roles.length) {
                const { as } = data.getCurrentManager.roles[0];
                if (as.trim() === "Admin" || "Member") {
                  dispatch(setUser(data.getCurrentManager));
                  dispatch(changeAppState({ emailverified: true }));
                  setAppLoaded(true);
                }
              } else {
                setAppLoaded(true);
              }
            }
          } else {
            dispatch(changeAppState({ loggedin: true }));
            setAppLoaded(true);
          }
        }
      } else {
        setAppLoaded(true);
      }
    });
  }, [
    dispatch,
    getUser,
    canMemberSignin,
    data,
    memberData,
    loading,
    memberLoading,
    sendManagerOut,
  ]);

  if (appLoaded) {
    return (
      <BrowserRouter>
        <Switch>
          {loggedin && accountCompleted ? (
            <div>
              {isDesktop ? (
                <HomeStack />
              ) : (
                <div>
                  <HeaderLogin />
                  <Mobile />
                </div>
              )}
            </div>
          ) : loggedin && !emailverified ? (
            <div>
              {isDesktop ? (
                <>
                  <div className="fixed-header" style={{ zIndex: "999" }}>
                    <HeaderLogin setupStack={true} />
                  </div>
                  <EmailVerification />
                </>
              ) : (
                <div>
                  <HeaderLogin />
                  <Mobile />
                </div>
              )}
            </div>
          ) : loggedin && emailverified && !accountCompleted ? (
            <div>
              {isDesktop ? (
                <>
                  <div className="fixed-header" style={{ zIndex: "999" }}>
                    <HeaderLogin setupStack={true} />
                  </div>
                  <SetupStack />
                </>
              ) : (
                <div>
                  <HeaderLogin />
                  <Mobile />
                </div>
              )}
            </div>
          ) : (
            <div>
              <AuthStack />
            </div>
          )}
        </Switch>
      </BrowserRouter>
    );
  } else {
    return (
      <div>
        <HeaderLogin />
        <Loading />
      </div>
    );
  }
}
