import React, { useState, useEffect } from "react";
import { useQuery } from "@apollo/client";
import { useSelector } from "react-redux";
import Search from "../../../components/molecules/Search";
import JobFilter from "../../../components/molecules/JobFilter";
import AppText from "../../../components/atoms/AppText";
import AppDropDownButton from "../../../components/molecules/AppDropDownButton";
import JobsList from "../../../components/molecules/JobsList";
import AppPagination from "../../../components/molecules/AppPagination";
import BasicAlerts from "../../../components/molecules/BasicAlerts";
import { jobTextFunction } from "../../../utils/functions/index";
import { CircularProgress } from "@material-ui/core";
import {
  GET_MY_JOBS,
  GET_CAREER_PAGES_STATUS,
} from "../../../utils/apolloSchema";

export default function Jobs() {
  const options = [
    { label: "Highest paid" },
    { label: "Most recent" },
    { label: "Closing Date" },
  ];
  const [createdJobs, setCreatedJobs] = useState([]);
  const [count, setCount] = useState(0);
  const [currentOption, setCurrentOption] = useState("Most recent");
  const [page, setPage] = useState(0);
  const [sortBy, setSortBy] = useState("recent");
  const [refetchJobs, setRefetchJobs] = useState(false);
  const [filterBy, setFilterBy] = useState("active");
  const [jobText, setJobText] = useState(jobTextFunction(0, "active"));
  const [currentFilter, setCurrentFilter] = useState("active");
  const [paginationPage, setPaginationPage] = useState(0);
  const [alert, setAlert] = useState(true);

  const [searchValue, setSearchValue] = useState("");

  const { user } = useSelector((state) => state.app);

  const navOption = [
    { name: "Active", key: "active" },
    { name: "Closed", key: "closed" },
    { name: "Draft", key: "drafts" },
  ];

  const {
    loading: careerPageLoading,
    data: careerPageData,
    refetch: careerRefetch,
  } = useQuery(GET_CAREER_PAGES_STATUS, {
    variables: { companyId: user?.roles[0].company?.id },
  });

  const {
    loading,
    data: jobsData,
    refetch,
  } = useQuery(GET_MY_JOBS, {
    variables: {
      input: {
        companyId: user?.roles[0].company?.id,
        AccessPrilivages: user?.roles[0].as,
        managerId: user.id,
        limit: 6,
        skip: page,
        sortBy: sortBy,
        filterBy: filterBy,
        query: searchValue,
      },
    },
  });

  useEffect(() => {
    refetch({
      variables: {
        input: {
          managerId: user.id,
          limit: 6,
          skip: page,
          sortBy: sortBy,
          filterBy: filterBy,
        },
      },
    });
  }, [page, refetch, user.id, sortBy, filterBy, refetchJobs]);

  useEffect(() => {
    if (!loading && jobsData) {
      setCreatedJobs([...jobsData.getMyJobs.jobs]);
      setCount(jobsData?.getMyJobs?.count);
      setJobText(jobTextFunction(jobsData?.getMyJobs?.count, filterBy));
    } else if (jobsData === undefined) {
      setCreatedJobs([]);
      setCount([]);
    }
    // eslint-disable-next-line
  }, [jobsData, loading]);

  useEffect(() => {
    setAlert(false);
  }, []);

  useEffect(() => {
    careerRefetch();
  }, [careerRefetch]);

  const onChange = (value) => {
    setCurrentOption(value.label);
    if (value.label === "Most recent") {
      setSortBy("recent");
    } else if (value.label === "Closing Date") {
      setSortBy("closeDate");
    } else {
      setSortBy("highestPaid");
    }
  };

  const handleSearch = () => {
    setPage(0);
  };

  const handlePaginationChange = (value) => {
    window.scrollTo(0, 0);
    if (value <= 1) {
      setPage(0);
      setPaginationPage(0);
    } else if (value > Math.ceil(count / 12)) {
      setPage((Math.ceil(count / 12) - 1) * 12);
      setPaginationPage(value);
    } else {
      setPage((value - 1) * 12);
      setPaginationPage(value);
    }
  };

  const handleChangeFilter = (value) => {
    setCurrentFilter(value);
    setFilterBy(value);
    setAlert(false);
    setPage(0);
  };

  const handleCloseAlert = () => {
    setAlert(false);
  };

  const handleUnPublish = (title, published) => {
    setAlert(
      `${title} job post has been ${
        published ? "unpublished" : "published"
      } and moved to ${published ? "Drafts" : "Actives"}`
    );
    setRefetchJobs(!refetchJobs);
  };

  return (
    <div className={"d-flex flex-column overview_background mt-4"}>
      <div className="w-100 bg-white pt-4 shadow_ d-flex justify-content-center">
        <div className="w-80 pt-3">
          <Search
            value={searchValue}
            onValueChange={(e) => {
              setSearchValue(e.target.value);
              setPage(0);
            }}
            onButtonClick={handleSearch}
          />
          <div className={"d-flex"}>
            {navOption.map((opt) => (
              <JobFilter
                key={opt.name}
                text={opt.name}
                optKey={opt.key}
                jobsPage={true}
                onClick={() => handleChangeFilter(opt.key)}
                isSelect={currentFilter}
              />
            ))}
          </div>
        </div>
      </div>
      <div className="w-100 d-flex flex-column justify-content-center align-items-center">
        {alert ? <BasicAlerts text={alert} onClose={handleCloseAlert} /> : null}

        <div className="w-80 pt-3 pb-3 d-flex justify-content-between align-items-center">
          {loading && <CircularProgress style={{ color: "#EAECEE" }} />}
          <div>
            {!loading && jobsData && (
              <AppText size={"_18"}>
                You have {jobsData?.getMyJobs?.count} {jobText}
              </AppText>
            )}
          </div>
          <div className="d-flex">
            <AppDropDownButton
              onChange={onChange}
              buttonOption={currentOption}
              buttonOptions={options}
            />
          </div>
        </div>
      </div>
      <div className="w-100 d-flex justify-content-center">
        <div className="w-80 pb-3">
          {createdJobs?.map((j) => (
            <div
              key={j.id}
              className={"bg-white mb-3  card_style"}
              style={{ border: "1px solid #eaedf3" }}
            >
              <JobsList
                job={j}
                moreInfo={true}
                refetch={handleUnPublish}
                currentFilter={currentFilter}
                careerPaymentDataLoading={careerPageLoading}
                careerPaymentData={careerPageData}
              />
            </div>
          ))}
          {count > 6 && (
            <div className={"d-flex justify-content-center"}>
              <AppPagination
                count={Math.ceil(count / 6)}
                onChange={handlePaginationChange}
                paginationPage={paginationPage}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
