import React, { useState, useRef, useCallback } from "react";
import AWS from "aws-sdk";
import ReactCrop from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";
import { useMutation, gql } from "@apollo/client";
import { uid } from "uid";
import ModalFooter from "../molecules/ModalFooter";
import { fire } from "../../firebase";

const UPDATE_USER = gql`
  mutation ($input: UpdateManagerInput) {
    updateManager(input: $input) {
      id
    }
  }
`;

export default function ProfileUploadModal({ temp, onClose, onChange, isAccountSetup = false }) {
  const [crop, setCrop] = useState({ width: 200, height: 200, unit: "px" });
  const uid_ = fire.auth().currentUser.uid;
  const [completedCrop, setCompletedCrop] = useState(null);

  const [saveProfileImage] = useMutation(UPDATE_USER);

  const [isLoading, setLoading] = useState(false);

  const imageRef = useRef(null);

  const onSave = async () => {
    const croppedImg = await getCroppedImg(imageRef, completedCrop, "fileName");

    setLoading(true);
    const s3Bucket = new AWS.S3({
      accessKeyId: "AKIAJ3P5KOR27P6EV5MA",
      secretAccessKey: "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",
      region: "eu-west-2",
    });
    const params = {
      Bucket: "tapobucket",
      Key: `${uid(10)}.jpg`,
      Body: croppedImg,
      ContentDisposition: "attachment",
    };
    const upload = s3Bucket.upload(params);

    upload.send((err, data) => {
      if (err) console.log(err);

      saveProfileImage({ variables: { input: { uid: uid_, avatarUrl: data.Location } } }).then(
        () => {
          setLoading(false);
          onClose();
          if (isAccountSetup) {
            onChange(data.Location)
          }
        }
      );
    });
  };

  const onLoad = useCallback((img) => {
    imageRef.current = img;
  }, []);

  function getCroppedImg(image, crop) {
    const Image = image.current;

    const canvas = document.createElement("canvas");
    const scaleX = Image.naturalWidth / Image.width;
    const scaleY = Image.naturalHeight / Image.height;
    canvas.width = crop.width;
    canvas.height = crop.height;
    const ctx = canvas.getContext("2d");

    ctx.drawImage(
      Image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width,
      crop.height
    );

    // As Base64 string
    // const base64Image = canvas.toDataURL('image/jpeg');

    // As a blob
    return new Promise((resolve, reject) => {
      canvas.toBlob(
        (blob) => {
          resolve(blob);
        },
        "image/jpeg",
        1
      );
    });
  }

  return (
    <div>
      <div className='d-flex justify-content-center' style={{ maxHeight: 459 }}>
        <ReactCrop
          imageStyle={{ maxHeight: 459 }}
          src={temp}
          crop={crop}
          onImageLoaded={onLoad}
          onChange={(newCrop) => setCrop(newCrop)}
          onComplete={(c) => setCompletedCrop(c)}
        />
      </div>
      <ModalFooter onClose={onClose} isLoading={isLoading} hasErrors={false} onSave={onSave} />
    </div>
  );
}
