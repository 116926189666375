import React from "react";
import { Checkbox } from "@material-ui/core";
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';

import AppInputIcon from '../atoms/AppInputIcon'
import AppText from '../atoms/AppText'

export default function SalaryRange({
    formik,
    onChange,
    isFixed,
    isHourly,
    setIsFixed,
    setIsHourly
}) {

    return (
        <div>
            <div style={{ marginBottom: "10px" }}>
                <AppText size='_12' color='_c50' weight='_medium'>
                    SALARY RANGE ({(!isFixed && !isHourly) ? 'ANNUALLY' : isFixed ? "FIXED" : "HOURLY"})
                </AppText>
            </div>
            {/* Salary Range */}
            <div>
                {(!isFixed && !isHourly) ? (
                    // ANNUALLY
                    <div className={"d-flex flex-row"}>
                        <div className='mr-2'>
                            <AppInputIcon
                                placeholder='Min'
                                name='salaryRange.start'
                                value={formik.values?.salaryRange?.start}
                                onChange={(e) => onChange(e, "salaryRange", "start")}
                                formik={formik}
                            />
                        </div>
                        <div>
                            <AppInputIcon
                                label=''
                                placeholder='Max'
                                name='salaryRange.end'
                                value={formik.values?.salaryRange?.end}
                                onChange={(e) => onChange(e, "salaryRange", "end")}
                                formik={formik}
                            />
                        </div>
                    </div>
                ) : (
                    // FIXED or HOURLY
                    <div className={"d-flex flex-row"}>
                        <div className='mr-2 w-100'>
                            <AppInputIcon
                                placeholder='Enter amount'
                                name='salaryRange.end'
                                value={formik.values?.salaryRange?.end}
                                onChange={(e) => onChange(e, "salaryRange", "end")}
                                formik={formik}
                            />
                        </div>
                    </div>
                )}
                {/* Check Boxs */}
                <div className={"d-flex flex-row"}>
                    <div className='mt-2 d-flex align-items-center'>
                        <Checkbox
                            name='Fixed'
                            value='Fixed'
                            checked={isFixed}
                            icon={<CheckBoxOutlineBlankIcon style={{ fontSize: '16px', color: "#D8DCE6" }} />}
                            checkedIcon={<CheckBoxIcon style={{ fontSize: '16px', color: "#03CA98" }} />}
                            onChange={() => {
                                setIsHourly(false)
                                setIsFixed(!isFixed)
                            }}
                            style={{
                                padding: 0,
                                marginRight: '5px'
                            }}
                        />{" "}
                        <AppText size='_14' color='_c90'>
                            Fixed Salary
                        </AppText>
                    </div>
                    <div className='mt-2 ml-3 d-flex align-items-center'>
                        <Checkbox
                            name='Hourly'
                            value='Hourly'
                            checked={isHourly}
                            icon={<CheckBoxOutlineBlankIcon style={{ fontSize: '16px', color: "#D8DCE6" }} />}
                            checkedIcon={<CheckBoxIcon style={{ fontSize: '16px', color: "#03CA98" }} />}
                            onChange={() => {
                                setIsFixed(false)
                                setIsHourly(!isHourly)
                            }}
                            style={{
                                padding: 0,
                                marginRight: '5px'
                            }}
                        />{" "}
                        <AppText size='_14' color='_c90'>
                            Hourly Rate
                        </AppText>
                    </div>
                </div>
                {formik.touched.salaryRange?.start &&
                    formik.errors.salaryRange?.start &&
                    !formik.errors.salaryRange?.end &&
                    (!isFixed && !isHourly) &&
                    (
                        <AppText size='_12' color='_r100'>
                            {formik.errors.salaryRange?.start}
                        </AppText>
                    )}
                {formik.touched.salaryRange?.end &&
                    formik.errors.salaryRange?.end &&
                    (!isFixed && !isHourly) &&
                    (
                        <AppText size='_12' color='_r100'>
                            {formik.errors.salaryRange?.end}
                        </AppText>
                    )}
                {formik.touched.salaryRange?.end &&
                    formik.touched.salaryRange?.start &&
                    !formik.errors.salaryRange?.end &&
                    !formik.errors.salaryRange?.start &&
                    (parseFloat(formik.values.salaryRange?.start) >= parseFloat(formik.values.salaryRange?.end)) &&
                    (!isFixed && !isHourly) &&
                    (
                        <AppText size='_12' color='_r100'>
                            The Min value must be lower than the Max value
                        </AppText>
                    )}
                {
                    formik.touched.salaryRange?.end &&
                    formik.errors.salaryRange?.end &&
                    (isFixed || isHourly) &&
                    (
                        <AppText size='_12' color='_r100'>
                            Please enter an amount
                        </AppText>
                    )}
            </div>
        </div>
    )
}