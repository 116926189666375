import React from "react";
import AppText from "../atoms/AppText";
// import AppCircularProgress from "../atoms/AppCircularProgress";
import { Avatar } from "@material-ui/core";

export default function CompanyCard({ company }) {
  return (
    <div className={"w-100 _companybox bg-white"}>
      <div className="p-4">
        <div className="d-flex">
          <Avatar alt="er3" src={company?.logo} className="_clogo mr-3" />
          <div>
            <AppText size="_18" weight="_medium">
              {company?.name}
            </AppText>
            <div>
              <AppText size="_14" color="_c90">
                {company?.services} | {company?.location}
              </AppText>
            </div>
          </div>
        </div>
        <div className="mt-3">
          <AppText size="_14" color="_c90">
            {company?.bio}
          </AppText>
        </div>
      </div>
      {/* <div className='_sep_' />
      <div className={"d-flex p-4"}>
        <div
          className={"d-flex align-items-center pr-3"}
          style={{ borderRight: "1.5px solid #eee" }}
        >
          <AppCircularProgress value={80} />
          <div className={"d-flex flex-column ml-3"} style={{ minWidth: "100px" }}>
            <AppText size='_18' weight='_medium'>
              80%
            </AppText>
            <AppText size='_14' color='_c50'>
              Profile Strength
            </AppText>
          </div>
        </div>
        <div className={"d-flex flex-column ml-3"}>
          <AppText size='_12' color='_c50' weight='_medium'>
            TIP
          </AppText>
          <AppText size='_14' color='_c90' align={"_justify"}>
            Add a Video CV to your profile and increase your chance of getting hired.
          </AppText>
        </div>
      </div> */}
    </div>
  );
}
