import React from "react";
import DeleteOutlineRoundedIcon from '@material-ui/icons/DeleteOutlineRounded';
import AppText from "./AppText";

export default function RemoveUpload({ onClick, label, color }) {
  return (
    <span
      className={`d-flex p-1 px-2 align-items-center pointer shadow-sm ${label ? "ml-3" : ""}`}
      style={{ border: `1px solid ${color === "_white" ? "#FFFF" : "#eaedf3"}`, borderRadius: "4px" }}
      onClick={onClick}
    >
      <DeleteOutlineRoundedIcon
        label='Add image'
        style={{ color: color === "_white" ? "#FFFF" : "", fontSize: "16px", marginRight: `${label ? "6px" : ''}` }}
      />
      {label && (
        <AppText size='_14' color={`${color === '_white' ? '_c0' : ''}`}>{label}</AppText>
      )}
    </span>
  );
}
