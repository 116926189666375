import React, { useState } from "react";
import { useFormik } from "formik"
import { useHistory } from "react-router-dom"
import { useMediaQuery } from "react-responsive";
import { CheckCircleOutline } from "@material-ui/icons";
import { fire } from "../../../firebase";

import AppText from "../../../components/atoms/AppText";
import AppButton from "../../../components/molecules/AppButton";
import AppInput from "../../../components/molecules/AppInput";
import Model from "../../../components/molecules/Model";
import AppDialog from "../../../components/atoms/AppDialog";
import { ForgotPasswordShema } from '../../../utils/schema/login'

export default function ForgotPassword() {

  const isDesktop = useMediaQuery({ minWidth: 992 });
  const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 991 });

  const [isSent, setIsSent] = useState(false);
  const history = useHistory()

  const formik = useFormik({
    initialValues: {
      email: "",
    },
    validationSchema: ForgotPasswordShema,
    onSubmit: async (value) => {
      fire
        .auth()
        .sendPasswordResetEmail(value.email)
        .then(() => {
          history.push("/sent", { email: value.email })
        })
        .catch((error) => {
          console.log(error);
        });
    },
  });

  return <div className='background d-flex flex-column align-items-center justify-content-center'>
    <div className={isDesktop ? "w-25" : isTablet ? "w-60" : "w-90"}>
      <div className='bg-white p-4 boxy shadow-sm rounded'>
        <div>
          <AppText size='_22'>Forgot Password</AppText>
          <div>
            <AppText size='_16' color='_c50'>
              Enter the email you used in creating your account
          </AppText>
          </div>
        </div>
        <form onSubmit={formik.handleSubmit}>
          <div className='my-4 custom-styles '>
            <AppInput
              label='EMAIL ADDRESS'
              placeholder='email'
              name='email'
              value={formik.values.email}
              onChange={formik.handleChange}
              hasError={formik.touched.email && formik.errors.email ? true : false}
            />
            <div>
              {formik.touched.email && formik.errors.email ? (
                <AppText size='_12' color='_r100'>
                  {formik.errors.email}
                </AppText>
              ) : null}
            </div>
          </div>

          <AppButton width='_full' type='submit'>
            <AppText size='_14' color='_c0'>
              Send Reset Link
          </AppText>
          </AppButton>
        </form>
      </div>
      <div className='mt-4 pl-4'>
        <AppText size='_14' color='_c50'>
          Didn't receive the reset link?{" "}
          <span onClick={formik.handleSubmit} className='_g90 pointer'>
            Resend
        </span>
        </AppText>
      </div>
    </div>
    {isSent && (
      <Model title='Link sent' isModal={false} onClose={() => setIsSent(false)}>
        <AppDialog
          content={
            <div className='d-flex flex-column align-items-center  justify-content-center'>
              <CheckCircleOutline style={{ fontSize: "70px", color: "#9EA0A5" }} />
            </div>
          }
          saveText='Close'
          onClose={() => setIsSent(false)}
          onSave={() => setIsSent(false)}
        />
      </Model>
    )}
  </div>
}
