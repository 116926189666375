import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import AppText from "../atoms/AppText";
import { Avatar } from "@material-ui/core";
import { Popover } from '@mui/material';
import { returnInitals, UpperCaseFirst } from "../../utils/functions";
import AddIcon from '@mui/icons-material/Add';

export default function AppDropAdmin({
  onChange,
  users,
  id
}) {
  const [value, setValue] = useState({});
  const [userState, setUserState] = useState(users);
  const [anchorEl, setAnchorEl] = useState(null);
  const { user } = useSelector((state) => state.app);
  const open = Boolean(anchorEl);
  const idPopover = open ? 'simple-popover' : undefined;
  
  useEffect(() => {
    if (id) {
      const firstDisplay = users.find((el) => el.id === id);
      setValue(firstDisplay);
    } else {
      const firstDisplay = users.find((el) => el.id === user.id);
      setValue(firstDisplay);
    }
    setUserState(users);
  }, [users, user, id]);

  const handleClick = (value) => {
    setValue(value);
    onChange(value);
    setAnchorEl(null);
  };

  const handleClickPopover = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div className="d-flex">
      <Avatar
        style={{ height: "40px", width: "40px", background: "#34AA44" }}
        className={"mr-2"}
        src={value?.avatarUrl || null}
      />
      <div
        className=" d-flex justify-content-center py-2"
        style={{ border: "1px solid #e2e5ed", width: '40px', borderRadius: "100%", cursor: "pointer" }}
        onClick={handleClickPopover}
      >
        <AddIcon style={{ fontSize: "20px", color: `#9EA0A5` }} />
      </div>
      <Popover
        id={idPopover}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        sx={{ marginTop: "5px" }}
      >
        {userState.map((e, i) => (
          <div
            key={i}
            className='d-flex align-items-center py-2 px-3 _opt pointer'
            onClick={() => handleClick(e)}
          >
            <Avatar
              style={{ height: "35px", width: "35px", background: "#34AA44" }}
              className={"mr-3"}
              src={e?.avatarUrl || null}
            >
              <AppText size='_14' color='_c0'>
                {returnInitals(`${UpperCaseFirst(e?.fullName)} `)}
              </AppText>
            </Avatar>
            <AppText size='_12' weight='_small'>
              {e?.fullName}
            </AppText>
          </div>
        ))}
      </Popover >
    </div >
  );
}
