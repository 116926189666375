import React, { useEffect, useState } from "react";
import { gql, useQuery } from "@apollo/client";
// import { useMediaQuery } from "react-responsive";
// import { useDispatch, useSelector } from "react-redux";

import AppText from "../atoms/AppText";
import ChipButton from "../molecules/ChipButton";
import AppSearchDropdown from "./AppSearchDropdown";
import { allSkills } from "../../utils/dummy/index";

const FETCH_SKILLS = gql`
  query {
    getCurrentUser {
      skills {
        value
        label
      }
    }
  }
`;

export default function SkillsSearch({ onChange, skills = [] }) {
  // const isDesktop = useMediaQuery({ minWidth: 992 });
  // const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 991 });

  const [number, setNumber] = useState(0)
  const [general, setGeneral] = useState(allSkills.filter((item) => skills.includes(item.label) && item.value === 'General'))
  const [softSkills, setSoftSkills] = useState(allSkills.filter((item) => skills.includes(item.label) && item.value === 'Soft skills'))
  const [toolsAndTechnologies, setToolsAndTechnologies] = useState(allSkills.filter((item) => skills.includes(item.label) && item.value === 'Tools'))
  const [others, setOthers] = useState(allSkills.filter((item) =>
    !skills.includes(item.label)
    && item.value === 'General'
    && item.value === 'Tools'
    && item.value === 'Soft skills'
  ))

  const { loading, data } = useQuery(FETCH_SKILLS, { fetchPolicy: "no-cache" });

  useEffect(() => {
    if (!loading && data) {
    }
  }, [data, loading]);

  const handleChange = (obj) => {
    setNumber(number + 1)
    if (obj.value === 'General') {
      const newValue = general
      const checked = newValue.filter((skill) => skill.label === obj.label)
      if (!checked.length) {
        newValue.push(obj)
        setGeneral(newValue)
        onChange(obj, 'add')
      }
    }
    else if (obj.value === 'Soft skills') {
      const newValue = softSkills
      const checked = newValue.filter((skill) => skill.label === obj.label)
      if (!checked.length) {
        newValue.push(obj)
        setSoftSkills(newValue)
        onChange(obj, 'add')
      }
    }
    else if (obj.value === 'Tools') {
      const newValue = toolsAndTechnologies
      const checked = newValue.filter((skill) => skill.label === obj.label)
      if (!checked.length) {
        newValue.push(obj)
        setToolsAndTechnologies(newValue)
        onChange(obj, 'add')
      }
    }
    else if (obj.value === 'others') {
      const newValue = others
      const checked = newValue.filter((skill) => skill.label === obj.label)
      if (!checked.length) {
        newValue.push(obj)
        setOthers(newValue)
        onChange(obj, 'add')
      }
    }
  }

  const onRemoveSkill = (obj) => {
    if (obj.value === 'General') {
      const newValue = general
      const checked = newValue.filter((skill) => skill.label !== obj.label)
      setGeneral(checked)
      onChange(obj, 'remove')
    }
    else if (obj.value === 'Soft skills') {
      const newValue = softSkills
      const checked = newValue.filter((skill) => skill.label !== obj.label)
      setSoftSkills(checked)
      onChange(obj, 'remove')
    }
    else if (obj.value === 'Tools') {
      const newValue = toolsAndTechnologies
      const checked = newValue.filter((skill) => skill.label !== obj.label)
      setToolsAndTechnologies(checked)
      onChange(obj, 'remove')
    }
    else if (obj.value === 'others') {
      const newValue = others
      const checked = newValue.filter((skill) => skill.label !== obj.label)
      setOthers(checked)
      onChange(obj, 'remove')
    }
  };

  return (
    <div>
      <div className='w-100'>
        <AppSearchDropdown
          label='SKILL'
          name='skill'
          items={allSkills}
          onChange={(data) => handleChange(data)}
        />
      </div>

      {/* General */}
      <div className='mt-2'>
        {general.length > 0 && (
          <div className='mb-2'>
            <AppText size='_14' weight='_small' color='_c25'>
              General
            </AppText>
          </div>
        )}
        {general.map((g, i) => (
          <ChipButton label={g.label} key={i} onClick={() => onRemoveSkill(g)} />
        ))}
        {
          (general.length > 0 && (toolsAndTechnologies.length > 0 || others.length > 0 || softSkills.length > 0)) && (
            <div className='_sep_ my-3' />
          )
        }

        {/* Tools And Technologies */}
        {toolsAndTechnologies.length > 0 ? (
          <div className='mb-2'>
            <AppText size='_14' weight='_small' color='_c25'>
              Tools & Technologies
            </AppText>
          </div>
        ) : null}
        {toolsAndTechnologies.map((t, i) => (
          <ChipButton label={t.label} key={i} onClick={() => onRemoveSkill(1)} />
        ))}
        {
          (toolsAndTechnologies.length > 0 && (others.length > 0 || softSkills.length > 0)) && (
            <div className='_sep_ my-3' />
          )
        }

        {/* Soft Skills */}
        {softSkills.length > 0 ? (
          <div className='mb-2'>
            <AppText size='_14' weight='_small' color='_c25'>
              Soft Skills
            </AppText>
          </div>
        ) : null}
        {softSkills.map((s, i) => (
          <ChipButton label={s.label} key={i} onClick={() => onRemoveSkill(s)} />
        ))}
        {
          (softSkills.length > 0 && (others.length > 0)) && (
            <div className='_sep_ my-3' />
          )
        }

        {/* Others */}
        {others.length > 0 ? (
          <div className='mb-2'>
            <AppText size='_14' weight='_small' color='_c25'>
              Others
            </AppText>
          </div>
        ) : null}
        {others.map((s, i) => (
          <ChipButton label={s.label} key={i} onClick={() => onRemoveSkill(s)} />
        ))}
      </div>
    </div>
  );
}
