import React from "react";
import AppText from "../atoms/AppText";
// import { gql, useMutation } from "@apollo/client";

export default function Billing({ careerStatus }) {
  return (
    <div>
      <div className={"card_style mb-4"}>
        <header className={"d-flex flex-column p-4"}>
          <AppText size="_18" weight="_medium">
            Billing
          </AppText>
          <AppText size="_14" color={"_c50"}>
            Payment for Career Pages with stripe
          </AppText>
        </header>
        <div className="_sep_" />
        <div className="p-4">
          {careerStatus?.getCareerPageStatus?.status === "trial"
            ? "trial"
            : careerStatus?.getCareerPageStatus?.status === "expired" &&
              careerStatus?.getCareerPageStatus?.JobsToIframe.length
            ? `Thanks you paid £${
                10 * careerStatus?.getCareerPageStatus?.JobsToIframe.length
              } for ${
                careerStatus?.getCareerPageStatus?.JobsToIframe.length
              } jobs`
            : careerStatus?.getCareerPageStatus?.status === "expired"
            ? "Add jobs to career page iframe"
            : "Start Trial"}
        </div>
      </div>
    </div>
  );
}
