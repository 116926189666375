import React, { useState, createRef, useEffect } from "react";
import AppText from "../atoms/AppText";
import moment from "moment";
import StripeCheckout from "react-stripe-checkout";
import { useHistory } from "react-router-dom";
import WorkOutlineOutlinedIcon from "@material-ui/icons/WorkOutlineOutlined";
import GroupOutlinedIcon from "@material-ui/icons/GroupOutlined";
import numbro from "numbro";
import AppDown from "../molecules/AppDown";
import AppDialog from "../atoms/AppDialog";
import Model from "../molecules/Model";
import { textCut } from "../../utils/functions/index";
import { Avatar } from "@material-ui/core";
import { gql, useMutation } from "@apollo/client";
import { closeDate } from "../../utils/functions/index";
import AppButton from "./AppButton";
import { useSelector } from "react-redux";

const SET_PUBLISH_JOB = gql`
  mutation ($input: _JobUpdateInput) {
    updateJobOne(input: $input) {
      id
    }
  }
`;

const MAKE_PAYMENT = gql`
  mutation ($input: paymentInput) {
    careerPagesPayment(input: $input)
  }
`;

export default function JobsList({
  job,
  moreInfo = false,
  refetch,
  currentFilter,
  careerPaymentDataLoading,
  careerPaymentData,
}) {
  const history = useHistory();
  const [openModal, setOpenModal] = useState(false);
  const [isFocus, setIsFocus] = useState(false);
  const [paid, setPaid] = useState(false);
  const dropRef = createRef(null);

  const { user } = useSelector((state) => state.app);

  const [publishJob] = useMutation(SET_PUBLISH_JOB);
  const [makePayment] = useMutation(MAKE_PAYMENT);

  const isClosing = closeDate(parseInt(job.closingDate));

  const handleOnClick = () => {
    history.push(`/job/${job.id}`, job);
  };

  useEffect(() => {
    if (!careerPaymentDataLoading) {
      if (
        careerPaymentData?.getCareerPageStatus?.JobsToIframe.includes(job.id)
      ) {
        setPaid(true);
      }
    }
  }, [careerPaymentDataLoading, careerPaymentData, job.id]);

  const handleOnClickHorizIcon = () => {
    if (openModal) {
      setOpenModal(!openModal);
      setIsFocus(false);
    } else {
      setOpenModal(!openModal);
      setIsFocus(true);
    }
  };

  const handleEdit = () => {
    setIsFocus(false);
    dropRef.current.close();
    history.push(`/create-job`, job);
  };

  const handleUnPublish = () => {
    setOpenModal(false);
    setIsFocus(false);
    publishJob({
      variables: { input: { id: job?.id, published: !job.published } },
    });
    refetch(job?.jobTitle, job?.published);
  };

  const handleOnFocus = () => {
    setIsFocus(!isFocus);
  };

  const getToken = (token) => {
    const newToken = {
      email: token.email,
      id: token.id,
      companyId: user?.roles[0].company.id,
      jobId: job.id,
      desc: "Career page payment for " + job.jobTitle,
    };

    makePayment({ variables: { input: newToken } }).then((data) =>
      console.log(data)
    );
  };

  return (
    <div className={`d-flex p-3 pointer ${isFocus ? "_jobList" : ""}`}>
      <div className={"d-flex flex-column w-100"} onClick={handleOnClick}>
        <div className={"d-flex justify-content-between align-items-center"}>
          <div className={"d-flex flex-column"}>
            <AppText size="_16" weight="_medium">
              {job?.jobTitle}
            </AppText>
            <AppText size="_12" weight="_small" color="_c25">
              {job?.location} - Posted{" "}
              {textCut(moment(parseInt(job?.createdAt)).fromNow(), 14)}
            </AppText>
          </div>
          {!isClosing ? null : (
            <span className={"ms-1 d-flex mr-2"}>
              <AppText size="_14" color={"_o100"}>
                {isClosing}
              </AppText>
            </span>
          )}
        </div>
        <div className="_sep_ mt-3 mb-3" />
        <div className={"d-flex justify-content-between align-items-center"}>
          <div className="d-flex">
            <div className={"d-flex flex-column mr-4"}>
              <AppText size="_12" weight="_small" color="_c25">
                Experience Level
              </AppText>
              <AppText size="_12" weight="_medium">
                {job?.experienceLevel}
              </AppText>
            </div>
            <div className={"d-flex flex-column mr-4 "}>
              <AppText size="_12" weight="_small" color="_c25">
                Employment Type
              </AppText>
              <AppText size="_12" weight="_medium">
                {job?.employmentType}
              </AppText>
            </div>
            <div className={"d-flex flex-column mr-4"}>
              <AppText size="_12" weight="_small" color="_c25">
                Applications
              </AppText>
              <div className={"d-flex align-items-center"}>
                <GroupOutlinedIcon
                  style={{
                    color: moreInfo ? "" : "green",
                    fontSize: 15,
                    marginRight: "5px",
                  }}
                />
                <AppText
                  size="_12"
                  weight="_medium"
                  color={
                    job?.applicationsCount?.length > 0
                      ? moreInfo
                        ? ""
                        : "_g100"
                      : moreInfo
                      ? ""
                      : "_c50"
                  }
                >
                  {job?.applicationsCount > 0 && job?.applicationsCount}{" "}
                  {moreInfo ? "Candidate(s)" : "Candidates"}
                </AppText>
              </div>
            </div>
            {moreInfo && (
              <div className={"d-flex flex-column mr-4"}>
                <AppText size="_12" weight="_small" color="_c25">
                  Salary Range
                </AppText>
                <div
                  className={`d-flex align-items-center ${
                    job?.salaryRange?.start === 0
                      ? "justify-content-center"
                      : ""
                  }`}
                >
                  {job?.salaryRange?.defaultType !== "hourly" ? (
                    <AppText size="_12" weight={"_medium"}>
                      £
                      {job?.salaryRange?.start !== 0
                        ? job?.salaryRange?.start < 1000
                          ? numbro(job?.salaryRange?.start).format({
                              mantissa: 2,
                            })
                          : numbro(job?.salaryRange?.start).format({
                              thousandSeparated: true,
                              average: true,
                            })
                        : null}
                      {job?.salaryRange?.start !== 0 ? " - £" : ""}
                      {job?.salaryRange?.end < 1000
                        ? numbro(job?.salaryRange?.end).format({ mantissa: 2 })
                        : numbro(job?.salaryRange?.end).format({
                            thousandSeparated: true,
                            average: true,
                          })}
                    </AppText>
                  ) : (
                    <AppText size="_12" weight={"_medium"}>
                      £
                      {job?.salaryRange?.end < 1000
                        ? numbro(job?.salaryRange?.end).format({ mantissa: 2 })
                        : numbro(job?.salaryRange?.end).format({
                            thousandSeparated: true,
                            average: true,
                          })}
                      /hr
                    </AppText>
                  )}
                </div>
              </div>
            )}
            {moreInfo && (
              <div className={"d-flex flex-column mr-4"}>
                <AppText size="_12" weight="_small" color="_c25">
                  Admins
                </AppText>
                {job?.assignedTo === null ? (
                  <div
                    className={
                      "d-flex align-items-center justify-content-center"
                    }
                  >
                    <Avatar
                      src={user?.avatarUrl}
                      style={{ height: "20px", width: "20px" }}
                    >
                      <AppText>{user?.fullName.charAt(0)}</AppText>
                    </Avatar>
                  </div>
                ) : (
                  <div
                    className={
                      "d-flex align-items-center justify-content-center"
                    }
                  >
                    <Avatar
                      src={job?.assignedTo?.avatarUrl}
                      style={{ height: "20px", width: "20px" }}
                    >
                      <AppText>{job?.assignedTo?.fullName.charAt(0)}</AppText>
                    </Avatar>
                  </div>
                )}
              </div>
            )}

            {moreInfo && (
              <div className={"d-flex flex-column mr-4"}>
                <AppText size="_12" weight="_small" color="_c25">
                  Closing Date
                </AppText>
                <div
                  className={"d-flex align-items-center justify-content-center"}
                >
                  <AppText size="_12" weight="_medium">
                    {job?.closingDate
                      ? moment(parseInt(job?.closingDate)).format("DD-MM-YYYY")
                      : "-"}
                  </AppText>
                </div>
              </div>
            )}
          </div>
          <div>
            {currentFilter === "active" && (
              <>
                {careerPaymentData?.getCareerPageStatus &&
                careerPaymentData?.getCareerPageStatus.status !== "trial" &&
                !paid ? (
                  <StripeCheckout
                    name={"Career page Payment for " + job.jobTitle}
                    email={job?.assignedTo.email}
                    description={"Career page Payment for " + job.jobTitle}
                    currency="GBP"
                    amount={1000}
                    token={getToken}
                    stripeKey={process.env.REACT_APP_PUBLISHABLE_KEY}
                  >
                    <AppButton color="_white">
                      <AppText size="_14" color="_g100">
                        Pays £10
                      </AppText>
                    </AppButton>
                  </StripeCheckout>
                ) : careerPaymentData?.getCareerPageStatus && paid ? (
                  "Paid"
                ) : null}
              </>
            )}
          </div>
        </div>
      </div>
      {moreInfo && (
        <div onClick={handleOnFocus} className={"mt-2"}>
          <AppDown vertical="bottom" horizontal="center" ref={dropRef}>
            <div className="py-2 px-3">
              <div
                className="d-flex align-items-center py-1 pointer"
                onClick={handleEdit}
              >
                <AppText size="_14">Edit Job</AppText>
              </div>
              {currentFilter !== "closed" ? (
                <div
                  className="d-flex align-items-center py-1 pointer"
                  onClick={handleOnClickHorizIcon}
                >
                  <AppText size="_14">
                    {job.published ? "Unpublish Job" : "Publish Job"}
                  </AppText>
                </div>
              ) : null}
            </div>
          </AppDown>
        </div>
      )}
      {openModal && (
        <Model
          isDelete={true}
          closeButton={false}
          onClose={() => setOpenModal(false)}
          width={"500px"}
        >
          <AppDialog
            saveText={job.published ? "Yes, Unpublish" : "Yes, Publish"}
            closeText="No"
            onClose={() => setOpenModal(false)}
            onSave={() => handleUnPublish(job.published)}
            job={true}
            content={
              <div className={"d-flex flex-column align-items-center"}>
                <div className="py-3">
                  <span
                    className="d-flex justify-content-center align-items-center"
                    style={{
                      display: "inline-block",
                      height: "50px",
                      width: "50px",
                      color: "#03CA9880",
                      borderRadius: "50px",
                      border: "1px solid",
                    }}
                  >
                    <WorkOutlineOutlinedIcon
                      style={{ fontSize: "26px", color: "#03CA98" }}
                    />
                  </span>
                </div>
                <div>
                  <AppText weight="_medium">
                    {job.published
                      ? "Unpublish Job Post?"
                      : "Publish Job Post?"}
                  </AppText>
                </div>
                <div className="mt-2 mb-4">
                  <AppText size="_14" align="_center">
                    {job?.jobTitle} job post will be moved to{" "}
                    {job.published
                      ? "drafts and no longer be visible to Job Seekers."
                      : "actives and will be visible to Job Seekers."}{" "}
                    Continue?
                  </AppText>
                </div>
              </div>
            }
          ></AppDialog>
        </Model>
      )}
    </div>
  );
}
