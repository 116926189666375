import "./App.css";
import Router from "./stacks";
import { Provider } from "react-redux";
import { ApolloProvider } from "@apollo/client";
import Store from "./Store";
import Client from "./apollo";

function App() {
  return (
    <ApolloProvider client={Client}>
      <Provider store={Store}>
        <Router />
      </Provider>
    </ApolloProvider>
  );
}

export default App;
