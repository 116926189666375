import React, { useState, forwardRef } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";

import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
import { TextField, InputAdornment, IconButton, makeStyles } from "@material-ui/core";
import DropDownArrow from "../atoms/DropDownArrow";

export default function AppCalendar({ value, onChange, isDisabled = false }) {
  const [startDate, setStartDate] = useState(value ? moment.unix(parseInt(value)/1000).toDate() : moment().toDate()); // Need real data from database
  const useSyles = makeStyles({
    label: { marginBottom: "10px" },
    root: {
      width: "100%",
      "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
        border: "none",
      },
      "& .MuiOutlinedInput-notchedOutline": {
        border: "none",
        maxHeight: "38px",
      },
      "& :disabled": {
        WebkitTextFillColor: "#3E3F42",
      },
    },
    btn: {
      padding: "0!important",
      "&:hover": {
        background: "none",
      },
      "&:focus": {
        outline: 0,
      },
    }
  });
  const classes = useSyles();
  const Input = forwardRef(
    ({ value, onClick }, ref) => (
      <div className='position-relative'>
        <div>
          <TextField
            value={value ? moment(startDate).format('DD/MM/YYYY') : ''}
            variant='outlined'
            fullWidth
            size='small'
            ref={ref}
            disabled={isDisabled}
            onClick={onClick}
            placeholder={'Select Date'}
            InputProps={{
              endAdornment: (
                <InputAdornment position='end'>
                  <IconButton
                    disableRipple
                    disableTouchRipple
                    disableFocusRipple
                    className={classes.btn}
                    onClick={onClick}
                  >
                    <DropDownArrow />
                  </IconButton>
                </InputAdornment>
              ),
              startAdornment: (
                <InputAdornment position='start'>
                  <CalendarTodayIcon style={{ color: '#c9c9c9', fontSize: 20, marginRight: '5px' }} />
                </InputAdornment>
              )
            }}
          />
        </div>
      </div>
    ),
  );

  const MyContainer = ({ className, children }) => {
    return (
      <div style={{ height: '260px' }} className={'bg-white rounded shadow-sm'}>{children}</div>
    );
  };
  
  const handleChange = (newDate) => {
    setStartDate(newDate)
    onChange(newDate)
  }

  return (
    <DatePicker
      className='_dp'
      selected={value ? startDate : ''}
      onChange={(e) => handleChange(e)}
      dateFormat='dd/MM/yyyy'
      customInput={<Input />}
      placeholder={'Select Date'}
      calendarContainer={MyContainer}
      minDate={new Date()}
      disabled={isDisabled}
    />
  );
}
