import React from "react";
import AppText from '../atoms/AppText'

export default function NoNotifications() {

    return (
        <div className='h-100 d-flex justify-content-center w-100 align-items-start'>
            <div className={`d-flex flex-column align-items-center w-100`}>
                <img alt={"notifications"} src='/Group-10.svg' className={'my-3'} height={'142'} width={'190'}></img>
                <AppText size='_16' weight='_medium' color={'_c100'}>
                    Clean as a whistle!
                </AppText>
                <AppText size='_14' color={'_c50'} align={'_center'}>
                    Congratulations! You are all caught up.
                </AppText>
            </div>
        </div>
    )
}