import React, { useState } from "react";
import _ from "lodash";
import axios from "axios";
import OutsideClickHandler from "react-outside-click-handler";

import AppText from "../atoms/AppText";

export default function LocationSearchMapbox({
  value,
  onChange,
  onValueChange,
  formik,
}) {
  const [results, setResults] = useState([]);
  const [isBlur, setIsBlur] = useState(true);
  const [isClick, setIsClick] = useState(false);
  const [isError, setIsError] = useState(false);

  const handleKeydown = async (e) => {
    const search = async () => {
      const search = e.target.value;
      if (search === "") {
        setResults([]);
      } else {
        const res = await axios.get(
          `https://api.mapbox.com/geocoding/v5/mapbox.places/${search}.json?access_token=${
            process.env.REACT_APP_MAP_BOX ||
            "pk.eyJ1Ijoic2hvbm1hY3JheSIsImEiOiJja214bGRkbmgwb2Y2Mm5wOTllcXZpaTFlIn0.4ddFAx4rcm91XJZHb_wKuw"
          }`
        );
        setResults(res.data.features);
      }
    };
    const d = _.debounce(search, 150);
    d();
  };
  const handleOnChange = (e) => {
    const data = {
      shortText: value,
      lat: ``,
      lng: ``,
    };
    onChange(data);
    setIsClick(false);
    onValueChange(e);
  };

  const handleOnBlur = () => {
    setIsBlur(false);
    const data = {
      shortText: value,
      lat: ``,
      lng: ``,
    };
    if (
      isBlur &&
      formik.errors.location === undefined &&
      !formik.values.location
    ) {
      setIsError(true);
      onChange(data);
    } else if (isBlur && formik.errors.location && !formik.values.location) {
      setIsError(true);
      onChange(data);
    } else if (!isClick && !results.length) {
      onChange(data);
    }
  };

  const handleOnFocus = () => {
    setIsBlur(true);
    setIsClick(false);
    setIsError(false);
  };

  const handleClick = (place) => {
    setIsClick(true);
    const shortText = `${place.place_name.split(",")[1]}, ${
      place.context[place.context?.length - 1].text
    }`;
    const longText = place.place_name;
    const data = {
      shortText: shortText.trim(),
      longText,
      lat: `${place.geometry.coordinates[1]}`,
      lng: `${place.geometry.coordinates[0]}`,
    };
    onChange(data);
    setResults([]);
  };

  return (
    <div className={"w-100"}>
      <OutsideClickHandler onOutsideClick={() => setResults([])}>
        <AppText size="_12" color="_c50" weight="_medium">
          JOB LOCATION
        </AppText>
        <input
          placeholder="Office location or Postcode"
          onKeyUp={handleKeydown}
          value={value}
          className="mapbox_search shadow-sm rounded mt-2 p-2 pr-0"
          onChange={handleOnChange}
          onBlur={handleOnBlur}
          onFocus={handleOnFocus}
          style={
            !isError || !(!isBlur && !isClick)
              ? { border: "1px solid #D8DCE6" }
              : { border: "1px solid #E6492D" }
          }
        />
        {results.length ? (
          <div className={"_drop w-23"}>
            {results?.map((e) => (
              <div
                key={e.place_name}
                className="py-2 px-3 _opt pointer"
                onClick={() => handleClick(e)}
              >
                <AppText size="_14">
                  {e.place_name.split("").slice(0, 40)}
                </AppText>
              </div>
            ))}
          </div>
        ) : (
          ""
        )}
        {!isError ? null : (
          <AppText size="_12" color="_r100">
            Please enter a location
          </AppText>
        )}
        {!isError && !isBlur && !isClick && !results.length ? (
          <AppText size="_12" color="_r100">
            Please you need to choose a location from the dropdown
          </AppText>
        ) : null}
      </OutsideClickHandler>
    </div>
  );
}
