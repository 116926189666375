import React, { useState, useEffect } from "react";
import { useFormik } from "formik";
import { Switch, makeStyles } from "@material-ui/core";
import { useHistory, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { useMutation, gql, useQuery } from "@apollo/client";

import AppText from "../../../components/atoms/AppText";
import AppButton from "../../../components/molecules/AppButton";
import AppInput from "../../../components/molecules/AppInput";
import AppDropdown from "../../../components/molecules/AppDropdown";
import AppDropAdmin from "../../../components/molecules/AppDropAdmin";
import LocationSearchMapbox from "../../../components/molecules/LocationSearchMapbox";
import AppCalendar from "../../../components/orgnisms/AppCalendar";
import AppTextEditor from "../../../components/molecules/AppTextEditor";
import SkillsSearch from "../../../components/molecules/SkillsSearch";
import SalaryRange from "../../../components/orgnisms/SalaryRange";
import { industrys } from "../../../utils/industrys";

import { createJobSchema } from "../../../utils/schema/createJob";
import {
  validateTime,
  submitButton,
  addOneDay,
} from "../../../utils/functions/index";

const PUBLISH_JOB = gql`
  mutation ($input: _JobInput) {
    postJobOne(input: $input) {
      id
    }
  }
`;
const SET_PUBLISH_JOB = gql`
  mutation ($input: _JobUpdateInput) {
    updateJobOne(input: $input) {
      id
    }
  }
`;
const GET_COMPANY_MEMEBERS = gql`
  query ($input: ID!) {
    getCompanyMembers(companyId: $input) {
      id
      fullName
      avatarUrl
    }
  }
`;

const useStyles = makeStyles({
  root: {
    width: 40,
    height: "20px",
    padding: 0,
    marginRight: "0.6rem",
    "& .MuiSwitch-switchBase": {
      padding: 1,
    },
    "& .MuiSwitch-colorPrimary.Mui-checked + .MuiSwitch-track": {
      backgroundColor: "#34AA44",
    },
    "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
      opacity: 1,
    },
    "& .MuiSwitch-track": {
      backgroundColor: "#9EA0A5",
      borderRadius: "26px!important",
    },
  },
  thumb: {
    width: 18,
    height: 18,
    backgroundColor: "#fff",
    boxShadow: "0 2px 4px #00000026",
  },
});

export default function CreateJob() {
  const experienceLevelOptions = [
    "Entry Level",
    "Intermediate",
    "Mid Level",
    "Senior",
  ];
  const jobTypeOptions = ["Full-Time", "Part-Time", "Contract", "Internship"];

  const { user } = useSelector((state) => state.app);
  const as = user?.roles[0].as;

  const [publishJob] = useMutation(PUBLISH_JOB);
  const [updateJob] = useMutation(SET_PUBLISH_JOB);
  const { loading, data } = useQuery(GET_COMPANY_MEMEBERS, {
    variables: { input: user?.roles[0].company?.id },
  });
  const [isLoading, setIsLoading] = useState(false);
  const [members, setMembers] = useState([]);
  const classes = useStyles();
  const history = useHistory();
  const { state: job } = useLocation();
  const [closingDate, setClosingDate] = useState(
    job?.closingDate ? true : false
  );
  const [isFixed, setIsFixed] = useState(
    job?.salaryRange?.defaultType === "fixed" ? true : false
  );
  const [isHourly, setIsHourly] = useState(
    job?.salaryRange?.defaultType === "hourly" ? true : false
  );

  useEffect(() => {
    if (!loading && data) {
      setMembers(data.getCompanyMembers);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, loading]);

  const formik = useFormik({
    initialValues: {
      jobTitle: job?.jobTitle || "",
      experienceLevel: job?.experienceLevel || "",
      location: job?.location || "",
      mapCordinates: {
        lat: job?.mapCordinates?.coordinates[1] || "",
        lng: job?.mapCordinates?.coordinates[0] || "",
      },
      employmentType: job?.employmentType || "",
      applyLinkUrl: job?.applyLinkUrl || "",
      industry: job?.industry || "",
      salaryRange: {
        defaultType: job?.salaryRange?.defaultType || "annually",
        start: job?.salaryRange?.start || "",
        end: job?.salaryRange?.end || "",
      },
      skills: job?.skills || [],
      description: job?.description || "",
      closingDate: validateTime(job?.closingDate || ""),
      saved: job?.saved || false,
      published: job?.published || false,
      assignedTo: job?.assignedTo || user?.id,
      remotePossible: job?.remotePossible || false,
    },
    validationSchema: createJobSchema,
  });
  const handleSave = () => {
    setIsLoading(true);
    if (job?.jobTitle) {
      const jobInput = {
        id: job?.id,
        jobTitle: formik.values.jobTitle,
        experienceLevel: formik.values.experienceLevel,
        location: formik.values.location,
        mapCordinates: {
          coordinates: [
            parseFloat(formik.values.mapCordinates?.lng),
            parseFloat(formik.values.mapCordinates?.lat),
          ],
        },
        employmentType: formik.values.employmentType,
        description: formik.values.description,
        salaryRange: {
          defaultType: isFixed ? "fixed" : isHourly ? "hourly" : "annually",
          start:
            isFixed || isHourly
              ? 0
              : parseFloat(formik.values.salaryRange.start),
          end: parseFloat(formik.values.salaryRange.end),
        },
        applyLinkUrl: formik.values.applyLinkUrl,
        industry: formik.values.industry,
        skills: [
          ...formik.values.skills?.map((skill) => {
            if (skill.label) {
              return skill.label;
            } else {
              return skill;
            }
          }),
        ],
        saved: true,
        closingDate: closingDate ? addOneDay(formik.values?.closingDate) : "",
        assignedTo: formik.values.assignedTo,
        remotePossible: formik.values.remotePossible,
      };
      updateJob({
        variables: {
          input: jobInput,
        },
      });
      setIsLoading(false);
      history.push(`/job/${job?.id}`, jobInput);
    } else {
      const jobInput = {
        jobTitle: formik.values.jobTitle,
        experienceLevel: formik.values.experienceLevel,
        location: formik.values.location,
        mapCordinates: {
          coordinates: [
            parseFloat(formik.values.mapCordinates?.lng),
            parseFloat(formik.values.mapCordinates?.lat),
          ],
        },
        employmentType: formik.values.employmentType,
        description: formik.values.description,
        salaryRange: {
          defaultType: isFixed ? "fixed" : isHourly ? "hourly" : "annually",
          start:
            isFixed || isHourly
              ? 0
              : parseFloat(formik.values.salaryRange.start),
          end: parseFloat(formik.values.salaryRange.end),
        },
        applyLinkUrl: formik.values.applyLinkUrl,
        industry: formik.values.industry,
        remotePossible: formik.values.remotePossible,
        skills: [...formik.values.skills?.map((skill) => skill.label)],
        closingDate: closingDate ? addOneDay(formik.values?.closingDate) : "",
        company: user?.roles[0].company?.id,
        assignedTo: formik.values.assignedTo,
        saved: true,
        published: false,
      };
      publishJob({ variables: { input: jobInput } }).then(({ data }) => {
        setIsLoading(false);
        jobInput.id = data.postJobOne.id;
        history.push(`/job/${data.postJobOne.id}`, jobInput);
      });
    }
  };

  const handlePublish = () => {
    setIsLoading(true);
    if (job?.jobTitle) {
      const jobInput = {
        id: job?.id,
        jobTitle: formik.values.jobTitle,
        experienceLevel: formik.values.experienceLevel,
        location: formik.values.location,
        mapCordinates: {
          coordinates: [
            parseFloat(formik.values.mapCordinates?.lng),
            parseFloat(formik.values.mapCordinates?.lat),
          ],
        },
        employmentType: formik.values.employmentType,
        description: formik.values.description,
        salaryRange: {
          defaultType: isFixed ? "fixed" : isHourly ? "hourly" : "annually",
          start:
            isFixed || isHourly
              ? 0
              : parseFloat(formik.values.salaryRange.start),
          end: parseFloat(formik.values.salaryRange.end),
        },
        applyLinkUrl: formik.values.applyLinkUrl,
        industry: formik.values.industry,
        remotePossible: formik.values.remotePossible,
        skills: [
          ...formik.values.skills?.map((skill) => {
            if (skill.label) {
              return skill.label;
            } else {
              return skill;
            }
          }),
        ],
        saved: true,
        published: true,
        closingDate: closingDate ? addOneDay(formik.values?.closingDate) : "",
        assignedTo: formik.values.assignedTo,
      };
      updateJob({
        variables: {
          input: jobInput,
        },
      });
      setIsLoading(false);
      history.push(`/job/${job?.id}`, jobInput);
    } else {
      const jobInput = {
        jobTitle: formik.values.jobTitle,
        experienceLevel: formik.values.experienceLevel,
        location: formik.values.location,
        mapCordinates: {
          coordinates: [
            parseFloat(formik.values.mapCordinates?.lng),
            parseFloat(formik.values.mapCordinates?.lat),
          ],
        },
        employmentType: formik.values.employmentType,
        description: formik.values.description,
        salaryRange: {
          defaultType: isFixed ? "fixed" : isHourly ? "hourly" : "annually",
          start:
            isFixed || isHourly
              ? 0
              : parseFloat(formik.values.salaryRange.start),
          end: parseFloat(formik.values.salaryRange.end),
        },
        applyLinkUrl: formik.values.applyLinkUrl,
        industry: formik.values.industry,
        remotePossible: formik.values.remotePossible,
        skills: [...formik.values.skills?.map((skill) => skill.label)],
        closingDate: closingDate ? addOneDay(formik.values?.closingDate) : "",
        company: user?.roles[0].company?.id,
        assignedTo: formik.values.assignedTo,
        saved: true,
        published: true,
      };
      publishJob({ variables: { input: jobInput } }).then(({ data }) => {
        setIsLoading(false);
        jobInput.id = data.postJobOne.id;
        history.push(`/job/${data.postJobOne.id}`, jobInput);
      });
    }
  };

  const handleChangeSlaray = (e, key, subKey) => {
    if (!e.target.value || e.target.value.match(/^\d{1,}(\.\d{0,2})?$/)) {
      const values = e.target.value;
      formik.setFieldValue(key, values);
      const newSlary = {
        ...formik.values[key],
        [subKey]: values,
      };
      formik.setFieldValue(key, newSlary);
      formik.handleChange(e);
    }
  };

  const handleSkillsChange = (obj, type) => {
    if (type === "add") {
      const newSkill = [...formik.values.skills];
      newSkill.push(obj);
      formik.setFieldValue("skills", newSkill);
    } else if (type === "remove") {
      const newSkill = [...formik.values.skills];
      const checked = newSkill.filter((skill) => skill.label !== obj.label);
      formik.setFieldValue("skills", checked);
    }
  };

  const onHandleTextChange = (value) => {
    formik.setFieldValue("description", `${value}`);
  };

  return (
    <div className={"d-flex flex-column overview_background"}>
      {/* SubHeader */}
      <div className="w-100 bg-white _create_job shadow-sm d-flex justify-content-center">
        <div className="w-80 pt-3 pb-3">
          <div className={"d-flex justify-content-between align-items-center"}>
            <div>
              <AppText size="_18">
                {job?.jobTitle ? "Edit Job Post" : "Create A Job Post"}
              </AppText>
            </div>
            <div className={"d-flex"}>
              <div className={"mr-3"}>
                <AppButton
                  type="submit"
                  onClick={handleSave}
                  color="_white"
                  state={submitButton(formik, isFixed, isHourly, closingDate)}
                >
                  <AppText size="_14" color="_c50" weight="_medium">
                    Save Job Post
                  </AppText>
                </AppButton>
              </div>
              <AppButton
                type="submit"
                onClick={handlePublish}
                state={submitButton(formik, isFixed, isHourly, closingDate)}
              >
                {isLoading ? (
                  <AppText color="_c0" size="_14" weight="_medium">
                    loading...
                  </AppText>
                ) : (
                  <AppText color="_c0" size="_14" weight="_medium">
                    Publish Job Post
                  </AppText>
                )}
              </AppButton>
            </div>
          </div>
        </div>
      </div>
      <div className="w-100 d-flex justify-content-center">
        <div className="w-80 pt-4 pb-3 d-flex justify-content-between">
          <div className={"w-70"}>
            <div
              className={"w-100 bg-white shadow-sm mr-3 p-4"}
              style={{
                borderRadius: "4px 4px 0px 0px",
                border: "1px solid #eaedf3",
              }}
            >
              <form>
                <div className="d-flex flex-wrap justify-content-between">
                  {/* Job title */}
                  <div className={"w-100"}>
                    <AppInput
                      label="JOB TITLE"
                      placeholder="Enter job title"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.jobTitle}
                      name="jobTitle"
                      hasError={
                        formik.touched.jobTitle && formik.errors.jobTitle
                          ? true
                          : false
                      }
                    />
                    {formik.touched.jobTitle && formik.errors.jobTitle && (
                      <AppText size="_12" color="_r100">
                        {formik.errors.jobTitle}
                      </AppText>
                    )}
                  </div>
                  {/* Experience Level */}
                  <div className={"w-45 mt-3"}>
                    <AppDropdown
                      defaultValue={formik.values.experienceLevel}
                      formik={formik}
                      name="experienceLevel"
                      label="EXPERIENCE LEVEL"
                      placeholder="Experience required for this job"
                      items={experienceLevelOptions}
                      onChange={(value) =>
                        formik.setFieldValue("experienceLevel", value)
                      }
                    />
                  </div>
                  {/* Location */}
                  <div className={"w-45 mt-3"}>
                    <LocationSearchMapbox
                      value={formik.values?.location}
                      onChange={(data) => {
                        formik.setFieldValue("location", data.shortText);
                        formik.setFieldValue("mapCordinates", {
                          lat: data.lat,
                          lng: data.lng,
                        });
                      }}
                      onValueChange={(e) => {
                        formik.setFieldValue("location", e.target.value);
                      }}
                      formik={formik}
                    />
                  </div>
                  {/* Employment Type */}
                  <div className={"w-45 mt-3"}>
                    <AppDropdown
                      defaultValue={formik.values.employmentType}
                      formik={formik}
                      name="employmentType"
                      label="EMPLOYMENT TYPE"
                      placeholder="Select employment type"
                      items={jobTypeOptions}
                      onChange={(value) =>
                        formik.setFieldValue("employmentType", value)
                      }
                    />
                  </div>
                  {/* Salary Range*/}
                  <div className={"w-45 mt-3 d-flex flex-column"}>
                    <SalaryRange
                      onChange={handleChangeSlaray}
                      formik={formik}
                      isFixed={isFixed}
                      isHourly={isHourly}
                      setIsFixed={setIsFixed}
                      setIsHourly={setIsHourly}
                    />
                  </div>
                  {/* Company Link */}
                  <div className={"w-100"}>
                    <AppInput
                      label="JOB LINK"
                      placeholder="Enter job link"
                      onChange={(e) =>
                        formik.setFieldValue("applyLinkUrl", e.target.value)
                      }
                      onBlur={formik.handleBlur}
                      value={formik.values.applyLinkUrl}
                      name="jobLink"
                      hasError={
                        formik.touched.applyLinkUrl &&
                        formik.errors.applyLinkUrl
                          ? true
                          : false
                      }
                    />
                    {formik.errors.applyLinkUrl && (
                      <AppText size="_12" color="_r100">
                        {formik.errors.applyLinkUrl}
                      </AppText>
                    )}
                  </div>
                  {/* Company INDUSTRY */}
                  <div className={"w-100 mt-3"}>
                    <AppDropdown
                      defaultValue={formik.values.industry}
                      formik={formik}
                      name="industry"
                      label="INDUSTRY (OPTIONAL)"
                      placeholder="Select Industry"
                      items={industrys}
                      onChange={(value) =>
                        formik.setFieldValue("industry", value)
                      }
                    />
                  </div>
                </div>
              </form>
              {/* Text Editor */}
              <div className={"w-100 mt-3"}>
                <div className={"mb-2"}>
                  <AppText size="_12" color="_c50" weight="_medium">
                    JOB DESCRIPTION
                  </AppText>
                </div>
                <AppTextEditor
                  onHandleTextChange={onHandleTextChange}
                  description={formik.values.description}
                />
              </div>
              <div className="d-flex flex-wrap justify-content-between">
                {/* Skills */}
                <div className={"w-100 mt-3"}>
                  <SkillsSearch
                    onChange={handleSkillsChange}
                    skills={formik.values.skills}
                  />
                </div>
              </div>
            </div>
            {/* Footer */}
            <div className={"w-100 shadow-sm mr-3 p-4 _create_job_footer"}>
              <button
                onClick={() => history.push("/jobs")}
                style={{ background: "none", border: "none" }}
                className={"ml-3"}
              >
                <AppText size="_14" color="_c50" weight="_medium">
                  Cancel
                </AppText>
              </button>
            </div>
          </div>
          <div className={"w-25 p-3"}>
            {/* Remote */}
            <AppText size="_16" weight="_medium">
              <Switch
                checked={formik.values.remotePossible}
                onChange={() =>
                  formik.setFieldValue(
                    "remotePossible",
                    !formik.values.remotePossible
                  )
                }
                focusVisibleClassName={classes.focusVisible}
                disableRipple
                color="primary"
                classes={{
                  root: classes.root,
                  switchBase: classes.switchBase,
                  thumb: classes.thumb,
                  track: classes.track,
                }}
              />
              Make Job Remote
            </AppText>
            <div className={"mt-2 mb-5"}>
              <AppText size="_14" weight="_small" color="_c50">
                Enabling this will mean this job role will be available to
                candidates who want to work remotely only.
              </AppText>
            </div>
            {/* Closing date */}
            <AppText size="_16" weight="_medium">
              <Switch
                checked={closingDate}
                onChange={() => {
                  setClosingDate(!closingDate);
                }}
                focusVisibleClassName={classes.focusVisible}
                disableRipple
                color="primary"
                classes={{
                  root: classes.root,
                  switchBase: classes.switchBase,
                  thumb: classes.thumb,
                  track: classes.track,
                }}
              />
              Job Closing Date
            </AppText>
            <div className={"mt-2"}>
              <AppText size="_14" weight="_small" color="_c50">
                Enabling this will mean this jobs post will expire and be
                automatically unpublished from the Job Seekers
              </AppText>
            </div>
            <div className={"mt-3"}>
              <AppCalendar
                value={formik.values?.closingDate}
                placeholder={"Select Date"}
                onChange={(v) => {
                  formik.setFieldValue("closingDate", validateTime(v));
                }}
                isDisabled={!closingDate}
              />
            </div>
            {/* Assign to Admin */}
            {as !== "Member" ? (
              <div>
                <div className="_sep_ mt-4 mb-4" />
                <AppText size="_16" weight="_medium">
                  Assign to Admin
                </AppText>
                <div className={"mt-2 mb-3"}>
                  <AppText size="_12" weight="_small" color="_c50">
                    This admin will be able to move canditates through the
                    recruitment process
                  </AppText>
                </div>
                {!loading && data ? (
                  <AppDropAdmin
                    name="admin"
                    users={members}
                    onChange={(value) =>
                      formik.setFieldValue("assignedTo", value.id)
                    }
                    id={job?.assignedTo}
                  />
                ) : (
                  <p>loading...</p>
                )}
              </div>
            ) : null}
            <div className="_sep_ mt-4 mb-4" />
          </div>
        </div>
      </div>
    </div>
  );
}
