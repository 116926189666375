import React from "react";
import './styles.css'
import { useHistory } from "react-router-dom";
import AppButton from "../molecules/AppButton";
import AppText from "../atoms/AppText";
import { fire } from "../../firebase";

function HeaderLogin({ setupStack = false }) {
    const history = useHistory();
    const handleOnClickImg = () => {
        history.push('/')
    }

    const logout = () => {
        fire
            .auth()
            .signOut()
            .then(() => {
                window.location.replace("/");
            });
    };

    return (
        <div className="header px-4 d-flex align-items-center" >
            <div className='h-100 w-100 d-flex align-items-center justify-content-between'>
                <div className={'d-flex'}>
                    <img className='logo-img' alt='logo' src='/logo.png' height='32' onClick={handleOnClickImg} style={{ cursor: 'pointer' }} />
                    <div className='line ml-3' />
                </div>
                
                {setupStack && (
                    <div className={`pointer`}>
                        <AppButton type='submit' color='_white' onClick={logout}>
                            <AppText size='_14'>
                                Log Out
                            </AppText>
                        </AppButton>
                    </div>
                )}
            </div>
        </div>
    )
}

export default HeaderLogin;