import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import AppText from "../../../components/atoms/AppText";
import CompanyAdmins from "../../../components/orgnisms/CompanyAdmins";
import CompanyBox from "../../../components/orgnisms/CompanyBox";
import CompanyInformation from "../../../components/orgnisms/CompanyInformation";
import CompanyImgs from "../../../components/orgnisms/CompanyImgs";
import CompanySocial from "../../../components/orgnisms/CompanySocial";
import { gql, useQuery, useMutation } from "@apollo/client";
import EmbedJobs from "../../../components/orgnisms/EmbedJobs";
import Billing from "../../../components/orgnisms/Billing";

const GET_COMPANY_MEMEBERS = gql`
  query ($input: ID!) {
    getCompanyMembers(companyId: $input) {
      id
      fullName
      avatarUrl
      roles {
        as
      }
    }
  }
`;

const SEND_JOIN_INVITATION = gql`
  mutation ($input: InvitationInput) {
    sendJoinInvitation(input: $input)
  }
`;

const REMOVE_MANAGER_COMPANY = gql`
  mutation ($input: RemoveInput) {
    removeManagerFromCompany(input: $input)
  }
`;

const GET_CAREER_PAGES_STATUS = gql`
  query ($companyId: ID!) {
    getCareerPageStatus(companyId: $companyId) {
      id
      status
      JobsToIframe
    }
  }
`;

export default function ManageAccount() {
  const { user } = useSelector((state) => state.app);
  const company = user?.roles[0].company;
  const as = user?.roles[0].as;

  const [sendJoinInvitation] = useMutation(SEND_JOIN_INVITATION);
  const [removeManagerCompany] = useMutation(REMOVE_MANAGER_COMPANY);

  const {
    loading: careerPageLoading,
    data: careerPageData,
    refetch: careerRefetch,
  } = useQuery(GET_CAREER_PAGES_STATUS, {
    variables: { companyId: company.id },
  });

  const { data, refetch } = useQuery(GET_COMPANY_MEMEBERS, {
    variables: { input: company.id },
    pollInterval: 2000,
    fetchPolicy: "no-cache",
  });

  useEffect(() => {
    careerRefetch();
  }, [careerRefetch]);

  const handleRemove = (member) => {
    removeManagerCompany({
      variables: { input: { companyId: company.id, managerId: member.id } },
    });
    refetch();
  };

  const handleSendInvitation = (email) => {
    sendJoinInvitation({
      variables: {
        input: {
          companyId: company.id,
          email: email,
          managerName: user?.fullName,
        },
      },
    });
  };

  return (
    <div className={"d-flex flex-column overview_background mt-5"}>
      {/* SubHeader */}
      <div className="bg-white _create_job shadow-sm d-flex justify-content-center">
        <div className="w-75 py-2 my-3">
          <div className={"d-flex justify-content-between align-items-center"}>
            <div className="d-flex">
              <AppText size="_22">{`${company.name}`}</AppText>
            </div>
            <div className={"d-flex"}>
              <CompanyAdmins
                members={data?.getCompanyMembers}
                onRemove={handleRemove}
                onSend={handleSendInvitation}
                iam={as}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="d-flex justify-content-center w-100">
        <div className={"w-75 d-flex justify-content-between"}>
          {/* Left Box */}
          <div className={"w-48 mt-4"}>
            <CompanyBox company={company} />
            <CompanyInformation company={company} iam={as} />
            {!careerPageLoading && careerPageData ? (
              <Billing careerStatus={careerPageData} />
            ) : null}
          </div>
          {/* Right Box */}
          <div className={"w-48 mt-4"}>
            <CompanyImgs company={company} iam={as} />
            <CompanySocial company={company} iam={as} />
            {!careerPageLoading && careerPageData ? (
              <EmbedJobs
                company={company}
                careerStatus={careerPageData}
                refresh={careerRefetch}
              />
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
}
