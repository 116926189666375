import React, { useState, useRef, useCallback, useEffect } from "react";
import AWS from "aws-sdk";
import ReactCrop from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";
import { useSelector } from "react-redux";
import { useMutation, gql } from "@apollo/client";
import { uid } from "uid";
import ModalFooter from "../molecules/ModalFooter";

const UPDATE_HEADER = gql`
  mutation ($input: _CompanyInputUpdate) {
    updateCompany(input: $input) {
      header
    }
  }
`;

export default function HeaderUploadModal({ temp, onClose }) {
  const [crop, setCrop] = useState({
    unit: "px",
    width: 0,
    height: 240,
  });
  const [imageLoaded, setImageLoaded] = useState(false);

  const { user } = useSelector((state) => state.app);

  const [completedCrop, setCompletedCrop] = useState(null);

  const [updateHeader] = useMutation(UPDATE_HEADER);

  const [isLoading, setIsLoading] = useState(false);

  const imageRef = useRef(null);

  const onSave = async () => {
    const croppedImg = await getCroppedImg(imageRef, completedCrop);

    setIsLoading(true);
    const s3Bucket = new AWS.S3({
      accessKeyId: "AKIAJ3P5KOR27P6EV5MA",
      secretAccessKey: "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",
      region: "eu-west-2",
    });
    const params = {
      Bucket: "tapobucket",
      Key: `${uid(10)}.jpg`,
      Body: croppedImg,
      ContentDisposition: "attachment",
    };

    const upload = s3Bucket.upload(params);

    upload.send((err, data) => {
      if (err) console.log(err);

      updateHeader({
        variables: {
          input: {
            companyId: user?.roles[0].company.id,
            values: { header: data.Location },
          },
        },
      }).then(() => {
        setIsLoading(false);
        onClose();
      });
    });
  };

  const onLoad = useCallback((img) => {
    imageRef.current = img;
    setImageLoaded(true);
  }, []);

  useEffect(() => {
    if (imageLoaded) {
      const newCrop = { ...crop };
      newCrop.width = imageRef.current.width;
      setCompletedCrop({ x: 0, y: 0, ...newCrop });
      setCrop({ ...newCrop });
    }
    // eslint-disable-next-line
  }, [imageLoaded]);

  function getCroppedImg(image, crop) {
    const Image = image.current;

    const canvas = document.createElement("canvas");
    const scaleX = Image.naturalWidth / Image.width;
    const scaleY = Image.naturalHeight / Image.height;
    canvas.width = crop.width;
    canvas.height = crop.height;
    const ctx = canvas.getContext("2d");

    ctx.drawImage(
      Image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width,
      crop.height
    );

    return new Promise((resolve) => {
      canvas.toBlob(
        (blob) => {
          resolve(blob);
        },
        "image/jpeg",
        1
      );
    });
  }

  return (
    <div>
      <div style={{ width: "100%" }}>
        <ReactCrop
          src={temp}
          crop={crop}
          onImageLoaded={onLoad}
          onChange={(newCrop) => setCrop(newCrop)}
          onComplete={(c) => setCompletedCrop(c)}
          keepSelection
          locked
        />
      </div>
      <ModalFooter
        onClose={onClose}
        isLoading={isLoading}
        hasErrors={false}
        onSave={onSave}
      />
    </div>
  );
}
