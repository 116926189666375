import React from "react";
import { Check } from "@material-ui/icons";
import AppText from "./AppText";



export default function MatchJob({ type, value }) {
  return (
    <div
      className={"d-flex justify-content-center align-items-center py-1 px-2 mr-2 mt-2"}
      style={{ border: "1px solid #D8DCE6", borderRadius: "5px", boxShadow: "0 1px 6px #eeeeee" }}>
      <Check style={{ fontSize: '14px', color: "#03CA98", marginRight: '5px' }} />
      <AppText size={'_14'}>{type}: {value}</AppText>
    </div>
  );
}
