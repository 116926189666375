import React, { useRef, useEffect, useState } from 'react'
import AppText from '../atoms/AppText'

export default function Skills({ title, skills, isSelect }) {
    const elRef = useRef(null);
    useEffect(() => {
        const handleScroll = () => {
            if (elRef?.current?.getBoundingClientRect()?.top - 100 <= 0 && 100 + elRef?.current?.getBoundingClientRect()?.top >= 1) {
                isSelect('Skills')
            }
        }
        document.addEventListener('scroll', handleScroll);
        return () => {
            document.removeEventListener('scroll', handleScroll);
        }
    }, [isSelect])

    const [general] = useState(skills.filter((item) => item.value === 'general'))
    const [softSkills] = useState(skills.filter((item) => item.value === 'softSkills'))
    const [toolsAndTechnologies] = useState(skills.filter((item) => item.value === 'toolsAndTechnologies'))
    const [others] = useState(skills.filter((item) => item.value === 'others'))

    return (
        <div className={`mt-3 mb-4 w-100 card_style`} id={'Skills'} ref={elRef}>
            {/* Header */}
            <header className={'p-4'}>
                <AppText size='_18' weight='_medium'>
                    {title}
                </AppText>
            </header>
            <div className='_sep_' />
            {/* General */}
            <div className='mt-2 mt-3'>
                {general.length > 0 && (
                    <div className='mb-2 px-3 pb-3 d-flex flex-column'>
                        <AppText size='_14' weight='_small' color='_c90'>
                            General
                        </AppText>
                        <div className={'mt-1'}>
                            {general.map((g, i) => (
                                <span className={'mr-3'}>
                                    <AppText size='_16' weight={'_medium'}>{g.label}</AppText>
                                </span>
                            ))}
                        </div>
                        {
                            (general.length > 0 && (toolsAndTechnologies.length > 0 || others.length > 0 || softSkills.length > 0)) && (
                                <div className='_sep_ mt-3' />
                            )
                        }
                    </div>
                )}

                {/* Tools And Technologies */}
                {toolsAndTechnologies.length > 0 && (
                    <div className='mb-2 px-3 pb-3 d-flex flex-column'>
                        <AppText size='_14' weight='_small' color='_c90'>
                            Tools & Technologies
                        </AppText>
                        <div className={'mt-1'}>
                            {toolsAndTechnologies.map((g, i) => (
                                <span className={'mr-3'}>
                                    <AppText size='_16' weight={'_medium'}>{g.label}</AppText>
                                </span>
                            ))}
                        </div>
                        {
                            (toolsAndTechnologies.length > 0 && (others.length > 0 || softSkills.length > 0)) && (
                                <div className='_sep_ mt-3' />
                            )
                        }
                    </div>
                )}

                {/* Soft Skills */}
                {softSkills.length > 0 && (
                    <div className='mb-2 px-3 pb-3 d-flex flex-column'>
                        <AppText size='_14' weight='_small' color='_c90'>
                            Soft Skills
                        </AppText>
                        <div className={'mt-1'}>
                            {softSkills.map((g, i) => (
                                <span className={'mr-3'}>
                                    <AppText size='_16' weight={'_medium'}>{g.label}</AppText>
                                </span>
                            ))}
                        </div>
                        {
                            (softSkills.length > 0 && (others.length > 0)) && (
                                <div className='_sep_ mt-3' />
                            )
                        }
                    </div>
                )}
                {/* Others */}
                {others.length > 0 && (
                    <div className='mb-2 px-3 pb-3 d-flex flex-column'>
                        <AppText size='_14' weight='_small' color='_c90'>
                            Others
                        </AppText>
                        <div className={'mt-1'}>
                            {others.map((g, i) => (
                                <span className={'mr-3'}>
                                    <AppText size='_16' weight={'_medium'}>{g.label}</AppText>
                                </span>
                            ))}
                        </div>
                    </div>
                )}
            </div>
        </div >
    )
}