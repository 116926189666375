import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { gql, useLazyQuery } from "@apollo/client";
import { useFormik } from "formik";
import { CircularProgress } from "@material-ui/core";
import query from "query-string";
import { useDispatch } from "react-redux";

import { fire } from "../../../firebase";
import AppInput from "../../../components/molecules/AppInput";
import AppPassword from "../../../components/molecules/AppPassword";
import AppText from "../../../components/atoms/AppText";
import AppButton from "../../../components/molecules/AppButton";
import { SignupShema } from "../../../utils/schema/login";
import { saveTokens } from "../Ducks";
import { Checkbox } from "@material-ui/core";
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';

const CHECK_EMAIL = gql`
  query($email: String!) {
    checkManagerExists(email: $email)
  }
`;

export default function Signup() {
  const [isLoading, setIsLoading] = useState(false);
  const [isCheck, setIsCheck] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [checkManagerExists, { loading, data }] = useLazyQuery(CHECK_EMAIL);

  const dispatch = useDispatch();

  const qc = query.parse(window.location.search);

  const formik = useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
      email: "",
      password: "",
      isNewEmail: false,
    },
    validationSchema: SignupShema,
    onSubmit: async (values, { resetForm }) => {
      const { email, password, firstName, lastName } = values;
      setIsLoading(true);
      try {
        await fire.auth().createUserWithEmailAndPassword(email, password);
        fire.auth().currentUser.updateProfile({ displayName: `${firstName} ${lastName}` });
        dispatch(saveTokens({ ...qc }));
        resetForm();
        setIsLoading(false);
        setHasError(false);
      } catch (e) {
        if (e) {
          setIsLoading(false);
          setHasError(e);
        }
      }
    },
  });

  useEffect(() => {
    if (!loading && data) {
      formik.setFieldValue("isNewEmail", data.checkManagerExists);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, loading]);

  const onEmailBlur = () => {
    checkManagerExists({ variables: { email: formik.values.email } });
  };

  return (
    <div className='d-flex flex-column justify-content-center align-items-center signup my-5'>
      <div className={`d-flex flex-wrap w-60`}>
        <div className={`w-45 app-box`}>
          <div className='p-4'>
            <AppText size='_22'>Hey there!</AppText>
            <div>
              <AppText size='_16' color='_c50'>
                Create Account
              </AppText>
            </div>

            <div className='mt-4' />
            <form onSubmit={formik.handleSubmit}>
              <div className="d-flex justify-content-between">
                <div className="w-48">
                  <AppInput
                    label='FIRST NAME'
                    placeholder='First Name'
                    name='firstName'
                    value={formik.values.firstName}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    hasError={formik.touched.firstName && formik.errors.firstName ? true : false}
                  />
                  <div>
                    {formik.touched.firstName && formik.errors.firstName ? (
                      <AppText size='_12' color='_r100'>
                        {formik.errors.firstName}
                      </AppText>
                    ) : null}
                  </div>
                </div>
                <div className="w-48">
                  <AppInput
                    label='LAST NAME'
                    placeholder='Last Name'
                    name='lastName'
                    value={formik.values.lastName}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    hasError={formik.touched.lastName && formik.errors.lastName ? true : false}
                  />
                  <div>
                    {formik.touched.lastName && formik.errors.lastName ? (
                      <AppText size='_12' color='_r100'>
                        {formik.errors.lastName}
                      </AppText>
                    ) : null}
                  </div>
                </div>
              </div>
              <div className='mt-3'>
                <AppInput
                  label='EMAIL ADDRESS'
                  placeholder='Email Address'
                  name='email'
                  value={formik.values.email}
                  onChange={formik.handleChange}
                  onBlur={(e) => {
                    formik.handleBlur("email")(e);
                    onEmailBlur();
                  }}
                  hasError={formik.touched.email && formik.errors.email ? true : false}
                />
                <div>
                  {formik.touched.email && formik.errors.email ? (
                    <AppText size='_12' color='_r100'>
                      {formik.errors.email}
                    </AppText>
                  ) : null}
                  {formik.errors.isNewEmail && formik.touched.email ? (
                    <AppText size='_12' color='_r100'>
                      {formik.errors.isNewEmail}
                    </AppText>
                  ) : null}
                </div>
              </div>
              <div className='mt-3'>
                <AppPassword
                  label='PASSWORD'
                  placeholder='Password'
                  name='password'
                  value={formik.values.password}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  hasError={formik.touched.password && formik.errors.password ? true : false}
                />
                <div>
                  {formik.touched.password && formik.errors.password ? (
                    <AppText size='_12' color='_r100'>
                      {formik.errors.password}
                    </AppText>
                  ) : null}
                </div>

                <div>
                  {hasError && (
                    <AppText size='_12' color='_r100'>
                      {hasError.message}
                    </AppText>
                  )}
                </div>
              </div>
              <div className='mt-3 d-flex'>
                <Checkbox
                  name='Fixed'
                  value='Fixed'
                  checked={isCheck}
                  icon={<CheckBoxOutlineBlankIcon style={{ fontSize: '26px', color: "#D8DCE6" }} />}
                  checkedIcon={<CheckBoxIcon style={{ fontSize: '26px', color: "#03CA98" }} />}
                  onChange={() => {
                    setIsCheck(!isCheck)
                  }}
                  style={{
                    padding: 0,
                    marginRight: '5px'
                  }}
                />
                <AppText size='_14' color='_c90'>
                  By signing up you agree to our <a href={'https://www.taprecruit.co.uk/privacy'} className={'txt _g90'} target="_blank" rel="noopener noreferrer">Privacy Policy</a>
                </AppText>
              </div>
              <div className='mt-4'>
                <AppButton
                  width='_full'
                  type='submit'
                  state={
                    (formik.errors.password) || (formik.errors.password === undefined && !formik.values.password) ||
                      (formik.errors.email) || (formik.errors.email === undefined && !formik.values.email) ||
                      (formik.errors.lastName) || (formik.errors.lastName === undefined && !formik.values.lastName) ||
                      (formik.errors.firstName) || (formik.errors.firstName === undefined && !formik.values.firstName) ||
                      (!isCheck)
                      ? true : false}
                >
                  {isLoading ? (
                    <CircularProgress style={{ color: "#fff", height: "30px", width: "30px" }} />
                  ) : (
                    <AppText color='_c0' size='_14'>
                      Create an Account
                    </AppText>
                  )}
                </AppButton>
              </div>
            </form>
          </div>
        </div>
        {/* hidden on mobile and tablet */}
        <div className='w-55 img-right'>
          <div className='d-flex flex-column justify-content-around pl-5 pb-4 h-100 pt-4' style={{ backgroundColor: "#FEEBD2" }}>
            <img src="ill.svg" alt="ill" height="300" className={'mb-4'} />
            <AppText size='_22' weight="_medium">
              Providing a unified recruitment experience
            </AppText>
          </div>
        </div>
        <div className='mt-4 pl-4'>
          <AppText size='_14' color='_c50'>
            Already have an account?
            <Link to='/login'>
              <span className='_g90 ml-1'>Log In</span>
            </Link>
          </AppText>
          <div className='mt-2'>
            <AppText size='_14' color='_c50'>
              Forgot your password?
              <Link to='/forgot-password'>
                <span className='_g90 ml-1'>Request a new password</span>
              </Link>
            </AppText>
          </div>
        </div>
      </div>
    </div>
  );
}
