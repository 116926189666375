import React, { useEffect, useState } from "react";
import { Bar } from "react-chartjs-2";
import { useQuery, gql } from "@apollo/client";
import { useSelector } from "react-redux";
import JobOverview from "../../../components/orgnisms/JobOverview";
import CompanyCard from "../../../components/orgnisms/CompanyCard";
import HeaderContainer from "../../../components/molecules/HeaderContainer";
import JobsList from "../../../components/molecules/JobsList";
// import InterviewsList from "../../../components/molecules/InterviewsList";
import { useHistory } from "react-router-dom";
import { textCut } from "../../../utils/functions/index";

// import { interviews } from "../../../utils/dummy/index";
import { Skeleton } from "@material-ui/lab";
import { GET_MY_JOBS } from "../../../utils/apolloSchema";

const GET_MY_JOB_STATS = gql`
  query ($input: AccessInput) {
    getMyJobStats(input: $input) {
      jobs
      views
      applications
      shortlist
    }
  }
`;

const POPULAR_JOBS_DATASET = gql`
  query ($input: AccessInput) {
    popularJobsDataset(input: $input) {
      jobTitle
      visitCount
      applicationsCount
    }
  }
`;

export default function Overview() {
  const history = useHistory();
  const handleClickJobs = () => {
    history.push("/jobs");
  };

  // const handleClickInterviews = () => {
  //   history.push("/interviews");
  // };

  const [dataset, setDataset] = useState({});
  const { user } = useSelector((state) => state.app);

  const { loading, data: jobsData } = useQuery(GET_MY_JOBS, {
    variables: {
      input: {
        managerId: user.id,
        companyId: user?.roles[0].company?.id,
        AccessPrilivages: user?.roles[0].as,
        limit: 3,
        skip: 0,
        filterBy: "active",
        sortBy: "recent",
      },
    },
  });
  const { loading: statsLoading, data: statsData } = useQuery(
    GET_MY_JOB_STATS,
    {
      variables: {
        input: {
          companyId: user?.roles[0].company?.id,
          managerId: user.id,
          AccessPrilivages: user?.roles[0].as,
        },
      },
      pollInterval: 2000,
    }
  );

  const { loading: dataLoading, data: dataSets } = useQuery(
    POPULAR_JOBS_DATASET,
    {
      variables: {
        input: {
          companyId: user?.roles[0].company?.id,
          managerId: user.id,
          AccessPrilivages: user?.roles[0].as,
        },
      },
    }
  );

  useEffect(() => {
    if (!dataLoading && dataSets) {
      let labels = [];
      const datasets = [];

      labels = dataSets.popularJobsDataset.map((set) =>
        textCut(set.jobTitle, 22)
      );
      const views = {
        label: "Viewed",
        backgroundColor: "#EAECEE",
        barPercentage: 0.5,
        barThickness: 9,
      };
      views.data = dataSets.popularJobsDataset.map((view) => view.visitCount);

      const applied = {
        label: "Applied",
        backgroundColor: "#34AA44",
        barPercentage: 0.5,
        barThickness: 9,
      };
      views.data = dataSets.popularJobsDataset.map((view) => view.visitCount);
      applied.data = dataSets.popularJobsDataset.map(
        (view) => view.applicationsCount
      );
      datasets.push(views);
      datasets.push(applied);

      setDataset({ ...dataset, labels, datasets });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataLoading, dataSets]);

  useEffect(() => {}, [loading, jobsData]);

  return (
    <div className={"d-flex justify-content-center overview_background mt-4"}>
      <div className="w-80 pt-4">
        <div className="w-100 mt-4 d-flex justify-content-between">
          {/* JobOverview */}
          <div className="w-70 d-flex flex-wrap justify-content-between mr-3">
            {!statsLoading && statsData && (
              <>
                <div className="w-100 d-flex justify-content-between mb-2">
                  <JobOverview
                    title={"TOTAL JOB"}
                    number={statsData?.getMyJobStats?.jobs[0]}
                    text={`+${statsData?.getMyJobStats?.jobs[1]} this week`}
                    newData={statsData?.getMyJobStats?.jobs[1]}
                  />
                  <JobOverview
                    title={"TOTAL JOB VIEWS"}
                    number={statsData?.getMyJobStats?.views[0]}
                    text={`+${statsData?.getMyJobStats?.views[1]} this week`}
                    newData={statsData?.getMyJobStats?.views[1]}
                  />
                </div>
                <div className="w-100 d-flex justify-content-between">
                  <JobOverview
                    title={"TOTAL APPLICATIONS"}
                    number={statsData?.getMyJobStats?.applications[0]}
                    text={`+${statsData?.getMyJobStats?.applications[1]} this week`}
                    newData={statsData?.getMyJobStats?.applications[1]}
                  />
                  {/* <JobOverview
                    title={"SHORTLISTED CANDIDATES"}
                    number={statsData?.getMyJobStats?.shortlist[0]}
                    text={`+${statsData?.getMyJobStats?.shortlist[1]} this week`}
                    newData={statsData?.getMyJobStats?.shortlist[1]}
                  /> */}
                </div>
              </>
            )}
            {statsLoading && (
              <div className="d-flex flex-wrap justify-content-between">
                <Skeleton variant="rect" width={370} height={125} />
                <Skeleton variant="rect" width={370} height={125} />
                <Skeleton variant="rect" width={370} height={125} />
                <Skeleton variant="rect" width={370} height={125} />
              </div>
            )}
          </div>
          <CompanyCard />
        </div>
        {/* Job graphics */}
        {!dataLoading &&
          dataset?.datasets?.length &&
          dataset?.datasets[0]?.data[0] > 1 &&
          dataset?.datasets[0]?.data[1] > 1 && (
            <div
              className={"w-100 mt-3 bg-white"}
              style={{ border: "1px solid #eaedf3" }}
            >
              <HeaderContainer
                title={"Popular Jobs"}
                onClick={() => handleClickJobs()}
                buttonTitle={"See all Jobs"}
              />
              <div>
                <Bar
                  data={dataset}
                  options={{
                    layout: {
                      padding: 50,
                    },
                    scales: {
                      x: {
                        grid: {
                          display: false,
                          offset: false,
                        },
                      },
                    },
                  }}
                />
              </div>
            </div>
          )}

        <div className={"w-100 d-flex justify-content-between pb-3"}>
          {/* Recent Jobs */}
          {!loading && jobsData?.getMyJobs?.count ? (
            <div className="w-48">
              <div className={"mt-3 card_style"}>
                <HeaderContainer
                  title={"Recent Jobs"}
                  onClick={() => handleClickJobs()}
                  buttonTitle={"See all Jobs"}
                />
                <div className={"px-3 py-4"}>
                  {!loading &&
                    jobsData &&
                    jobsData.getMyJobs?.jobs?.map((job) => {
                      return <JobsList job={job} user={user} key={job.id} />;
                    })}
                </div>
              </div>
            </div>
          ) : null}

          {/* Upcoming Interviews */}
          {/* <div className={"w-48"}>
            <div className={"mt-3 card_style"}>
              <HeaderContainer
                title={"Upcoming Interviews"}
                onClick={() => handleClickInterviews()}
                buttonTitle={"See all Interviews"}
              />
              <div className={"px-3 py-4"}>
                {interviews?.map((interview) => {
                  return <InterviewsList interview={interview} />;
                })}
              </div>
            </div>
          </div> */}
        </div>
      </div>
    </div>
  );
}
