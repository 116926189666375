import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { useFormik } from "formik";
import { useMutation, gql } from "@apollo/client";
import { useSelector } from "react-redux";
import AppInput from "../../../components/molecules/AppInput";
import BoardingPanel from "../../../components/orgnisms/BoardingPanel";
import { socialSchema } from "../../../utils/schema/accountSetupSchema";
import AppText from "../../../components/atoms/AppText";
import { useAccountSetup } from "../../../Context/AccountSetupContext";
import { addHttp } from '../../../utils/functions/index'

const SET_SOCIAL_LINKS = gql`
  mutation($input: _CompanyInputUpdate) {
    updateCompany(input: $input) {
      id
    }
  }
`;
export default function Social({ onNext, onBack, step }) {
  const { accountSetup, setAccountSetup } = useAccountSetup();
  const [UpdateCompany] = useMutation(SET_SOCIAL_LINKS);

  // const isDesktop = useMediaQuery({ minWidth: 992 });
  // const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 991 });

  const { user } = useSelector((state) => state.app);

  const company = user?.roles[0].company;

  const [isLoading, setIsLoading] = useState(false);

  const history = useHistory();

  if (step !== 2) {
    history.push('/')
  }

  const formik = useFormik({
    initialValues: {
      facebook: accountSetup?.social?.facebook || company?.facebookLink || "",
      twitter: accountSetup?.social?.twitter || company?.twitterLink || "",
      instagram: accountSetup?.social?.instagram || company?.instagramLink || "",
      linkedIn: accountSetup?.social?.linkedIn || company?.linkedinLink || "",
    },
    validationSchema: socialSchema,
    onSubmit: (values) => {
      setIsLoading(true);
      const { facebook, twitter, instagram, linkedIn } = values;
      const data = {
        facebookLink: facebook,
        twitterLink: twitter,
        linkedinLink: linkedIn,
        instagramLink: instagram,
      };
      if (accountSetup?.basic?.id) {
        UpdateCompany({
          variables: { input: { companyId: company?.id || accountSetup?.basic?.id, values: data } },
        }).then(() => {
          setAccountSetup({ ...accountSetup, social: values });
          onNext(3);
          setIsLoading(false);
          history.push("/image");
        });
      }
    },
  });

  const handleNext = () => {
    onNext(3);
    history.push("/image");
  };

  const handleBack = () => {
    onBack(1);
    history.push("/basic");
  };

  const handleChangeValue = (value, type) => {
    formik.setFieldValue(type, addHttp(value))
  }

  return (
    <div>
      <BoardingPanel
        heading='Social Links'
        subHeading='Let people connect with your company'
        // state={state}
        onSubmit={formik.handleSubmit}
        isLoading={isLoading}
        onSubmitText={"Next"}
        onSkip={handleNext}
        onBack={handleBack}
      >
        <form>
          <div className='d-flex flex-wrap justify-content-between'>
            {/* Facebook */}
            <div className={"w-45 mt-3"}>
              <AppInput
                label='FACEBOOK'
                placeholder='https://www.facebook.com/'
                // editable={state === "preview" ? false : true}
                onChange={(e) => handleChangeValue(e.target.value, 'facebook')}
                onBlur={formik.handleBlur}
                value={formik.values.facebook}
                name='facebook'
                hasError={formik.touched.facebook && formik.errors.facebook ? true : false}
              />
              {formik.touched.facebook && formik.errors.facebook && (
                <AppText size='_12' color='_r100'>
                  {formik.errors.facebook}
                </AppText>
              )}
            </div>

            {/* Twitter */}
            <div className={"w-45 mt-3"}>
              <AppInput
                label='TWITTER'
                placeholder='https://twitter.com/'
                // editable={state === "preview" ? false : true}
                onChange={(e) => handleChangeValue(e.target.value, 'twitter')}
                onBlur={formik.handleBlur}
                value={formik.values.twitter}
                name='twitter'
                hasError={formik.touched.twitter && formik.errors.twitter ? true : false}
              />
              {formik.touched.twitter && formik.errors.twitter && (
                <AppText size='_12' color='_r100'>
                  {formik.errors.twitter}
                </AppText>
              )}
            </div>
            {/* Instagram */}
            <div className={"w-45 mt-3"}>
              <AppInput
                label='INSTAGRAM'
                placeholder='https://instagram.com/'
                // editable={state === "preview" ? false : true}
                onChange={(e) => handleChangeValue(e.target.value, 'instagram')}
                onBlur={formik.handleBlur}
                value={formik.values.instagram}
                name='instagram'
                hasError={formik.touched.instagram && formik.errors.instagram ? true : false}
              />
              {formik.touched.instagram && formik.errors.instagram && (
                <AppText size='_12' color='_r100'>
                  {formik.errors.instagram}
                </AppText>
              )}
            </div>
            {/* Web Link */}
            <div className={"w-45 mt-3"}>
              <AppInput
                label='LINKEDIN'
                placeholder='https://linkedIn.com/'
                // editable={state === "preview" ? false : true}
                onChange={(e) => handleChangeValue(e.target.value, 'linkedIn')}
                onBlur={formik.handleBlur}
                value={formik.values.linkedIn}
                name='linkedIn'
                hasError={formik.touched.linkedIn && formik.errors.linkedIn ? true : false}
              />
              {formik.touched.linkedIn && formik.errors.linkedIn && (
                <AppText size='_12' color='_r100'>
                  {formik.errors.linkedIn}
                </AppText>
              )}
            </div>
          </div>
          <div className='mt-3' />
        </form>
      </BoardingPanel>
      {/* {state !== "preview" && !isProfile && (
        <div>
          <div className='pt-5' />
          <div className='pt-5' />
        </div>
      )} */}
    </div>
  );
}
