import React from "react";
import { usePagination } from "@material-ui/lab/Pagination";
import { makeStyles } from "@material-ui/core/styles";
import ArrowLeftRoundedIcon from '@material-ui/icons/ArrowLeftRounded';
import ArrowRightRoundedIcon from '@material-ui/icons/ArrowRightRounded';
import IconButton from "@material-ui/core/IconButton";

const useStyles = makeStyles({
    ul: {
        listStyle: "none",
        padding: 0,
        margin: 0,
        display: "flex",
        background: '#FFF',
        border: '1px solid #eaedf3'
    }
});

export default function AppPagination({ count, onChange, paginationPage }) {
    const classes = useStyles();
    const { items } = usePagination({
        count,
        page: paginationPage === 0 ? 1 : paginationPage
    });
    const handlePageChange = (page) => {
        onChange(page)
    }
    return (
        <nav>
            <ul className={`${classes.ul} shadow-sm rounded py-1 mb-3`}>
                {items.map(({ page, type, selected, ...item }, index) => {
                    let children = null;
                    if (type === "start-ellipsis" || type === "end-ellipsis") {
                        children = "…";
                    } else if (type === "page") {
                        children = (
                            <IconButton
                                style={{
                                    backgroundColor: paginationPage === page || (paginationPage <= 1 && page <= 1) || (paginationPage >= count && page >= count) ? "#03CA98" : "white",
                                    color: paginationPage === page || (paginationPage <= 1 && page <= 1) || (paginationPage >= count && page >= count) ? "white" : "black",
                                    borderRadius: '4px',
                                    width: 40,
                                    height: 40,
                                    fontSize: '18px'
                                }}
                                {...item}
                            >
                                {page}
                            </IconButton>
                        );
                    } else {
                        if (type === "previous") {
                            children = (
                                <IconButton {...item}
                                    style={{
                                        fontWeight: selected ? "bold" : undefined,
                                        backgroundColor: selected ? "rgba(0, 0, 0, 0.04)" : "white",
                                        borderRadius: '1px',
                                        width: 50,
                                        height: 30,
                                        borderRight: '2px solid #eaedf3'
                                    }}
                                    className={'d-flex justify-content-center'}
                                    disabled={paginationPage === page || (paginationPage <= 1 && page <= 1) ? true : false}
                                >
                                    <ArrowLeftRoundedIcon style={{ fontSize: '25px' }} />
                                </IconButton>
                            )
                        } else {
                            children = (
                                <IconButton {...item}
                                    style={{
                                        fontWeight: selected ? "bold" : undefined,
                                        backgroundColor: selected ? "rgba(0, 0, 0, 0.04)" : "white",
                                        borderRadius: '1px',
                                        width: 50,
                                        height: 30,
                                        borderLeft: '2px solid #eaedf3'
                                    }}
                                    className={'d-flex justify-content-center'}
                                    disabled={paginationPage >= count && page >= count ? true : false}
                                >
                                    <ArrowRightRoundedIcon style={{ fontSize: '25px' }} />
                                </IconButton>
                            )
                        }
                    }
                    return (
                        <li key={index} style={{ margin: "auto 0" }} onClick={((e) => handlePageChange(page))}>
                            {children}
                        </li>
                    );
                })}
            </ul>
        </nav>
    );
}