import React, { useState, useEffect, useRef } from "react";
import {
  Avatar,
  makeStyles,
  IconButton,
  Badge,
  Button,
  Drawer,
  SwipeableDrawer,
  Collapse,
} from "@material-ui/core";
import { NotificationsNone, Menu, Close } from "@material-ui/icons";
import { useMediaQuery } from "react-responsive";
import { useSelector, useDispatch } from "react-redux";
import { useHistory, useLocation, Link } from "react-router-dom";
import { HashLink } from "react-router-hash-link";
import { gql, useQuery, useMutation } from "@apollo/client";
import AppText from "../atoms/AppText";
import DropDownArrow from "../atoms/DropDownArrow";
import {
  randomColor,
  returnInitals,
  UpperCaseFirst,
} from "../../utils/functions/index";
import { fire } from "../../firebase";
import NotificationCard from "../molecules/NotificationCard";
import { setUnread } from "../../stacks/home/ChatsDucks";
import NoNotifications from "../molecules/NoNotifications";

const UPDATE_NOTIFICATION_LAST = gql`
  mutation ($input: _LastSeenInput) {
    managerUpdateLastSeen(input: $input)
  }
`;

const FETCH_ALL_NOTIFICATIONS = gql`
  query ($input: ID!) {
    getMyNotifications(userId: $input) {
      id
      string
      action
      sentAt
      senderFullName
      senderPhotoUrl
      read
    }
  }
`;

const GET_MY_UNREAD_MESSAGES = gql`
  query ($input: ID!) {
    getMyUnreadMessages(userId: $input) {
      id
      conversationId
      messageContent
    }
  }
`;

const MARK_NOTIFICATION = gql`
  mutation ($input: MarkReadInput) {
    markNotificationsRead(input: $input)
  }
`;

export default function AppHeader({ starter = false }) {
  const { user: currentUser } = useSelector((state) => state.app);

  const { loading: loadingData, data: notiData } = useQuery(
    FETCH_ALL_NOTIFICATIONS,
    {
      variables: { input: currentUser.id },
      pollInterval: 5000,
    }
  );

  const { loading: unreadMessageLoading, data: unreadMessageData } = useQuery(
    GET_MY_UNREAD_MESSAGES,
    {
      variables: { input: currentUser.id },
      pollInterval: 2000,
    }
  );

  const [updateNotificationLastSeen] = useMutation(UPDATE_NOTIFICATION_LAST);
  const [markNotificationsRead] = useMutation(MARK_NOTIFICATION);

  const user = fire.auth().currentUser;
  const [unreadNotications, setUnreadNotications] = useState([]);
  const [allNotifications, setAllNotifications] = useState([]);
  const [messageAlerts, setMessageAlerts] = useState([]);
  const strength = null;

  const as = currentUser?.roles[0].as;

  const isDesktop = useMediaQuery({ minWidth: 992 });
  const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 991 });

  const [drop, showDrop] = useState(false);
  const [notifications_, showNotifications_] = useState(false);
  const [currentRoute, setCurrentRoute] = useState("/");
  const [menuOpen, setMenuOpen] = useState(false);

  const statusAlerts = unreadNotications.filter(
    (el) => el.action === 3 && el.action === 5
  );

  const dropRef = useRef(null);
  const buttonRef = useRef(null);

  const routes = [
    { name: "Overview", path: "/", notifications: 0 },
    { name: "Jobs", path: "/jobs", notifications: 0 },
    // { name: "Interviews", path: "/interviews", notifications: 0 },
    {
      name: "Messages",
      path: "/messages",
      notifications: messageAlerts.length,
    },
    {
      name: "Manage Account",
      path: "/manage-account",
      notifications: statusAlerts.length,
    },
  ];

  const startRoutes = [
    { name: "Home", path: "/", notifications: 0 },
    { name: "Manage Account", path: "/manage-account", notifications: 0 },
  ];

  if (as.trim() !== "Admin") {
    routes.splice(routes.length - 1, 1);
    startRoutes.splice(startRoutes.length - 1, 1);
  }

  const { pathname } = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();

  useEffect(() => {
    setCurrentRoute(pathname);
  }, [pathname]);

  useEffect(() => {
    if (!unreadMessageLoading && unreadMessageData) {
      setMessageAlerts(unreadMessageData.getMyUnreadMessages);
      dispatch(setUnread(unreadMessageData.getMyUnreadMessages));
    }
  }, [unreadMessageData, unreadMessageLoading, dispatch]);

  useEffect(() => {
    if (!loadingData && notiData) {
      setUnreadNotications(
        notiData?.getMyNotifications.filter((el) => el.read === false)
      );
      setAllNotifications(notiData?.getMyNotifications);
    }
  }, [loadingData, notiData, user.notificationsLastSeen]);
  useEffect(() => {
    if (notifications_) {
      markNotificationsRead({
        variables: { input: { manager: currentUser.id } },
      });
    }
  }, [currentUser.id, markNotificationsRead, notifications_]);

  const useStyles = makeStyles({
    root: {
      textTransform: "none",
      borderRadius: 0,
      paddingRight: "0!important",
      maxHeight: "50px!important",
      minHeight: "50px!important",
      paddingLeft: "1.6rem",
      "&:focus": {
        outline: "none",
      },
      "&:hover": {
        backgroundColor: "transparent",
      },
      width: "157px",
    },
    smbtn: {
      textTransform: "none",
      paddingRight: "1.5rem",
      paddingLeft: "1.5rem",
      borderRadius: 0,
      "&:focus": {
        outline: "none",
      },
      "&:hover": {
        backgroundColor: "#fff",
      },
    },
    avatar: {
      height: "33px",
      width: "33px",
      marginRight: "10px",
      backgroundColor: randomColor(user.displayName),
    },
    dropAvatar: {
      height: "70px",
      width: "70px",
      backgroundColor: randomColor(user.displayName),
    },
    icon: {
      fontSize: "18px",
      color: "#9EA0A5",
    },
    icon1: {
      fontSize: "18px",
      color: "#BCBCBC",
    },
    icon2: {
      fontSize: "19px",
      color: "#BCBCBC",
    },
    iconsBtn: {
      padding: "0px!important",
      paddingRight: "12px!important",
      "&:focus": {
        outline: "none",
      },
      "&:hover": {
        backgroundColor: "#fff",
      },
    },
    btn: {
      width: "100%",
      height: "45px",
      backgroundColor: "#FBFBFD",
      borderRadius: "0 0 4px 4px",
    },
    badge: {
      position: "absolute",
      right: isDesktop ? "-13px" : "0px",
      top: "50%",
    },
    rightDrawer: {
      "& .MuiDrawer-paper": {
        top: "50px",
        width: isDesktop || isTablet ? "25%" : "80%",
        border: "1px solid #EAEDF3",
        borderTop: "none",
        boxShadow: "0 16px 32px #0000000F",
      },
    },
  });
  const classes = useStyles();

  useEffect(() => {
    window.addEventListener("click", (e) => {
      if (
        dropRef.current &&
        !dropRef.current.contains(e.target) &&
        buttonRef.current &&
        !buttonRef.current.contains(e.target)
      ) {
        showDrop(false);
      }
    });
  }, []);

  const logout = () => {
    fire
      .auth()
      .signOut()
      .then(() => {
        window.location.replace("/");
      });
  };
  const handleNotificationClose = () => {
    if (!notifications_) {
      showNotifications_(true);
    } else {
      showNotifications_(false);
      updateNotificationLastSeen({
        variables: {
          input: { notificationsLastSeen: true, managerId: currentUser.id },
        },
      });
    }
  };

  const routeName = routes.filter((r) => r.path === currentRoute);

  const handleOnClickImg = () => {
    history.push("/");
  };
  return (
    <div
      className={`header position-fixed _fixed ${
        isDesktop || isTablet ? "px-4" : "pl-4"
      } d-flex align-items-center justify-content-between`}
    >
      <div className="h-100 d-flex align-items-center">
        <img
          alt="logo"
          src="/logo.png"
          height="32"
          onClick={handleOnClickImg}
          style={{ cursor: "pointer" }}
        />
        <div className="h-75 line ml-3" />
        {!isDesktop && (
          <span className="ml-3">
            <AppText size="_16" weight="_medium">
              {routeName[0]?.name}
            </AppText>
          </span>
        )}

        {isDesktop ? (
          <div className="_routers h-100 ml-5 pl-5 h-100 d-flex align-items-center">
            {!starter
              ? routes.map((route) => (
                  <span key={route.name}>
                    <Link key={route.name} to={route.path}>
                      <span className="mr-5">
                        <Badge
                          classes={{ badge: classes.badge }}
                          badgeContent={route.notifications}
                          color="secondary"
                          max={9}
                        >
                          <AppText
                            size="_14"
                            weight={
                              currentRoute === route.path ? "_medium" : ""
                            }
                            color={currentRoute === route.path ? "_g90" : ""}
                          >
                            {route.name}
                          </AppText>
                        </Badge>
                      </span>
                    </Link>
                  </span>
                ))
              : startRoutes.map((route) => (
                  <span key={route.name}>
                    <Link to={route.path}>
                      <span className="mr-5">
                        <Badge
                          classes={{ badge: classes.badge }}
                          badgeContent={route.notifications}
                          color="secondary"
                          max={9}
                        >
                          <AppText
                            size="_14"
                            weight={
                              currentRoute === route.path ? "_medium" : ""
                            }
                            color={currentRoute === route.path ? "_g90" : ""}
                          >
                            {route.name}
                          </AppText>
                        </Badge>
                      </span>
                    </Link>
                  </span>
                ))}
          </div>
        ) : null}
      </div>
      <div className="h-100">
        <div className="h-100 d-flex align-items-center">
          <div className="mr-2 h-100 d-flex align-items-center">
            <div className={isDesktop || isTablet ? "position-relative" : ""}>
              <IconButton
                onClick={handleNotificationClose}
                className={classes.iconsBtn}
                disableFocusRipple
                disableTouchRipple
              >
                <Badge
                  badgeContent={unreadNotications.length}
                  color="secondary"
                >
                  <NotificationsNone className={classes.icon2} />
                </Badge>
              </IconButton>

              <Drawer
                anchor="right"
                variant="persistent"
                className={classes.rightDrawer}
                open={notifications_}
                onClose={() => showNotifications_(false)}
                children={
                  <div>
                    <div className="p-3 d-flex justify-content-between">
                      <div>
                        <AppText>Notifications</AppText>
                        {unreadNotications.length !== 0 && (
                          <span className="ml-2 _danger_b">
                            <AppText size="_14" color="_c0" weight="_bold">
                              {unreadNotications.length} NEW
                            </AppText>
                          </span>
                        )}
                      </div>
                      <div>
                        <IconButton
                          onClick={handleNotificationClose}
                          className={classes.iconsBtn}
                          disableFocusRipple
                          disableTouchRipple
                        >
                          <Close className={classes.icon1} />
                        </IconButton>
                      </div>
                    </div>
                    <div className="_sep_" />
                    <div className="_notification_area">
                      {[...allNotifications]
                        .sort((a, b) =>
                          a.sentAt > b.sentAt ? -1 : a.sentAt < b.sentAt ? 1 : 0
                        )
                        .map((notification, i) => {
                          if (i < 15) {
                            return (
                              <div
                                key={i}
                                className={`${i === 14 ? "mb-5" : ""}`}
                              >
                                <NotificationCard
                                  notification={notification}
                                  lastSeen={user.notificationsLastSeen}
                                  onClick={handleNotificationClose}
                                />
                              </div>
                            );
                          } else {
                            return null;
                          }
                        })}
                      {allNotifications.length === 0 ? (
                        <NoNotifications />
                      ) : null}
                    </div>
                  </div>
                }
              />
            </div>
          </div>
          <div className="h-100 line" />
          {isDesktop ? (
            <Button
              className={classes.root}
              disableFocusRipple
              disableTouchRipple
              onClick={() => showDrop(!drop)}
              ref={buttonRef}
            >
              <Avatar
                sizes="small"
                className={classes.avatar}
                src={currentUser?.avatarUrl}
              >
                <AppText size="_14" color="_c0">
                  {returnInitals(`${UpperCaseFirst(user.displayName)} `)}
                </AppText>
              </Avatar>
              <div className="mr-4">
                <AppText size="_14">
                  {UpperCaseFirst(user?.displayName?.split(" ")[0])}
                </AppText>
              </div>
              <DropDownArrow />
            </Button>
          ) : null}
          {!isDesktop && (
            <React.Fragment>
              <IconButton
                className={classes.smbtn}
                disableFocusRipple
                disableTouchRipple
                onClick={() => setMenuOpen(true)}
                ref={buttonRef}
              >
                <Menu className={classes.icon2} />
              </IconButton>
              <SwipeableDrawer
                anchor="left"
                open={menuOpen}
                onClose={() => setMenuOpen(false)}
                onOpen={() => setMenuOpen(true)}
              >
                <div style={{ width: "326px" }}>
                  <div className="p-4 d-flex align-items-center">
                    <div>
                      <Avatar
                        style={{
                          height: "56px",
                          width: "56px",
                          backgroundColor: randomColor(user.displayName),
                        }}
                        src={user.photoUrl || null}
                      >
                        {returnInitals(`${UpperCaseFirst(user.displayName)} `)}
                      </Avatar>
                    </div>
                    <div className="ml-3 ">
                      <AppText size="_16" weight="_medium" align="_center">
                        {`${UpperCaseFirst(user.displayName)}`}
                      </AppText>
                    </div>
                  </div>
                  <div className="_sep_" />
                  <div className="d-flex flex-column pt-4">
                    <div className="pl-4">
                      <AppText size="_14" weight={"_medium"} color={"_c90"}>
                        MENU
                      </AppText>
                    </div>

                    <div className="mb-2" />
                    {routes.map((route) => (
                      <Link
                        key={route.name}
                        to={route.path}
                        onClick={() => setMenuOpen(false)}
                        className={`py-2 px-4 ${
                          currentRoute === route.path ? "_sele_" : ""
                        }`}
                      >
                        <span className="d-flex justify-content-between">
                          <AppText
                            size="_14"
                            weight={
                              currentRoute === route.path ? "_medium" : ""
                            }
                            color={currentRoute === route.path ? "_g90" : ""}
                          >
                            {route.name}
                          </AppText>
                          <Badge
                            classes={{ badge: classes.badge }}
                            badgeContent={route.notifications}
                            color="secondary"
                            max={9}
                          />
                        </span>
                      </Link>
                    ))}
                  </div>
                  <div className="_sep_ my-3" />
                  <div className="py-3">
                    <div className="px-4">
                      <AppText size="_12" color="_c50">
                        YOUR DETAILS
                      </AppText>
                    </div>
                    <Link to="/profile" onClick={() => setMenuOpen(false)}>
                      <div className="_lists px-4">
                        <div className="mt-2">
                          <AppText size="_14">My CV</AppText>
                        </div>
                        <AppText size="_12" color="_o100">
                          Profile Strength {strength}%
                        </AppText>
                      </div>
                    </Link>
                    <div className="mt-2" />
                    <div className="px-4 mt-2">
                      <HashLink
                        to="/profile#password"
                        onClick={() => setMenuOpen(false)}
                      >
                        <AppText size="_14">Password</AppText>
                      </HashLink>
                    </div>
                    <div className="mt-3" />
                    <div className="_sep_" />
                    <div className="px-4 pt-3">
                      <AppText size="_12" color="_c50">
                        HELP & SUPPORT
                      </AppText>
                    </div>
                    <div
                      className="_lists px-4 pointer"
                      onClick={() =>
                        window.open("https://www.taprecruit.co.uk", "_blank")
                      }
                    >
                      <div className="mt-2">
                        <AppText size="_14">Contact Support</AppText>
                      </div>
                    </div>
                  </div>

                  <div className="_sep_ my-3" />
                  <Button className={classes.btn} onClick={logout}>
                    <AppText size="_12" color="_r100">
                      LOG OUT
                    </AppText>
                  </Button>
                </div>
              </SwipeableDrawer>
            </React.Fragment>
          )}
        </div>
        {drop && (
          <Collapse in={drop}>
            <div className="position-absolute _menu_drop" ref={dropRef}>
              <div className="py-3 d-flex flex-column justify-content-center align-items-center">
                <div>
                  <Avatar
                    className={classes.dropAvatar}
                    src={currentUser?.avatarUrl}
                  >
                    {returnInitals(`${UpperCaseFirst(user.displayName)}`)}
                  </Avatar>
                </div>
                <div className="mt-3 d-flex flex-column justify-content-center ">
                  <AppText size="_16" weight="_medium" align="_center">
                    {`${UpperCaseFirst(user.displayName)}`}
                  </AppText>
                  <div></div>
                </div>
              </div>
              <div className="_sep_" />
              <div className="py-3">
                <div className="px-4">
                  <AppText size="_12" color="_c50">
                    YOUR ACCOUNT
                  </AppText>
                </div>
                <div className="mt-2" />
                <div className="px-4 mt-2">
                  <HashLink to="/profile" onClick={() => showDrop(false)}>
                    <AppText size="_14">Basic Information</AppText>
                  </HashLink>
                </div>
                <div className="px-4 mt-2">
                  <HashLink to="/profile" onClick={() => showDrop(false)}>
                    <AppText size="_14">Password</AppText>
                  </HashLink>
                </div>

                <div className="mt-3" />
                <div className="_sep_" />
                <div className="px-4 pt-3">
                  <AppText size="_12" color="_c50">
                    HELP & SUPPORT
                  </AppText>
                </div>
                <div
                  className="_lists px-4 pointer"
                  onClick={() =>
                    window.open("https://www.taprecruit.co.uk", "_blank")
                  }
                >
                  <div className="mt-2">
                    <AppText size="_14">Contact Support</AppText>
                  </div>
                </div>
              </div>
              <div className="_sep_" />
              <Button className={classes.btn} onClick={logout}>
                <AppText size="_12" color="_r100">
                  LOG OUT
                </AppText>
              </Button>
            </div>
          </Collapse>
        )}
      </div>
    </div>
  );
}
