import React, { useRef, useEffect } from 'react'
import AppText from '../atoms/AppText'

export default function Summary({ title, summary, isSelect }) {
    const elRef = useRef(null);
    useEffect(() => {
        const handleScroll = () => {
            if (elRef?.current?.getBoundingClientRect()?.top - 100 <= 0 && 100 + elRef?.current?.getBoundingClientRect()?.top >= 1) {
                isSelect('Professional-Summary')
            }
        }
        document.addEventListener('scroll', handleScroll);
        return () => {
            document.removeEventListener('scroll', handleScroll);
        }
    }, [isSelect])

    return (
        <div className={`mt-3 mb-4 w-100 card_style`} key={'summary'} id={'Professional-Summary'} ref={elRef}>
            {/* Header */}
            <header className={'p-4'}>
                <AppText size='_18' weight='_medium'>
                    {title}
                </AppText>
            </header>
            <div className='_sep_' />
            <div className={'p-3'}>
                <AppText size='_14' weight='_medium'>
                    {summary}
                </AppText>
            </div>
        </div>
    )
}