import { ArrowDropDownOutlined, ArrowDropUpOutlined } from "@material-ui/icons";
import React from "react";

export default function DropDownArrow({ color }) {
  return (
    <div className='d-flex flex-column'>
      <ArrowDropUpOutlined style={{ fontSize: "14px", color: `${color ? color : "#9EA0A5"}` }} />
      <ArrowDropDownOutlined style={{ fontSize: "14px", marginTop: "-6px", color: `${color ? color : "#9EA0A5"}` }} />
    </div>
  );
}
