import React from "react";
import { Alert } from '@material-ui/lab'
import AppText from "../atoms/AppText";
import { makeStyles } from "@material-ui/core/styles";
import CloseOutlinedIcon from "@material-ui/icons/CloseOutlined";

const useStyles = makeStyles({
  root: {
    background: "#07BF91",
    "& .MuiAlert-icon": {
      color: "white"
    }
  },
  closeButton: {
    color: "white",
    marginRight: '10px',
    fontSize: '20px',
    cursor: "pointer"
  }
});

export default function BasicAlerts({ text, onClose, mt = true }) {
  const classes = useStyles();
  return (
    <div className={`w-80 ${mt ? 'mt-3' : ''}`}>
      <Alert
        severity="success"
        className={classes.root}
        action={(
          <CloseOutlinedIcon className={classes.closeButton} onClick={onClose} />
        )}
      >
        <AppText size={'_14'} weight={'_medium'} color={'c10'}>
          {text}
        </AppText>
      </Alert>
    </div>
  );
}