import { Avatar } from "@material-ui/core";
import { Work } from "@material-ui/icons";
import React from "react";
import { Link } from "react-router-dom";
import { makeDate } from "../../utils/functions/index";
import AppText from "../atoms/AppText";

export default function NotificationCard({ notification, onClick }) {
  const notificationTypes = [
    { type: "", goto: "" },
    { type: "Sent you a message", goto: "/messages" },
    { type: "New Job Alert!", goto: "" },
    { type: "Job Application Status", goto: "" },
    { type: "", goto: "" },
    { type: "Job Application", goto: "/jobs" },
  ];

  return (
    <Link to={notificationTypes[notification.action].goto} onClick={onClick}>
      <div className={`d-flex align-items-start p-3  ${!notification.read && "_unseen"}`}>
        <div className='mr-3'>
          <Avatar
            src={notification.action === 1 ? notification?.senderPhotoUrl : ""}
            style={{
              height: "25px",
              width: "25px",
              backgroundColor: notification.action === 2 ? "#03CA98" : "#3E3F42",
            }}
          >
            {notification.action === 1 ? (
              <AppText color='_c0' size='_12'>
                {notification?.senderFullName?.split(" ")[0].charAt(0)}
                {notification?.senderFullName?.split(" ")[1].charAt(0)}
              </AppText>
            ) : (
                <Work style={{ fontSize: "16px", color: "#fff" }} />
              )}
          </Avatar>
        </div>
        <div className='w-100'>
          <div className='d-flex justify-content-between'>
            <div>
              {notification.action === 1 && (
                <AppText size='_14' weight='_medium'>
                  {notification?.senderFullName}
                </AppText>
              )}

              <div>
                <AppText size='_14' weight={notification.action !== 1 ? "_medium" : "_regular"}>
                  {notificationTypes[notification.action].type}
                </AppText>
              </div>
            </div>

            <span>
              <AppText size='_12' color='_c90'>
                {makeDate(notification.sentAt)}
              </AppText>
            </span>
          </div>

          <div className='mt-1'>
            <AppText size='_14'>{notification.string}</AppText>
          </div>
        </div>
      </div>
    </Link>
  );
}
