import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
// import { useMediaQuery } from "react-responsive";
import { useMutation, gql } from "@apollo/client";
import BoardingPanel from "../../../components/orgnisms/BoardingPanel";
import AppText from "../../../components/atoms/AppText";
import UploadButton from "../../../components/atoms/UploadButton";
import Model from "../../../components/molecules/Model";
// import ImageUploadModal from "../../../components/orgnisms/ImageUploadModal";
import { isValidImage } from "../../../utils/functions/index";
import { useAccountSetup } from "../../../Context/AccountSetupContext";
import RemoveUpload from "../../../components/atoms/RemoveButton";
import ImageUploadLogo from "../../../components/orgnisms/ImageUploadLogo";
import { fire } from "../../../firebase";
import { changeAppState, setUser } from "../../ducks";
import AddAPhotoOutlinedIcon from "@material-ui/icons/AddAPhotoOutlined";
import HeaderUploadModal from "../../../components/orgnisms/HeaderUploadModal";

const REMOVE_HEADER = gql`
  mutation ($input: _CompanyInputUpdate) {
    updateCompany(input: $input) {
      header
    }
  }
`;
const REMOVE_LOGO = gql`
  mutation ($input: _CompanyInputUpdate) {
    updateCompany(input: $input) {
      logo
    }
  }
`;

const COMPLETE_ACCOUNT = gql`
  mutation ($input: UpdateManagerInput) {
    updateManager(input: $input) {
      firstName
      lastName
      country
      city
      emailVerified
      avatarUrl
      accountComplete
      fullName
      email
      roles {
        company {
          id
          name
          size
          services
          websiteLink
          instagramLink
          facebookLink
          linkedinLink
          twitterLink
          culture
          logo
          header
          founded
          bio
          overview
          location
        }
        as
      }
    }
  }
`;

export default function Image({ onBack, step }) {
  const [isLoading] = useState(false);
  const [isExactLogo, setIsExactLogo] = useState(false);
  const [isExactHeader, setIsExactHeader] = useState(false);
  const [temp, setTemp] = useState(null);
  const [previewImage, setPreviewImage] = useState(false);
  const [previewLogo, setPreviewLogo] = useState(false);
  const [blob, setBlob] = useState(null);

  const [updateHeader] = useMutation(REMOVE_HEADER);
  const [updateLogo] = useMutation(REMOVE_LOGO);
  const [completeAccount] = useMutation(COMPLETE_ACCOUNT);
  const [headerDimesions] = useState({
    width: null,
    height: null,
  });

  const { user } = useSelector((state) => state.app);
  const dispatch = useDispatch();

  const history = useHistory();
  const { accountSetup, setAccountSetup } = useAccountSetup();

  useEffect(() => {
    if (user?.roles[0]?.company?.logo) {
      setIsExactLogo(true);
    }
    if (user?.roles[0]?.company?.header) {
      setIsExactHeader(true);
    }
    // eslint-disable-next-line
  }, []);

  const handleBack = () => {
    onBack(2);
    history.push("/social");
  };

  if (step !== 3) {
    history.push("/");
  }

  const handleChange = (file) => {
    const { temp } = isValidImage(file[0]);

    let img = document.createElement("img");
    img.src = temp;
    img.onload = () => {
      if (img.width < 720) {
        setIsExactHeader(false);
      } else {
        setTemp(temp);
        setPreviewImage(true);
        setIsExactHeader(true);
      }
    };
  };

  const handleLogoChange = (file) => {
    const { temp } = isValidImage(file[0]);
    setBlob(file[0]);

    let img = document.createElement("img");
    img.src = temp;

    img.onload = () => {
      if (img.height === img.width) {
        setIsExactLogo(true);
        setTemp(temp);
        setPreviewLogo(true);
      } else {
        setIsExactLogo(false);
      }
    };
  };

  const handleOnSubmit = () => {
    if (!user?.roles[0]?.company?.header) {
      setIsExactHeader(false);
    }

    if (!user?.roles[0]?.company?.logo) {
      setIsExactLogo(false);
    }

    const { uid } = fire.auth().currentUser;
    if (
      (user?.roles[0]?.company?.header || accountSetup.imageheader) &&
      (user?.roles[0]?.company?.logo || accountSetup.companyLogo)
    ) {
      completeAccount({
        variables: { input: { uid: uid, accountComplete: true } },
      }).then(({ data }) => {
        dispatch(setUser({ ...data.updateManager }));
        dispatch(changeAppState({ accountCompleted: true }));
      });
    }
  };

  const removeHeader = () => {
    updateHeader({
      variables: {
        input: {
          companyId:
            (user && user?.roles[0]?.company?.id) || accountSetup?.basic.id,
          values: { header: "" },
        },
      },
    }).then(() => {
      setAccountSetup({ ...accountSetup, imageheader: "" });
    });
  };
  const removeLogo = () => {
    updateLogo({
      variables: {
        input: {
          companyId:
            (user && user?.roles[0]?.company?.id) || accountSetup?.basic.id,
          values: { logo: "" },
        },
      },
    }).then(() => {
      setAccountSetup({ ...accountSetup, companyLogo: "" });
    });
  };

  return (
    <div>
      <BoardingPanel
        heading="Add your Company header image and logo"
        onSubmit={handleOnSubmit}
        isLoading={isLoading}
        onSubmitText={"Next"}
        onBack={handleBack}
      >
        {accountSetup?.imageheader ||
        (user && user.roles[0].company?.header) ? (
          <div className="header_image d-flex flex-column position-relative">
            <img
              className="w-100 h-100"
              alt={accountSetup.imageheader || user.roles[0].company?.header}
              src={accountSetup.imageheader || user.roles[0].company?.header}
            />
            <div
              className="position-absolute w-100 h-100 d-flex justify-content-center align-items-center"
              style={{ background: "rgba(0, 0, 0, 0.5)", borderRadius: "4px" }}
            >
              <UploadButton
                label="Upload photo"
                onChange={(file) => handleChange(file)}
              >
                <span
                  className={`d-flex p-1 px-2 align-items-center pointer shadow-sm`}
                  style={{
                    color: "#FFFF",
                    border: `1px solid #FFFF`,
                    borderRadius: "4px",
                  }}
                >
                  <AddAPhotoOutlinedIcon
                    label="Add image"
                    style={{
                      fontSize: "16px",
                      color: "#FFFF",
                      marginRight: "10px",
                    }}
                  />
                  <AppText size="_14" color={"_c0"}>
                    Add Photo
                  </AppText>
                </span>
              </UploadButton>
              <RemoveUpload
                label="Remove image"
                color={"_white"}
                onClick={removeHeader}
              />
            </div>
          </div>
        ) : (
          <div className="d-flex justify-content-center align-items-center _header_img">
            <UploadButton
              label="Upload photo"
              onChange={(file) => handleChange(file)}
            >
              <span
                className={`d-flex p-1 px-2 align-items-center pointer shadow-sm`}
                style={{ border: `1px solid #eaedf3`, borderRadius: "4px" }}
              >
                <AddAPhotoOutlinedIcon
                  label="Add image"
                  style={{ fontSize: "16px" }}
                />
              </span>
            </UploadButton>
          </div>
        )}

        {!isExactHeader && (
          <AppText size="_14" color={"_r100"}>
            The minimum company header image size is 720px by 240px
          </AppText>
        )}
        <div className="d-flex justify-content-between w-45 mt-4 mb-3">
          {accountSetup?.companyLogo ||
          (user && user?.roles[0]?.company?.logo) ? (
            <div className="company_logo d-flex flex-column position-relative">
              <img
                alt="sdd"
                className="w-100 h-100"
                src={accountSetup?.companyLogo || user?.roles[0]?.company?.logo}
              />
              <div
                className="position-absolute w-100 h-100 d-flex justify-content-center align-items-center"
                style={{
                  background: "rgba(0, 0, 0, 0.5)",
                  borderRadius: "4px",
                }}
              >
                <RemoveUpload label="" color={"_white"} onClick={removeLogo} />
              </div>
            </div>
          ) : (
            <div className="d-flex justify-content-center align-items-center _logo_img">
              <UploadButton
                label=""
                onChange={(file) => handleLogoChange(file)}
              >
                <span
                  className={`d-flex p-1 px-2 align-items-center pointer shadow-sm`}
                  style={{ border: `1px solid #eaedf3`, borderRadius: "4px" }}
                >
                  <AddAPhotoOutlinedIcon
                    label="Add image"
                    style={{ fontSize: "16px" }}
                  />
                </span>
              </UploadButton>
            </div>
          )}

          <div className="w-60 pl-2">
            <div
              className={"border-0 rounded mt-2"}
              style={{ background: "#fafafa", height: "35px", width: "75%" }}
            ></div>
            <div
              className={"border-0 rounded mt-2"}
              style={{ background: "#fafafa", height: "20px" }}
            ></div>
          </div>
        </div>

        {!isExactLogo && (
          <AppText size="_14" color={"_r100"}>
            The minimum company logo size is 100px by 100px
          </AppText>
        )}
        {/* {error ? (
          <AppText size='_14' color={'_r100'}>{error}</AppText>
        ) : (
            <AppText size='_14' color='_c50'>
              Logo must be of equal length and width. Header image must be 720px by 240px in size.
            </AppText>
          )} */}
      </BoardingPanel>
      {previewImage && (
        <Model
          width="70%"
          dimesions={headerDimesions}
          loose={true}
          onClose={() => {
            setPreviewImage(false);
            setTemp(null);
          }}
        >
          <HeaderUploadModal
            dimesions={headerDimesions}
            temp={temp}
            onClose={() => {
              setPreviewImage(false);
              setTemp(null);
            }}
          />
        </Model>
      )}
      {previewLogo && (
        <Model
          onClose={() => {
            setPreviewLogo(false);
            setTemp(null);
          }}
        >
          <ImageUploadLogo
            temp={temp}
            blob={blob}
            onClose={() => {
              setPreviewLogo(false);
              setTemp(null);
            }}
          />
        </Model>
      )}
    </div>
  );
}
