import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import ArrowBackRoundedIcon from "@material-ui/icons/ArrowBackRounded";

import AppText from "../../../components/atoms/AppText";
// import AppButton from "../../../components/molecules/AppButton";
import { UpperCaseFirst } from "../../../utils/functions/index";
import Select from "../../../components/molecules/Select";
import Experiences from "../../../components/molecules/Experiences";
import Summary from "../../../components/molecules/Summary";
import Skills from "../../../components/molecules/Skills";
import Educations from "../../../components/molecules/Educations";
import VideoCV from "../../../components/orgnisms/VideoCV";

export default function CandidateProfile() {

  const history = useHistory();
  const { state } = useLocation();

  if (state === undefined) {
    history.push("/");
  }
  const job = state?.job;
  const candidate = state?.candidate;
  const [currentFilter, setCurrentFilter] = useState(candidate.videoUrl || candidate.embedCode ? 'Video' : 'Professional-Summary');

  const navOption = [
    candidate.videoUrl || candidate.embedCode ? { name: "Video", key: "Video" } : null,
    { name: "Professional Summary", key: "Professional-Summary" },
    candidate.experience.length ? { name: "Work Experience", key: "Work-Experience" } : null,
    candidate.education.length ? { name: "Education", key: "Education" } : null,
    candidate.skills.length ? { name: "Skills", key: "Skills" } : null,
  ];

  const goBack = () => {
    history.push(`/job/${job.id}`, job);
  };

  // const handleDownload = () => {
  //   console.log("hola mundo");
  // };

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <div className={"d-flex flex-column overview_background mt-5"}>
      {/* SubHeader */}
      <div className='w-100 bg-white _create_job shadow-sm d-flex justify-content-center'>
        <div className='w-75 pt-3 pb-3 mt-3 mb-3'>
          <div className={"d-flex justify-content-between align-items-center"}>
            <div className='d-flex'>
              <ArrowBackRoundedIcon
                style={{ color: "#6B6C6F", fontSize: 25, marginRight: "20px", cursor: "pointer" }}
                onClick={goBack}
              />
              <div className={"d-flex flex-column"}>
                <AppText size='_22'>{`${UpperCaseFirst(candidate?.firstName)} ${UpperCaseFirst(
                  candidate?.lastName
                )}`}</AppText>
                {candidate?.experience.length ? (
                  <AppText size='_16' color={"_c50"}>
                    {`${candidate?.experience
                      .sort((a, b) => a.startDate > b.startDate ? -1 : a.startDate < b.startDate ? 1 : 0)
                    [0]?.myRole} at ${candidate?.experience[0]?.company.name}`}
                  </AppText>
                ) : null}
              </div>
            </div>
            {/* <div className={"d-flex"}>
              <AppButton type='submit' onClick={handleDownload} color='_white'>
                <AppText size='_14' weight='_medium'>
                  Download Resume
                </AppText>
              </AppButton>
            </div> */}
          </div>
        </div>
      </div>

      <div className={"w-100 d-flex justify-content-center pb-3"}>
        <div className={"w-75 mb-4 d-flex justify-content-between"}>
          {/* Candidate Phote Box */}
          <div className={"mr-4 w-35"}>
            <div className={"mt-3 card_style"} style={{ position: 'sticky', top: '70px' }}>
              <div>
                <img
                  src={candidate?.photoUrl ? candidate?.photoUrl : '/default-user-image.png'}
                  alt={"candidate?Phoro"}
                  style={{ borderRadius: "4px 4px 0px 0px" }}
                  width={"100%"}
                  height={"100%"}
                ></img>
                <div className={"p-3 d-flex flex-column"}>
                  <span className={"mb-1"}>
                    <AppText size='_22' weight='_medium'>
                      {`${UpperCaseFirst(candidate?.firstName)} ${UpperCaseFirst(
                        candidate?.lastName
                      )}`}
                    </AppText>
                  </span>
                  {candidate?.experience.length ? (
                    <AppText size='_14' weight='_medium' color={"_c90"}>
                      {`${candidate?.experience
                        .sort((a, b) => a.startDate > b.startDate ? -1 : a.startDate < b.startDate ? 1 : 0)
                      [0]?.myRole} at ${candidate?.experience[0]?.company.name}`}
                    </AppText>
                  ) : null}
                  <AppText size='_14' weight='_medium' color={"_c50"}>
                    {`${candidate?.city}, ${UpperCaseFirst(candidate?.country)}`}
                  </AppText>
                </div>
              </div>
              <div className={"d-flex flex-column p-3"} style={{ borderTop: "1px solid #e2e5ed" }}>
                {navOption.map((opt) => {
                  if (opt !== null) {
                    return (
                      <Select
                        key={opt.name}
                        text={opt.name}
                        number={opt.count}
                        optKey={opt.key}
                        isSelect={currentFilter}
                      />
                    )
                  }
                  else {
                    return null
                  }
                })}
              </div>
            </div>
          </div>

          {/* Candidate info Box */}
          <div className={"w-100"}>
            {candidate.videoUrl || candidate.embedCode ? (
              <VideoCV key={"video"} title={"Video"} url={
                candidate.videoUrl !== null ?
                  candidate.videoUrl : candidate.embedCode}
                isSelect={setCurrentFilter}
              />
            ) : null}
            {/* Summary */}
            <div style={{ marginBottom: `${candidate.experience.length ? '' : '350px'}` }}>
              <Summary summary={candidate.summary} title={"Professional Summary"} isSelect={setCurrentFilter} />
            </div>

            {/* Experiences */}
            {candidate.experience.length ? (
              <div style={{ marginBottom: `${candidate.education.length ? '' : '350px'}` }}>
                <Experiences experiences={candidate.experience} title={"Work Experience"} isSelect={setCurrentFilter} />
              </div>
            ) : null}

            {/* Educations */}
            {candidate.education.length ? (
              <div style={{ marginBottom: `${candidate.skills.length ? '' : '350px'}` }}>
                <Educations educations={candidate.education} title={"Education"} isSelect={setCurrentFilter} />
              </div>
            ) : null}

            {/* Skills */}
            {candidate.skills.length ? (
              <div style={{ marginBottom: '350px' }}>
                <Skills skills={candidate.skills} title={"Skills"} isSelect={setCurrentFilter} />
              </div>
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
}
