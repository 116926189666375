import React from "react";
import { useHistory } from "react-router-dom";

import SearchInput from './SearchInput'
import AppButton from './AppButton'
import AppText from '../atoms/AppText'

export default function Search({onButtonClick, value, onValueChange}) {
    const history = useHistory();

    const handleClick = () => {
        history.push("/create-job");
    }

    return (
        <div className={'d-flex justify-content-between'}>
            <div className={"w-45"}>
                <SearchInput
                    placeholder='Search for a job'
                    onChange={onValueChange}
                    value={value}
                    name='searchInput'
                    onClick={onButtonClick}
                />
            </div>
            <AppButton type='submit' onClick={handleClick}>
                <AppText color='_c0' size='_14'>
                    Create A Job Post
                </AppText>
            </AppButton>
        </div>
    )
}
