import React from "react";
import { Check } from "@material-ui/icons";
import { useMediaQuery } from "react-responsive";

export default function AppStepper({ steps, active }) {
  const isDesktop = useMediaQuery({ minWidth: 992 });

  return (
    <div className={!isDesktop && "d-flex"}>
      {steps.map((s, i) => (
        <div className='stepper_container' key={i}>
          <div
            className={`step_count ${
              active > i + 1 ? "_complete" : active < i + 1 ? "_left_a" : "_active"
            }`}
          >
            {active > i + 1 ? <Check /> : i + 1}
          </div>
          <div className={`${active > i + 1 ? "" : active < i + 1 ? "_left_l" : ""}`}>{s.name}</div>
        </div>
      ))}
    </div>
  );
}
