import React, { useState, useEffect } from "react";
import { useFormik } from "formik";
import { parse } from "query-string";
import { useMediaQuery } from "react-responsive";
import AppText from "../../../components/atoms/AppText";
import AppButton from "../../../components/molecules/AppButton";
import AppPassword from "../../../components/molecules/AppPassword";

import { fire } from "../../../firebase";
import { ResetPasswordShema } from "../../../utils/schema/login";
import { useHistory } from "react-router-dom";

export default function ResetPassword() {
  const isDesktop = useMediaQuery({ minWidth: 992 });
  const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 991 });

  const [reset, setReset] = useState(false);
  const history = useHistory()

  useEffect(() => {
    const qParams = parse(window.location.search);
    if (qParams.oobCode && qParams.mode === "resetPassword") {
      setReset(true);
    }
  }, []);

  const formik = useFormik({
    initialValues: {
      newPassword: "",
      confirmPassword: "",
    },
    validationSchema: ResetPasswordShema,
    onSubmit: (value) => {
      const qParams = parse(window.location.search);
      if (reset) {
        fire
          .auth()
          .confirmPasswordReset(qParams.oobCode, value.newPassword)
          .then(() => {
            history.push("/success")
          });
      }
    },
  });

  return (
    <div>
      <div className='background d-flex flex-column align-items-center justify-content-center'>
        <div className={isDesktop ? "w-25" : isTablet ? "w-60" : "w-90"}>
          <div className='bg-white p-4 boxy shadow-sm rounded'>
            <div>
              <AppText size='_22'>Reset Your Password</AppText>
            </div>
            {!reset && <AppText> Reset code is broken </AppText>}
            {reset && (
              <form onSubmit={formik.handleSubmit}>
                <div className='my-2'>
                  <AppPassword
                    label='ENTER NEW PASSWORD'
                    placeholder='New Password'
                    name='newPassword'
                    value={formik.values.newPassword}
                    onChange={formik.handleChange}
                    hasError={
                      formik.touched.newPassword && formik.errors.newPassword ? true : false
                    }
                  />
                  <div>
                    {formik.touched.newPassword && formik.errors.newPassword ? (
                      <AppText size='_12' color='_r100'>
                        {formik.errors.newPassword}
                      </AppText>
                    ) : null}
                  </div>
                </div>
                <div className='mb-4'>
                  <AppPassword
                    label='CONFIRM PASSWORD'
                    placeholder='confirm password'
                    name='confirmPassword'
                    value={formik.values.confirmPassword}
                    onChange={formik.handleChange}
                    hasError={
                      formik.touched.confirmPassword && formik.errors.confirmPassword ? true : false
                    }
                  />
                  <div>
                    {formik.touched.confirmPassword && formik.errors.confirmPassword ? (
                      <AppText size='_12' color='_r100'>
                        {formik.errors.confirmPassword}
                      </AppText>
                    ) : null}
                  </div>
                </div>

                <AppButton
                  width='_full'
                  type='submit'
                  state={
                    (formik.errors.confirmPassword) || (formik.errors.confirmPassword === undefined && !formik.values.confirmPassword) ||
                      (formik.errors.newPassword) || (formik.errors.newPassword === undefined && !formik.values.newPassword)
                      ? true : false}
                >
                  <AppText size='_14' color='_c0'>
                    Reset Password
                  </AppText>
                </AppButton>
              </form>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
