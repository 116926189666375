import React from "react";
import Quill from "react-quill";
import "react-quill/dist/quill.snow.css";

function AppTextEditor({ onHandleTextChange, description }) {
  return (
    <Quill theme="snow" value={description} onChange={onHandleTextChange} />
  );

}

export default AppTextEditor;
