import * as Yup from "yup";

export const SignupShema = Yup.object().shape({
  firstName: Yup.string()
    .min(3, "Minimum of 3 characters")
    .trim()
    .required("Please enter your First Name")
    .matches("^[^0-9]+$", "Must not contain numbers")
    .matches("^[^<>/$@%#&+'?,/.|()*]+$", "Must not contain special characters"),
  lastName: Yup.string()
    .min(3, "Minimum of 3 characters")
    .trim()
    .required("Please enter your Last Name")
    .matches("^[^0-9]+$", "Must not contain numbers")
    .matches("^[^<>/$@%#&+'?,/.|()*]+$", "Must not contain special characters"),
  email: Yup.string().email("Email should be valid").required("Email is required"),
  password: Yup.string()
    .min(6, "Password should be at least 6 characters")
    .matches(/\d+/, "Your password must contain at least one number")
    .matches("(?=.*[A-Z])", "Your password must contain at least one uppercase")
    .matches(
      "[!#$%&'()*,-./:;<=>?@_`{|}]+",
      "Your password must contain at least one special character"
    )
    .required("Please enter a valid password"),
  isNewEmail: Yup.bool().oneOf([false], "Email is already used"),
});

export const LoginShema = Yup.object().shape({
  email: Yup.string().required("Please enter a valid email address"),
  password: Yup.string().required("Please enter a valid password")
})

export const ResetPasswordShema = Yup.object().shape({
  newPassword: Yup.string()
    .min(6, "Password should be at least 6 characters")
    .matches(/\d+/, "Your password must contain at least one Number")
    .matches("(?=.*[A-Z])", "Your password must contain at least one uppercase")
    .matches("[!#$%&'()*,-./:;<=>?@_`{|}]+", "Your password must contain at least one special character")
    .required("Please enter your Password"),
  confirmPassword: Yup.string()
    .required("Please confirm your Password")
    .oneOf([Yup.ref("newPassword"), null], "Password and Confirm password do not match"),
})

export const ForgotPasswordShema = Yup.object().shape({
  email: Yup.string().email().required(),
})
