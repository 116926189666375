import React, { useState } from "react";
import AppText from "../atoms/AppText";
import Model from "../molecules/Model";
import AppButton from "../molecules/AppButton";
import DeleteOutlineRoundedIcon from '@material-ui/icons/DeleteOutlineRounded';
import CheckRoundedIcon from '@material-ui/icons/CheckRounded';
import { firstIsVowel } from "../../utils/functions/index";

export default function ModalRemoveMember({ onRemove, onClose, member }) {
    const [removeMember, setRemoveMember] = useState(false)

    const handleOnClose = () => {
        setRemoveMember(false)
        onClose()
    }

    const handleRemoveMember = () => {
        onRemove(member)
        setRemoveMember(true)
    }

    return (
        <>
            {!removeMember ? (
                <Model
                    onClose={handleOnClose}
                    width="30%"
                >
                    <div className={'d-flex flex-column align-items-center w-100 mb-4 px-3'}>

                        <span
                            style={{
                                borderRadius: "100%",
                                border: "2px solid #E6492D80",
                                height: '56px',
                                width: "56px",
                                background: "#E6492D0D"
                            }}
                            className={'d-flex justify-content-center align-items-center mb-3'}
                        >
                            <DeleteOutlineRoundedIcon
                                style={{ color: "#dc4d34", fontSize: "24px" }} />
                        </span>
                        <AppText size='_20' width={'_medium'}>Remove Admin?</AppText>
                        <AppText size='_14' align={'_center'}>
                            Remove {' '}
                            <AppText size='_14' weight='_bold'>
                                {member.fullName}
                            </AppText>
                            {' '} {firstIsVowel(member.roles[0].as)} {member?.roles[0]?.as === "Admin " ? "Admin" : "Hiring Manager"}? This cannot be undone.
                        </AppText>
                    </div>
                    {/* Footer */}
                    <footer className={'d-flex justify-content-center p-3 mb-3 mt-1'}>
                        <span className={'mr-3'}>
                            <AppButton
                                color='_white'
                                style={{ width: '70px' }}
                                width={'_custom'} onClick={handleOnClose}
                            >
                                <AppText size='_14'>No</AppText>
                            </AppButton>
                        </span>
                        <AppButton
                            color={'_rc'}
                            style={{ width: '120px' }}
                            width={'_custom'}
                            onClick={() => handleRemoveMember()}
                        >
                            <AppText size='_14' color={'_c0'}>Yes, Remove</AppText>
                        </AppButton>
                    </footer>
                </Model>
            ) :
                <Model
                    onClose={handleOnClose}
                >
                    <div className={'d-flex flex-column align-items-center w-100 mb-5 pb-2 px-5'}>
                        <span
                            style={{
                                borderRadius: "100%",
                                border: "2px solid #03CA9880",
                                height: '56px',
                                width: "56px",
                                background: "#03CA980D"
                            }}
                            className={'d-flex justify-content-center align-items-center mb-3'}
                        >
                            <CheckRoundedIcon
                                style={{ color: "#62cbb1", fontSize: "24px" }} />
                        </span>
                        <AppText size='_20' width={'_medium'}>Admin Removed!</AppText>
                        <AppText size='_14' align={'_center'}>
                            User has been removed {firstIsVowel(member.roles[0].as)} {member?.roles[0]?.as === "Admin " ? "Admin" : "Hiring Manager"}. To re-add them, you have to send them an email invite again
                        </AppText>
                    </div>
                </Model>
            }
        </>
    );
}