const SET_ACTION = "SET_ACTION";
const SET_USER = "SET_USER";

const initialState = {
  loggedin: false,
  emailverified: false,
  accountCompleted: false,
  user: null,
};

const AppReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case SET_ACTION:
      return { ...state, ...action.payload };
    case SET_USER:
      return { ...state, user: action.payload };
    default:
      return state;
  }
};

export const changeAppState = (state) => {
    return {
        type: SET_ACTION,
        payload: state
    }
};
export const setUser = (user) => {
  return {
    type: SET_USER,
    payload: user,
  };
};

export default AppReducer;
