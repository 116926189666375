import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import App from './App';
import reportWebVitals from './reportWebVitals';

Sentry.init({
  dsn: "https://85edf99fe5734e52a40cf9555c673ab2@o876170.ingest.sentry.io/5825485",
  integrations: [new Integrations.BrowserTracing({
    tracingOrigins: ["hmp.taprecruit.co.uk", "develop.taprecruit.co.uk", "hiring.taprecruit.co.uk", /^\//]
  })],
  autoSessionTracking: true,
  tracesSampleRate: 0.2,
});

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById("root")
);
reportWebVitals();
