import { Check, LockRounded, } from "@material-ui/icons";
import AppText from "../atoms/AppText";
import { useLocation, useHistory } from "react-router-dom"
import { fire } from "../../firebase";
import AppButton from "../molecules/AppButton";
import { useMediaQuery } from "react-responsive";

export default function SuccessModal({ heading, sub }) {
    const isDesktop = useMediaQuery({ minWidth: 992 });

    const { state } = useLocation()
    const history = useHistory()

    const handleResend = () => {
        const auth = fire.auth();
        auth
            .sendPasswordResetEmail(state.email)
            .catch((err) => {
                console.log(err);
            });
    }
    return (
        <div className="d-flex flex-column _bg justify-content-center align-items-center" >
            <div className={`flex-column py-4 _box bg-white ${isDesktop ? "w-25" : "w-80"} d-flex justify-content-center align-items-center`} style={{ maxWidth: '380px' }}>
                <span className="d-flex justify-content-center align-items-center" style={{ display: "inline-block", height: "50px", width: "50px", color: "#03CA9880", borderRadius: "50px", border: "1px solid" }}>
                    {state?.email ? <Check style={{ fontSize: "26px", color: "#03CA98" }} /> : <LockRounded style={{ fontSize: "26px", color: "#03CA98" }} />}
                </span>

                <div className="py-2" />
                <AppText weight="_medium">{heading}</AppText>
                <div className="py-2" />
                <div className="px-3">
                    <AppText align="_center" size="_14">{`${sub}`} {state?.email && <span class="_g90">{state?.email}</span>}</AppText>
                </div>
                <div className="py-2" />
                <div className="px-4 w-100">
                    {state?.email === undefined && <AppButton width="_full" onClick={() => history.replace("/login")}>
                        <AppText size="_14" color="_c0">Login</AppText>
                    </AppButton>}
                </div>

            </div>
            <div className="py-2" />
            {state?.email && <AppText size="_14">Didn't receive the reset link? <span onClick={handleResend} className="pointer _g90">Resend</span></AppText>}

        </div>
    )
}