import moment from "moment";

export const returnInitals = (str) => {
  let name = str;
  name = name?.split(" ");
  return name?.map((n) => n.slice(0, 1).toUpperCase());
};

export const jobTextFunction = (count, type) => {
  if (count <= 1) {
    if (type === "drafts") {
      return `Job on draft`;
    }
    return `Job ${type}`;
  } else {
    if (type === "drafts") {
      return `Jobs on drafts`;
    }
    return `Jobs ${type}`;
  }
};

export const randomColor = (name) => {
  const colors = ["#1765d8", "#E6492D", "#000000", "#F6AB2F", "#03CA98"];
  const letter = UpperCaseFirst(name?.split("")[0]);

  switch (letter) {
    case "S":
      return colors[0];
    case "R":
      return colors[1];
    case "P":
      return colors[2];
    case "K":
      return colors[3];
    case "N":
      return colors[4];
    default:
      return colors[0];
  }
};

export const isSquareNumber = (len) => {
  const nonSqures = [1, 2, 5, 7, 8, 10, 11, 13, 14, 16, 17, 19, 20, 22];
  if (len) {
    for (let n of nonSqures) {
      if (n === len) return true;
    }
    return false;
  }
};
export const isFourSquare = (len = 20) => {
  // start from 4
  const fours = [4];

  // loop the length
  for (let i = 1; i <= len; i++) {
    // add 4 to last index
    const n = fours[fours.length - 1] + 4;
    fours.push(n);
  }

  if (len) {
    for (let n of fours) {
      if (n === len) return false;
    }
    return true;
  }
};

export const UpperCaseFirst = (str) => {
  return str?.substring(0, 1).toUpperCase() + str?.substring(1);
};

export const textCut = (str, n = 20) => {
  return str?.length > n ? str?.substr(0, n - 1) + "..." : str;
};

export const makeDate = (str) => {
  return moment(parseInt(str)).startOf("second").fromNow();
};

// calender logic

export const isTheSameOrBefore = (sd, ed) => {
  // let sdn = sd?.split(",");
  // let edn = ed?.split(",");
  // const sm = sdn[0];
  // const sy = sdn[1];
  // const em = edn[0];
  // const ey = edn[1];
  // if (sy < ey && sm <= em) {
  //   return true;
  // } else if (sy === ey && sm < em) {
  //   return true;
  // } else if (sy < ey && sm > em) {
  //   return true;
  // } else {
  //   return false;
  // }
};

export const isValidImage = (file) => {
  const acceptedFormats = ["image/jpeg", "image/png", "image/jpg"];
  if (file.hasOwnProperty("0")) {
    if (acceptedFormats.find((format) => format === file[0]?.type)) {
      return { temp: window.URL.createObjectURL(file[0]), blob: file[0] };
    } else {
      return false;
    }
  } else {
    if (acceptedFormats.find((format) => format === file?.type)) {
      return { temp: window.URL.createObjectURL(file), blob: file };
    } else {
      return false;
    }
  }
};

export const impression = (data) => {
  let impression = 20;
  let tip = "";

  if (data?.experience?.length >= 1) {
    impression = impression + 20;
  } else {
    tip =
      "Add a Work Experience to your profile and increase your chance of getting hired";
  }
  if (data?.education?.length >= 1) {
    impression = impression + 20;
  } else {
    tip =
      "Add a Education to your profile and increase your chance of getting hired";
  }
  if (data?.skills?.length >= 1) {
    impression = impression + 20;
  } else {
    tip =
      "Add skills to your profile and increase your chance of getting hired";
  }
  if (data?.videoUrl || data?.embedCode) {
    impression = impression + 20;
  } else {
    tip =
      "Add a Video CV to your profile and increase your chance of getting hired.";
  }
  if (data) {
    return { strength: impression, tip };
  }
};

export const isEnoughSummary = (s) => {
  if (s?.length >= 100) {
    return true;
  } else {
    return false;
  }
};

export const getCurrentJob = (jobs) => {
  let job = false;
  for (let i in jobs) {
    if (jobs[i].workHere) {
      job = jobs[i];
    }
  }
  return job;
};

export const getPastJob = (jobs) => {
  let job = jobs.filter((job) => !job.workHere);
  return job;
};

export const getShortliested = (candidates) => {
  return candidates.filter((candidate) => candidate.shortlisted);
};

export const format = (seconds) => {
  if (isNaN(seconds)) {
    return `00:00`;
  }
  const date = new Date(seconds * 1000);
  const hh = date.getUTCHours();
  const mm = date.getUTCMinutes();
  const ss = date.getUTCSeconds().toString().padStart(2, "0");
  if (hh) {
    return `${hh}:${mm.toString().padStart(2, "0")}:${ss}`;
  }
  return `${mm}:${ss}`;
};

export const getWidth = (length) => {
  if (length === 0) {
    return "";
  } else if (length === 1) {
    return "25px";
  } else if (length === 2) {
    return "50px";
  } else if (length === 3) {
    return "75px";
  } else if (length > 3) {
    return "100px";
  }
};

export const firstIsVowel = (str) => {
  if (["a", "e", "i", "o", "u"].indexOf(str[0].toLowerCase()) !== -1) {
    return "as an";
  } else {
    return "as a";
  }
};

export const getTime = (time) => {
  const dateTime = moment(parseInt(time)).format("YYYY-MM-DD");
  if (!moment().subtract(1, "days").isAfter(dateTime)) {
    return moment(parseInt(time)).format("LT");
  } else if (
    moment(dateTime).isBetween(
      moment().subtract(2, "days"),
      moment().subtract(1, "days")
    )
  ) {
    return "Yesterday " + moment(parseInt(time)).format("LT");
  } else if (
    moment(dateTime).isBetween(
      moment().subtract(7, "days"),
      moment().subtract(2, "days")
    )
  ) {
    return moment(parseInt(time)).format("ddd LT");
  } else {
    return moment(parseInt(time)).format("MMM DD/YYYY LT");
  }
};

export const addHttp = (str) => {
  if (str.startsWith("https://")) {
    return str;
  } else if (str.length === 0) {
    return "";
  } else if (str.length < 8) {
    return "https://";
  } else if (!str.startsWith("https://")) {
    return "https://" + str;
  }
};

export const validateTime = (time) => {
  if (time === null || time === "") {
    return "";
  } else if (typeof time !== "object") {
    return moment(parseInt(time)).set({ h: 0, m: 59 }).format("x");
  } else {
    return moment(time).set({ h: 0, m: 59 }).format("x");
  }
};

export const addOneDay = (time) => {
  if (time === null || time === "") {
    return "";
  } else if (typeof time !== "object") {
    return moment(parseInt(time))
      .utc()
      .add(1, "days")
      .set({ h: 23, m: 59 })
      .format("x");
  } else {
    return moment(time).utc().add(1, "days").set({ h: 23, m: 59 }).format("x");
  }
};

export const getWindowDimensions = () => {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
};

export const sortChats = (chats, id) => {
  if (id) {
    const newOrder = [...chats].sort((a, b) => {
      return a.id === id.conversationId
        ? -1
        : b.id === id.conversationId
        ? 1
        : 0;
    });
    return newOrder;
  } else {
    const newOrder = [...chats].sort((a, b) => {
      return parseFloat(b.lastUpdate) - parseFloat(a.lastUpdate);
    });
    return newOrder;
  }
};

export const isClosed = (time) => {
  if (typeof time !== "object") {
    if (moment(parseInt(time)).isBefore(moment(), "day")) {
      return "Closed";
    } else {
      return "Closing";
    }
  } else {
    if (moment(time).isBefore(moment(), "day")) {
      return "Closed";
    } else {
      return "Closing";
    }
  }
};

export const submitButton = (formik, isFixed, isHourly, closingDate) => {
  if (
    !formik.values.jobTitle ||
    !formik.values.experienceLevel ||
    !formik.values.location ||
    !formik.values.employmentType ||
    !formik.values.salaryRange.end ||
    !formik.values.description ||
    !formik.values.mapCordinates.lat
  ) {
    return true;
  } else if (!isFixed && !isHourly) {
    if (!formik.values.salaryRange.start) {
      return true;
    } else if (
      parseFloat(formik.values.salaryRange?.start) >=
      parseFloat(formik.values.salaryRange?.end)
    ) {
      return true;
    }
  } else if (closingDate) {
    if (!formik.values?.closingDate) {
      return true;
    }
  } else {
    return false;
  }
};

export const getDate = (time) => {
  if (typeof time !== "object") {
    if (moment(parseInt(time)).utc().isSame(moment().utc(), "day")) {
      return "Today";
    } else {
      return `on ${moment(parseInt(time)).utc().format("MMMM DD YYYY")}`;
    }
  } else {
    if (moment(time).utc().isSame(moment().utc(), "day")) {
      return "Today";
    } else {
      return `on ${moment(time).utc().format("MMMM DD YYYY")}`;
    }
  }
};

export const closeDate = (time) => {
  if (
    moment(parseInt(time)).subtract(1, "day").isBefore(moment().add(7, "days"))
  ) {
    if (moment().isAfter(moment(parseInt(time)))) {
      return false;
    } else if (
      moment(parseInt(time)).subtract(1, "day").isSame(moment(), "day")
    ) {
      return `Closes in less than a day`;
    } else {
      return `Closes ${moment(parseInt(time))
        .subtract(1, "day")
        .endOf("days")
        .fromNow()}`;
    }
  } else {
    return false;
  }
};

export const getLastMessage = (unRead, inChat, message) => {
  if (
    unRead.filter((chat) => chat.conversationId === message.id).length !== 0
  ) {
    return textCut(
      unRead?.filter((chat) => chat.conversationId === message.id)[
        unRead?.filter((chat) => chat.conversationId === message.id).length - 1
      ]?.messageContent,
      30
    );
  } else if (inChat.length) {
    if (
      textCut(
        inChat.filter((chat) => chat.conversationId === message.id)[
          inChat.filter((chat) => chat.conversationId === message.id).length - 1
        ]?.messageContent,
        30
      )
    ) {
      return textCut(
        inChat.filter((chat) => chat.conversationId === message.id)[
          inChat.filter((chat) => chat.conversationId === message.id).length - 1
        ]?.messageContent,
        30
      );
    }
    return textCut(message?.lastMessage, 30);
  } else {
    return textCut(message?.lastMessage, 30);
  }
};
