import React from "react";
import ModalFooter from "../molecules/ModalFooter";

export default function AppDialog({ saveText, closeText, content, onClose, onSave, isLoading, job = false }) {
  return (
    <div>
      <div className='p-4'>{content}</div>
      <ModalFooter
        isLoading={isLoading}
        onSave={onSave}
        onClose={onClose}
        saveText={saveText}
        closeText={closeText}
        isModal={false}
        job={job}
      />
    </div>
  );
}
