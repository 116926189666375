import React, { useState, useRef } from "react";
import AppText from "../atoms/AppText";
import AddIcon from "@material-ui/icons/Add";
import { randomColor, getWidth } from "../../utils/functions/index";
import ModalInvitation from "../molecules/ModalInvitation";
import ModalRemoveMember from "../molecules/ModalRemoveMember";
import { Avatar, Collapse, Button } from "@material-ui/core";

export default function CompanyCard({ members, onRemove, onSend, iam }) {
  const [drop, showDrop] = useState(false);
  const [isAddModal, setIsAddModal] = useState(false);
  const [isRemoveModal, setIsRemoveModal] = useState(false);
  const [member, setMember] = useState({});
  const dropRef = useRef(null);

  const handleOnRemove = (item) => {
    setMember(item);
    setIsRemoveModal(true);
  };

  const handleOnClose = () => {
    setIsRemoveModal(false);
  };

  
  return (
    <div className={"d-flex"}>
      <span className={"mr-3"}>
        <AppText size='_14' weight='_medium'>
          Admins
        </AppText>
      </span>
      <div
        className={"d-flex align-items-center pointer"}
        style={{ width: getWidth(members?.length) }}
        onClick={() => showDrop(!drop)}
      >
        {members?.map((item, i) => {
          if (i < 3) {
            return (
              <span
                className={"position-absolute d-flex align-items-center justify-content-center"}
                style={{ width: "23px" }}
              >
                <Avatar
                  style={{
                    height: "30px",
                    width: "30px",
                    background: `${!item.avatarUrl ? randomColor(item.fullName) : ""}`,
                    marginLeft: `${i * 50}px`,
                    border: "2px solid #FFFF",
                  }}
                  className={"mr-2"}
                  src={item.avatarUrl ? item.avatarUrl : ""}
                ></Avatar>
              </span>
            );
          } else if (i === 3) {
            return (
              <span
                className={
                  "position-absolute d-flex align-items-center justify-content-center px-1 mr-2"
                }
                style={{
                  background: "#F6AB2F",
                  borderRadius: "100%",
                  width: "30px",
                  height: "30px",
                  border: "2px solid #FFFF",
                  marginLeft: "67px",
                }}
              >
                <AppText size='_14' color={"_c0"}>
                  +{members.length - 3}
                </AppText>
              </span>
            );
          } else {
            return null;
          }
        })}
      </div>
      {iam.trim() === "Admin" && (
        <span
          className={"d-flex align-items-center justify-content-center pointer"}
          style={{
            border: ".1em solid #BCBCBC",
            borderRadius: "100%",
            width: "28px",
            height: "28px",
          }}
          onClick={() => setIsAddModal(true)}
        >
          <AddIcon style={{ color: "#BCBCBC", fontSize: 18 }} />
        </span>
      )}

      {drop && (
        <Collapse in={drop} className={"mt-2"}>
          <div
            className='position-absolute _admin_drop mt-5 '
            ref={dropRef}
            style={{ zIndex: "1", marginRight: '11vw' }}
          >
            {members.map((item) => (
              <div className={"d-flex p-3 align-items-center pb-2 _hover"}>
                <Avatar
                  style={{
                    height: "30px",
                    width: "30px",
                    background: `${!item.avatarUrl ? randomColor(item.fullName) : ""}`,
                  }}
                  className={"mr-2"}
                  src={item.avatarUrl ? item.avatarUrl : ""}
                ></Avatar>
                <div className={"w-100 d-flex justify-content-between align-items-center"}>
                  <div className={"d-flex flex-column "}>
                    <AppText size='_14' weight={"_medium"}>
                      {item.fullName}
                    </AppText>
                    <AppText size='_14' color={"_c50"}>
                      {item.roles[0].as.trim() === 'Admin' ? 'Admin' : 'Hiring Manager'}
                    </AppText>
                  </div>
                  <span className={"pointer"} onClick={() => handleOnRemove(item)}>
                    {(item.roles[0].as.trim() !== "Admin" && iam.trim() === "Admin") && (
                      <AppText size='_12' color={"_r100"}>
                        Remove
                      </AppText>
                    )}
                  </span>
                </div>
              </div>
            ))}
            {iam.trim() === "Admin" && (
              <div className='bt-r mt-3 d-flex justify-content-center'>
                <Button className='btn_p w-100' onClick={() => setIsAddModal(true)}>
                  <AppText size='_12' color='_c90'>
                    ADD NEW COMPANY ADMIN
                  </AppText>
                </Button>
              </div>
            )}
          </div>
        </Collapse>
      )}
      {isAddModal && (
        <ModalInvitation
          title={"Add New Admin"}
          onClose={() => setIsAddModal(false)}
          onSend={onSend}
        />
      )}
      {isRemoveModal && (
        <ModalRemoveMember onClose={handleOnClose} onRemove={onRemove} member={member} />
      )}
    </div>
  );
}
