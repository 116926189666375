import React, { useRef, useEffect } from 'react'
import AppText from '../atoms/AppText'
import moment from 'moment'
import SchoolIcon from '@material-ui/icons/School';

export default function Educations({ title, educations, isSelect }) {
    const elRef = useRef(null);
    useEffect(() => {
        const handleScroll = () => {
            if (elRef?.current?.getBoundingClientRect()?.top - 100 <= 0 && 100 + elRef?.current?.getBoundingClientRect()?.top >= 1) {
                isSelect('Education')
            }
        }
        document.addEventListener('scroll', handleScroll);
        return () => {
            document.removeEventListener('scroll', handleScroll);
        }
    }, [isSelect])

    return (
        <div className={`mt-3 mb-4 w-100 card_style`} key={'Education'} id={'Education'} ref={elRef}>
            {/* Header */}
            <header className={'p-4'}>
                <AppText size='_18' weight='_medium'>
                    {title}
                </AppText>
            </header>
            <div className='_sep_' />
            {educations
                .sort((a, b) => a.startDate > b.startDate ? -1 : a.startDate < b.startDate ? 1 : 0)
                .map((item, i) => (
                    <div className={'p-3'}>
                        <div className={'d-flex'}>
                            <div className='_emp_logo mr-3 px-3'>
                                <SchoolIcon style={{ fontsSize: "22px" }} />
                            </div>
                            <div className={'w-100 d-flex justify-content-between'}>
                                <div className={'d-flex flex-column'}>
                                    <AppText size='_18' weight='_medium'>
                                        {item.field}
                                    </AppText>
                                    <div className={'d-flex'}>
                                        <AppText size='_14' weight='_medium' color={'_c50'}>
                                            {item.school}
                                        </AppText>
                                    </div>
                                </div>
                                <div className={'h-100 d-flex flex-column justify-content-end'}>
                                    <AppText size='_14' color={'_c50'}>
                                        {moment(item.startDate).format('MMM YYYY')}
                                        {' - '}
                                        {moment(item.endDate).format('MMM YYYY')}
                                    </AppText>
                                </div>
                            </div>
                        </div>
                        {i + 1 !== educations.length ? (<div className='_sep_ mt-3' />) : <div className='mt-3' />}
                    </div>
                ))}
        </div>
    )
}