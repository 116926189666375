import React from "react";
import { useFormik } from "formik";
import { useMutation, gql } from "@apollo/client";
// import { useMediaQuery } from "react-responsive";
import AppInput from "..//molecules/AppInput";
import { socialSchema } from "../../utils/schema/accountSetupSchema";
import { addHttp } from "../../utils/functions/index";
import AppText from "../atoms/AppText";
import AppButton from "../molecules/AppButton";

const SET_SOCIAL_LINKS = gql`
  mutation ($input: _CompanyInputUpdate) {
    updateCompany(input: $input) {
      id
    }
  }
`;

export default function CompanySocial({ company, iam }) {
  const [UpdateCompany] = useMutation(SET_SOCIAL_LINKS);
  const formik = useFormik({
    initialValues: {
      facebook: company?.facebookLink || "",
      twitter: company?.twitterLink || "",
      instagram: company?.instagramLink || "",
      linkedIn: company?.linkedinLink || "",
    },
    validationSchema: socialSchema,
    onSubmit: (values) => {
      const { facebook, twitter, instagram, linkedIn } = values;
      const data = {
        facebookLink: facebook,
        twitterLink: twitter,
        linkedinLink: linkedIn,
        instagramLink: instagram,
      };
      UpdateCompany({
        variables: { input: { companyId: company?.id, values: data } },
      });
    },
  });

  const handleSubmit = () => {
    formik.submitForm();
  };

  const handleChangeValue = (value, type) => {
    formik.setFieldValue(type, addHttp(value))
  }
  
  return (
    <div>
      <div className={"card_style mb-4"}>
        {/* Header */}
        <header className={"d-flex flex-column p-4"}>
          <AppText size='_18' weight='_medium'>
            Basic Information
          </AppText>
          <AppText size='_14' color={"_c50"}>
            Let Job Seekers know more about your company
          </AppText>
        </header>
        <div className='_sep_' />
        <form className={"p-4"}>
          <div className='d-flex flex-wrap justify-content-between'>
            {/* Facebook */}
            <div className={"w-100 mt-3"}>
              <AppInput
                label='FACEBOOK'
                placeholder='https://www.facebook.com/'
                // editable={state === "preview" ? false : true}
                onChange={(e) => handleChangeValue(e.target.value, 'facebook')}
                onBlur={formik.handleBlur}
                value={formik.values.facebook}
                name='facebook'
                hasError={formik.touched.facebook && formik.errors.facebook ? true : false}
              />
              {formik.touched.facebook && formik.errors.facebook && (
                <AppText size='_12' color='_r100'>
                  {formik.errors.facebook}
                </AppText>
              )}
            </div>

            {/* Twitter */}
            <div className={"w-100 mt-3"}>
              <AppInput
                label='TWITTER'
                placeholder='https://twitter.com/'
                // editable={state === "preview" ? false : true}
                onChange={(e) => handleChangeValue(e.target.value, 'twitter')}
                onBlur={formik.handleBlur}
                value={formik.values.twitter}
                name='twitter'
                hasError={formik.touched.twitter && formik.errors.twitter ? true : false}
              />
              {formik.touched.twitter && formik.errors.twitter && (
                <AppText size='_12' color='_r100'>
                  {formik.errors.twitter}
                </AppText>
              )}
            </div>
            {/* Instagram */}
            <div className={"w-100 mt-3"}>
              <AppInput
                label='INSTAGRAM'
                placeholder='https://instagram.com/'
                // editable={state === "preview" ? false : true}
                onChange={(e) => handleChangeValue(e.target.value, 'instagram')}
                onBlur={formik.handleBlur}
                value={formik.values.instagram}
                name='instagram'
                hasError={formik.touched.instagram && formik.errors.instagram ? true : false}
              />
              {formik.touched.instagram && formik.errors.instagram && (
                <AppText size='_12' color='_r100'>
                  {formik.errors.instagram}
                </AppText>
              )}
            </div>
            {/* Web Link */}
            <div className={"w-100 mt-3"}>
              <AppInput
                label='LINKEDIN'
                placeholder='https://linkedIn.com/'
                // editable={state === "preview" ? false : true}
                onChange={(e) => handleChangeValue(e.target.value, 'linkedIn')}
                onBlur={formik.handleBlur}
                value={formik.values.linkedIn}
                name='linkedIn'
                hasError={formik.touched.linkedIn && formik.errors.linkedIn ? true : false}
              />
              {formik.touched.linkedIn && formik.errors.linkedIn && (
                <AppText size='_12' color='_r100'>
                  {formik.errors.linkedIn}
                </AppText>
              )}
            </div>
          </div>
          <div className='mt-3' />
        </form>
        {/* Footer */}
        {iam.trim() === "Admin" && (
          <>
            <div className='_sep_' />
            <footer
              className={"d-flex justify-content-end p-3"}
              style={{ backgroundColor: "#FBFBFD" }}
            >
              <AppButton
                color='_white'
                style={{ width: "70px" }}
                width={"_custom"}
                onClick={handleSubmit}
                state={Object.keys(formik.errors).length ? true : false}
              >
                <AppText size='_14'>Save</AppText>
              </AppButton>
            </footer>
          </>
        )}
      </div>
    </div>
  );
}
