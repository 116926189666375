import React from "react";
import AppText from "../atoms/AppText";

export default function JobOverview({ title, number, text, newData }) {
  return (
    <div className={"p-4 _jobOverview w-48 d-flex flex-column"}>
      <AppText size='_14' color='_c50' weight='_medium'>
        {title}
      </AppText>
      <div className='d-flex align-items-center'>
        <AppText size='_30'>{number}</AppText>
        <div className='ml-2'>
          <AppText size='_14' color={newData > 0 ? "_g90" : newData < -1 ? "_r100" : "_c90 "}>
            {text}
          </AppText>
        </div>
      </div>
    </div>
  );
}
