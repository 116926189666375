import React from "react";
import { CloseOutlined } from "@material-ui/icons";
import { Avatar } from "@material-ui/core";
import AppText from "./AppText";



export default function Recipients({ candidate, onClick }) {
  const { photoUrl, firstName, lastName } = candidate.candidate
  return (
    <div
      className={"d-flex justify-content-center align-items-center py-1 px-2 mr-2 mt-2"}
      style={{ border: "1px solid #D8DCE6", borderRadius: "5px", boxShadow: "0 1px 6px #eeeeee" }}>
      <Avatar
        sizes='large'
        className={"mr-2"}
        src={photoUrl || null}
        style={{ height: "17px", width: "17px" }}
      ></Avatar>
      <AppText size={'_14'}>{firstName} {lastName}</AppText>
      <CloseOutlined onClick={() => onClick(candidate)} style={{fontSize:'16px', color: "#9EA0A5", cursor:"pointer"}}/>
    </div>
  );
}
