import React from "react";
import { useMediaQuery } from "react-responsive";
import { TextField, makeStyles } from "@material-ui/core";
import AppText from "../atoms/AppText";

export default function AppInput({
  label,
  onChange,
  value,
  name,
  onBlur,
  placeholder,
  editable = true,
  onKeyUp,
  hasError = false,
  onlyNumber = false
}) {
  const isDesktop = useMediaQuery({ minWidth: 992 });
  const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 991 });
  const useSyles = makeStyles({
    label: { marginBottom: "10px" },
    root: {
      width: "100%",
      "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
        border: "none",
      },
      "& .MuiOutlinedInput-notchedOutline": {
        border: "none",
        maxHeight: "38px",
      },
    },
    input: {
      WebkitAppearance: "none",
      MozAppearance: "none",
      appearance: "none",
      fontFamily: "Roboto",
      fontSize: "14px",
      marginTop: "-2px",
      height: "16.4px",
      color: name === "email" && !editable && (isDesktop || isTablet) ? "#ddd" : "#3E3F42",
      border: hasError ? "1px solid #E6492D" : "1px solid #D8DCE6",
      borderRadius: "4px",
    },
  });
  const classes = useSyles();

  const handleChange = (e) => {
    if (onlyNumber) {
      if (e.target.value.match(/^[0-9]{0,4}$/g)) {
        onChange(e)
      }
    }
    else {
      onChange(e)
    }
  }
  return (
    <div>
      {label !== undefined && (
        <div className={classes.label}>
          <AppText size='_12' color='_c50' weight='_medium'>
            {label}
          </AppText>
        </div>
      )}
      <div>
        <TextField
          value={value}
          name={name}
          variant='outlined'
          fullWidth
          placeholder={placeholder}
          size='small'
          disabled={!editable}
          onChange={(e) => handleChange(e)}
          onBlur={onBlur}
          className={classes.root}
          onKeyUp={onKeyUp}
          inputProps={{
            className: classes.input,
          }}
          autoComplete='off'
        />
      </div>
    </div>
  );
}
