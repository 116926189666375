import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
// import { useMediaQuery } from "react-responsive";
import AppText from "../atoms/AppText";
import UploadButton from "../atoms/UploadButton";
import Model from "../molecules/Model";
import { isValidImage } from "../../utils/functions/index";
import Compressor from "compressorjs";
import HeaderUploadModal from "./HeaderUploadModal";
import UploadLogo from "./UploadLogo";
import AddAPhotoOutlinedIcon from "@material-ui/icons/AddAPhotoOutlined";

export default function CompanyImgs({ iam }) {
  const { user } = useSelector((state) => state.app);

  const [isExactHeader, setIsExactHeader] = useState(false);
  const [isExactLogo, setIsExactLogo] = useState(false);
  const [temp, setTemp] = useState(null);
  const [previewImage, setPreviewImage] = useState(false);
  const [previewImageWidth, setPreviewImageWidth] = useState(0);
  const [previewLogo, setPreviewLogo] = useState(false);
  const [blob, setBlob] = useState(null);

  useEffect(() => {
    if (user?.roles[0]?.company?.header) {
      setIsExactHeader(true);
    }
    if (user?.roles[0]?.company?.logo) {
      setIsExactLogo(true);
    }
  }, [user?.roles]);

  const handleHeaderChange = (file) => {
    new Compressor(file[0], {
      quality: 0.9,
      height: 700,
      success: (results) => {
        const { temp } = isValidImage(results);
        let img = document.createElement("img");
        img.src = temp;

        img.onload = () => {
          if (img.width > 720) {
            setPreviewImageWidth(img.width);
            setTemp(temp);
            setPreviewImage(true);
            setIsExactHeader(true);
          } else {
            setIsExactHeader(false);
          }
        };
      },
    });
  };

  const handleLogoChange = (file) => {
    const { temp } = isValidImage(file[0]);
    setTemp(temp);
    setBlob(file[0]);

    let img = document.createElement("img");
    img.src = temp;
    img.onload = () => {
      if (img.height === img.width) {
        setIsExactLogo(true);
        setPreviewLogo(true);
      } else {
        setIsExactLogo(false);
      }
    };
  };

  const header = user?.roles[0]?.company?.header;
  const logo = user?.roles[0]?.company?.logo;

  return (
    <div>
      <div className={"card_style mb-4"}>
        {/* Header */}
        <header className={"d-flex flex-column p-4"}>
          <AppText size="_18" weight="_medium">
            Company Profile Images
          </AppText>
        </header>
        <div className="_sep_" />
        <div className={"p-4"}>
          <div className="position-relative">
            <div
              className={"d-flex flex-column position-relative"}
              style={{
                borderRadius: "4px 4px 0px 0px",
              }}
            >
              <img className="w-100 h-100" alt={header} src={header} />
              <div
                className="position-absolute w-100 h-100 d-flex justify-content-center align-items-center"
                style={{
                  background: "rgba(0, 0, 0, 0.5)",
                  borderRadius: "4px 4px 0px 0px",
                }}
              >
                <UploadButton onChange={(file) => handleHeaderChange(file)}>
                  <span
                    className={`d-flex p-1 px-2 align-items-center pointer shadow-sm`}
                    style={{ border: `1px solid #eaedf3`, borderRadius: "4px" }}
                  >
                    <AddAPhotoOutlinedIcon
                      label="Add image"
                      style={{ fontSize: "16px", color: "#FFFF" }}
                    />
                  </span>
                </UploadButton>
              </div>
            </div>
          </div>

          <div
            className="w-45 mb-3 pl-4 pt-1 pb-5 w-100"
            style={{
              border: "1px solid #eee",
              borderRadius: "0px 0px 4px 4px",
            }}
          >
            {!isExactHeader && (
              <AppText size="_14" color={"_r100"}>
                The minimum company header image size is 720px by 240px
              </AppText>
            )}

            <div className="d-flex pb-2 pt-4 w-100">
              <div
                className="company_logo d-flex flex-column  position-relative"
                style={{ border: "1px solid #eee", borderRadius: "4px" }}
              >
                <img alt="sdd" className="w-100 h-100" src={logo} />
                <div
                  className="position-absolute w-100 h-100 d-flex justify-content-center align-items-center"
                  style={{
                    background: "rgba(0, 0, 0, 0.5)",
                    borderRadius: "4px",
                  }}
                >
                  <UploadButton
                    label=""
                    onChange={(file) => handleLogoChange(file)}
                  >
                    <span
                      className={`d-flex p-1 px-2 align-items-center pointer shadow-sm`}
                      style={{
                        border: `1px solid #eaedf3`,
                        borderRadius: "4px",
                      }}
                    >
                      <AddAPhotoOutlinedIcon
                        label="Add image"
                        style={{ fontSize: "16px", color: "#FFFF" }}
                      />
                    </span>
                  </UploadButton>
                </div>
              </div>

              <div className="d-flex flex-column justify-content-center pl-4">
                <div
                  className={"border-0 rounded mt-2"}
                  style={{
                    background: "#fafafa",
                    height: "35px",
                    width: "155px",
                  }}
                ></div>
                <div
                  className={"border-0 rounded mt-2"}
                  style={{
                    background: "#fafafa",
                    height: "16px",
                    width: "205px",
                  }}
                ></div>
              </div>
            </div>
            {!isExactLogo && (
              <AppText size="_14" color={"_r100"}>
                The minimum company logo size is 100px by 100px
              </AppText>
            )}
          </div>
        </div>
        {/* <div className={"px-4 pb-4"}>
          {error ? (
            <AppText size="_14" color={"_r100"}>
              Logo must be of equal length and width.
            </AppText>
          ) : (
            <AppText size="_14" color="_c50">
              Logo must be of equal length and width. Header image must be 720px
              by 240px in size.
            </AppText>
          )}
          <div>
            {sizeError && (
              <AppText size="_14" color={"_r100"}>
                Header image uploaded is too small
              </AppText>
            )}
          </div>
        </div> */}
        {iam.trim() === "Admin" && (
          <>
            {/* <div className='_sep_' /> */}
            {/* Footer */}
            {/* <footer
              className={"d-flex justify-content-end p-3"}
              style={{ backgroundColor: "#FBFBFD", borderTop: "1px solid #eee" }}
            >
              <AppButton
                color='_white'
                style={{ width: "70px" }}
                width={"_custom"}
                state={!header || !logo ? true : false}
              >
                <AppText size='_14'>Save</AppText>
              </AppButton>
            </footer> */}
          </>
        )}
      </div>
      {previewImage && (
        <Model
          loose={true}
          onClose={() => {
            setPreviewImage(false);
            setTemp(null);
          }}
        >
          <HeaderUploadModal
            temp={temp}
            width={previewImageWidth}
            onClose={() => {
              setPreviewImage(false);
              setTemp(null);
              setIsExactHeader(true);
            }}
          />
        </Model>
      )}
      {previewLogo && (
        <Model
          onClose={() => {
            setPreviewLogo(false);
            setTemp(null);
          }}
        >
          <UploadLogo
            temp={temp}
            blob={blob}
            onClose={() => {
              setPreviewLogo(false);
              setTemp(null);
            }}
          />
        </Model>
      )}
    </div>
  );
}
