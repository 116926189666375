import { createStore, combineReducers } from "redux";
import AppReducer from "./stacks/ducks";
import AuthReducer from "./stacks/auth/Ducks";
import ChatsReducer from "./stacks/home/ChatsDucks";

const Store = createStore(
  combineReducers({
    app: AppReducer,
    auth: AuthReducer,
    Chats: ChatsReducer
  })
);

export default Store;
