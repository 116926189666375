import React from "react";
import AppText from "../atoms/AppText";
import CloseIcon from "@material-ui/icons/Close";
import { IconButton, makeStyles, Modal, Zoom } from "@material-ui/core";

export default function SendModal({
  children,
  onClose,
  title,
  closeButton = true,
  loose = false,
  dimesions,
  width = "50%"
}) {
  const useStyles = makeStyles({
    root: {
      padding: "0px",
      "&:hover": { background: "none" },
      "&:focus": { outline: 0 },

    },
    modal: {
      maxWidth: width,
      position: "absolute",
      minHeight: "30vh",
      backgroundColor: "#fff",
      borderRadius: "4px",
      marginTop: "2rem",
      width: '400px'
    },
    modalContainer: {
      position: "relative",
      display: "flex",
      justifyContent: "center",
    },
  });

  const classes = useStyles();

  return (
    <Modal
      className={`${classes.modalContainer} d-flex align-items-center w-100 h-100 justify-content-center`}
      style={{ width: "100%important" }}
      open={true}
      onClose={onClose}
      aria-labelledby='simple-modal-title'
      aria-describedby='simple-modal-description'
      BackdropProps={{ style: { backgroundColor: "rgba(0,0,0,.50)" } }}
    >
      <Zoom in={true}>
        <div className={classes.modal}>
          {closeButton ? (
            <div className='py-3 d-flex justify-content-between align-items-center px-3'>
              <AppText size='_20' weight='_medium'>
                {title}
              </AppText>
              <IconButton disableFocusRipple disableRipple className={classes.root} onClick={onClose}>
                <CloseIcon style={{ color: "#9EA0A5", fontSize: "20px" }} />
              </IconButton>
            </div>
          ) : null}
          <div className='_bbotm' />
          <div>{children}</div>
        </div>
      </Zoom>
    </Modal>
  );
}