import React from "react";
import AppText from "../atoms/AppText";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { useMediaQuery } from "react-responsive";
import { textCut } from '../../utils/functions/index'

export default function CompanyCard() {
  const isDesktop = useMediaQuery({ minWidth: 992 });
  const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 991 });

  const { user } = useSelector((state) => state.app);
  const company = user?.roles[0].company;
  const as = user?.roles[0].as;

  return (
    <div className={isDesktop ? "w-35 pl-3" : isTablet ? "w-50 mb-4" : "w-100 mb-4"}>
      <div className='bg-white _companybox'>
        <div className='pt-3 px-4'>
          <AppText size='_12' weight={'_medium'} color='_c50'>
            COMPANY PROFILE
          </AppText>
        </div>

        <div className='d-flex mt-3 px-4'>
          <img alt='er3' src={company?.logo} className='_clogo mr-3' />
          <div>
            <AppText size='_18' weight={'_medium'} >{company?.name}</AppText>
            <div>
              <AppText size='_14' color='_c90'>
                {company?.services} | {company?.location}
              </AppText>
            </div>
          </div>
        </div>
        <div className='py-3 px-4'>
          <AppText size='_14' color='_c90'>
            {textCut(company?.bio, 150)}
          </AppText>
        </div>
        {as.trim() === "Admin" && (
          <div className='bt-r d-flex justify-content-center'>
            <button className='btn_p w-100'>
              <Link to='/manage-account'>
                <AppText size='_12' weight={'_medium'} color='_c50'>
                  MANAGE ACCOUNT
                </AppText>
              </Link>
            </button>
          </div>
        )}
      </div>
    </div>
  );
}
