import {
  ApolloClient,
  InMemoryCache,
  HttpLink,
  concat,
  split,
} from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { getMainDefinition } from "@apollo/client/utilities";
import { WebSocketLink } from "@apollo/client/link/ws";
import { fire } from "./firebase";

const httpLink = new HttpLink({
  uri: "https://d3ru4so3z39qii.cloudfront.net/",
});

const wsLink = new WebSocketLink({
  uri: "wss://d3ru4so3z39qii.cloudfront.net/graphql",
  options: {},
});

const splitLink = split(
  ({ query }) => {
    const definition = getMainDefinition(query);
    return (
      definition.kind === "OperationDefinition" &&
      definition.operation === "subscription"
    );
  },
  wsLink,
  httpLink
);

const asyncAuthLink = setContext(async () => {
  return {
    headers: {
      Authorization: `Hiring ${await fire.auth().currentUser?.getIdToken()}`,
    },
  };
});

const Client = new ApolloClient({
  cache: new InMemoryCache({
    addTypename: false,
  }),
  link: concat(asyncAuthLink, splitLink),
});

export default Client;
