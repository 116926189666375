import React, { useState, useEffect } from "react";
import AppText from "../atoms/AppText";
import DropDownArrow from "../atoms/DropDownArrow";
import OutSide from "react-outside-click-handler";

export default function AppDropDownButton({ background, onChange, buttonOption, buttonOptions, border = true }) {

  const [showDrop, setShowDrop] = useState(false);
  const [value, setValue] = useState(buttonOption);

  const handleClick = (value) => {
    setValue(value.label);
    onChange(value);
  }

  useEffect(() => {
    setValue(buttonOption)
  }, [buttonOption])

  return (
    <div className='position-relative'>
      <OutSide onOutsideClick={() => setShowDrop(false)}>
        <div
          style={{ backgroundColor: `${background}`, boxShadow: background && "none" }}
          onClick={() => setShowDrop(!showDrop)}
          className={`${border ? "_gbtn_container" : ""} px-3 d-flex justify-content-between align-items-center pointer`}
        >
          <AppText size={`_14`} color={`${border ? "_c90" : "_c100"}`} weight={`${border ? null : "_medium"}`}>
            <span className='d-flex align-items-center'>
              <span className='ml-1 mr-2' style={{ color: background ? "#fff" : "#3E3F42" }}>
                {value}
              </span>
            </span>
          </AppText>
          <DropDownArrow color={background !== undefined ? "#fff" : null} />
        </div>

        {showDrop && (
          <div className='position-absolute _droper' style={{minWidth:"115px"}}>
            {buttonOptions.map((option, index) => (
              <div
                className='_drop_item w-100'
                key={index}
                onClick={() => {
                  handleClick(option);
                  setShowDrop(false);
                }}
              >
                <AppText size='_14'>{option.label}</AppText>
              </div>
            ))}
          </div>
        )}
      </OutSide>
    </div>
  );
}
