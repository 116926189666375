import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import SuccessModal from "../../components/templates/SuccessModel";
import ResetPasswordModal from "../../components/templates/ResetPasswordModal";
import EmailVerification from "./Templates/EmailVerification";
import ForgotPassword from "./Templates/ForgotPassword";
import Login from "./Templates/Login";
import ResetPassword from "./Templates/ResetPassword";
import Signup from "./Templates/Signup";
import JobsList from "./Templates/JobsList";
import HeaderLogin from "../../components/orgnisms/HeaderLogin";

export default function AuthStack() {
  return (
    <Switch>
      <Route path="/jobs/:id" exact>
        <JobsList />
      </Route>
      <Route path="/" exact>
        <HeaderLogin />
        <Signup />
      </Route>
      <Route path="/login" exact>
        <HeaderLogin />
        <Login />
      </Route>
      <Route path="/forgot-password" exact>
        <HeaderLogin />
        <ForgotPassword />
      </Route>
      <Route path="/email-verification" exact>
        <HeaderLogin />
        <EmailVerification />
      </Route>
      <Route path="/reset-password" exact>
        <HeaderLogin />
        <ResetPassword />
      </Route>
      <Route path="/sent">
        <HeaderLogin />
        <ResetPasswordModal
          heading="Reset link sent!"
          sub="Password reset link has been sent to"
        />
      </Route>
      <Route path="/success">
        <HeaderLogin />
        <SuccessModal
          heading="Your password has been reset!"
          sub="You can now log into your account with the new password."
        />
      </Route>
      <Route>
        <HeaderLogin />
        <Redirect to="/" />
      </Route>
    </Switch>
  );
}
