import React, { useState, useEffect } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import { useMediaQuery } from "react-responsive";
import { Avatar } from "@material-ui/core";
import { useQuery, gql } from "@apollo/client";
import { useLocation } from "react-router-dom";

import { fire } from "../../firebase";
import ProfileImage from "./templates/ProfileImage";
import Basic from "./templates/Basic";
import Social from "./templates/Social";
import Image from "./templates/Image";
import Welcome from "../../components/orgnisms/Welcome";
import AppText from "../../components/atoms/AppText";
import AppStepper from "../../components/atoms/AppStepper";
import { randomColor, returnInitals } from "../../utils/functions/index";
import { AccountSetupContextProvider } from "../../Context/AccountSetupContext";

const GET_CURRENT_MANAGER = gql`
  query ($uid: String!) {
    getCurrentManager(uid: $uid) {
      avatarUrl
      fullName
      email
      firstName
      lastName
      country
      city
    }
  }
`;

export default function SetupStack() {
  const isDesktop = useMediaQuery({ minWidth: 992 });
  const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 991 });
  const [step, setStep] = useState(1);
  const [avatarUrl, setAvatarUrl] = useState("");
  const { pathname } = useLocation();

  const { displayName, uid } = fire.auth().currentUser;
  const { loading, data } = useQuery(GET_CURRENT_MANAGER, {
    variables: { uid: uid },
  });

  const steps = [
    { name: "Basic Information" },
    { name: "Social Links" },
    { name: "Company Image" },
  ];

  const handleNext = (value) => {
    setStep(value);
  };

  const handleBack = (value) => {
    setStep(value);
  };

  useEffect(() => {
    if (!loading && data) {
      setAvatarUrl(data?.getCurrentManager?.avatarUrl);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading, data]);
  return (
    <div className="_bg">
      <div className="fixed-header">
        <Welcome>
          <div className={isDesktop || isTablet ? "mr-4" : "mr-2"}>
            <Avatar
              style={{
                height: "60px",
                width: "60px",
                backgroundColor: randomColor(displayName),
              }}
              src={avatarUrl}
            >
              <AppText weight="_bold" color="_c0">
                {returnInitals(displayName)}
              </AppText>
            </Avatar>
          </div>
          <div>
            <AppText size={isDesktop || isTablet ? "_22" : "_18"}>
              Hi there {displayName}
            </AppText>
            <div>
              <AppText
                size={isDesktop || isTablet ? "_16" : "_14"}
                color="_c50"
              >
                Welcome to Tap Recruit. Setup your company profile
              </AppText>
            </div>
          </div>
        </Welcome>
      </div>
      <div className={`d-flex pt-4 ovContainer justify-content-center`}>
        <div
          className={`${
            isDesktop
              ? "w-70 flex-row"
              : isTablet
              ? "w-60 flex-column"
              : "w-90 flex-column"
          } d-flex`}
        >
          {pathname !== "/" ? (
            <>
              <div className={`${isDesktop ? "w-10 position-fixed" : "w-100"}`}>
                <div className="free-space" />
                {isDesktop && <div className="mt-4" />}
                <div
                  className={`${
                    isDesktop
                      ? ""
                      : isTablet
                      ? "d-flex _mobi-line px-3 tabtop pt-2"
                      : "d-flex _mobi-line px-3 pt-2"
                  }`}
                >
                  <AppStepper steps={steps} active={step} />
                </div>
              </div>
              {isDesktop && <div className="w-30" />}
            </>
          ) : null}
          <div
            className={`${
              isDesktop ? (pathname === "/" ? "w-100" : "w-70") : "w-100"
            }`}
          >
            {isDesktop && <div className="free-space" />}
            {!isDesktop && <div className="mt-5" />}
            <AccountSetupContextProvider>
              <Switch>
                <Route path="/" exact>
                  <ProfileImage
                    onChange={(value) => {
                      setAvatarUrl(value);
                    }}
                    avatarUrl={avatarUrl}
                    displayName={displayName}
                    uid={uid}
                    onNext={handleNext}
                  />
                </Route>
                <Route path="/basic">
                  <Basic onNext={handleNext} step={step} />
                </Route>
                <Route path="/social">
                  <Social onNext={handleNext} onBack={handleBack} step={step} />
                </Route>
                <Route path="/image">
                  <Image onNext={handleNext} onBack={handleBack} step={step} />
                </Route>
                <Route>
                  <Redirect to="/" />
                </Route>
              </Switch>
            </AccountSetupContextProvider>
          </div>
        </div>
      </div>
    </div>
  );
}
