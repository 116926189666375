import React from "react";
import AppText from "../atoms/AppText";
import moment from "moment";
import numbro from "numbro";
import { textCut } from "../../utils/functions/index";
import { closeDate } from "../../utils/functions/index"

export default function JobsListExternal({ job, moreInfo = false }) {
  const isClosing = closeDate(parseInt(job.closingDate));

  const handleOnClick = () => {
    window.open(`https://stage.taprecruit.co.uk/job/${job.id}`, "_blank");
  };

  return (
    <div className={`d-flex p-3 pointer _jobListExternal`}>
      <div className={"d-flex flex-column w-100"} onClick={handleOnClick}>
        <div className={"d-flex justify-content-between align-items-center"}>
          <div className={"d-flex flex-column"}>
            <AppText size='_16' weight='_medium'>
              {job?.jobTitle}
            </AppText>
            <AppText size='_12' weight='_small' color='_c25'>
              {job?.location} - Posted {textCut(moment(parseInt(job?.createdAt)).fromNow(), 14)}
            </AppText>
          </div>
          {!isClosing ? null : (
            <span className={"ms-1 d-flex mr-2"}>
              <AppText size="_14" color={"_o100"}>
                {isClosing}
              </AppText>
            </span>
          )}
        </div>
        <div className='_sep_ mt-3 mb-3' />
        <div className={"d-flex"}>
          <div className={"d-flex flex-column mr-4"}>
            <AppText size='_12' weight='_small' color='_c25'>
              Experience Level
            </AppText>
            <AppText size='_12' weight='_medium'>
              {job?.experienceLevel}
            </AppText>
          </div>
          <div className={"d-flex flex-column mr-4 "}>
            <AppText size='_12' weight='_small' color='_c25'>
              Employment Type
            </AppText>
            <AppText size='_12' weight='_medium'>
              {job?.employmentType}
            </AppText>
          </div>
          <div className={"d-flex flex-column mr-4"}>
            <AppText size='_12' weight='_small' color='_c25'>
              Salary Range
            </AppText>
            <div className={`d-flex align-items-center ${job?.salaryRange?.start === 0 ? "justify-content-center" : ""}`}>
              {job?.salaryRange?.defaultType !== 'hourly' ? (
                <AppText size='_12' weight={'_medium'}>
                  £
                  {job?.salaryRange?.start !== 0 ?
                    (job?.salaryRange?.start < 1000 ?
                      numbro(job?.salaryRange?.start).format({ mantissa: 2 }) :
                      numbro(job?.salaryRange?.start).format({ thousandSeparated: true, average: true, })
                    ) : null
                  }
                  {job?.salaryRange?.start !== 0 ? " - £" : ""}
                  {job?.salaryRange?.end < 1000 ?
                    numbro(job?.salaryRange?.end).format({ mantissa: 2 }) :
                    numbro(job?.salaryRange?.end).format({
                      thousandSeparated: true,
                      average: true,
                    })}
                </AppText>
              ) : (
                <AppText size='_12' weight={'_medium'}>
                  £
                  {job?.salaryRange?.end < 1000 ?
                    numbro(job?.salaryRange?.end).format({ mantissa: 2 }) :
                    numbro(job?.salaryRange?.end).format({
                      thousandSeparated: true,
                      average: true,
                    })}/hr
                </AppText>
              )
              }
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}