import firebase from "firebase/app";
import "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyAhNOPTpodCd1SUvGQ1ukGkYDQ5_S2SJ-Y",
  authDomain: "tap-recruitment.firebaseapp.com",
  projectId: "tap-recruitment",
  storageBucket: "tap-recruitment.appspot.com",
  messagingSenderId: "305764880730",
  appId: "1:305764880730:web:90d6a46070461f5cbe2363",
};
export const fire = firebase;
firebase.initializeApp(firebaseConfig);
