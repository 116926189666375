import React from 'react';
import { Link } from 'react-router-dom';
import AppText from '../../../components/atoms/AppText';
import AppButton from '../../../components/molecules/AppButton';
import WelcomeBullet from '../../../components/molecules/WelcomeBullet';

export default function Welcome() {
    return (
        <div className="w-100 _bg d-flex justify-content-center align-items-center mt-5">
            <div className=" card_style d-flex w-57">
                <div 
                className={'d-flex flex-column align-items-center h-100 p-5 w-50'}
                >
                    <img alt={"welcomen"} src='/Unified-Experience.svg' className={'mb-5'}></img>
                    <AppText size="_30" weight="_medium">Welcome!</AppText>
                    <AppText size="_16" color={'_c90'} align={'_center'}>Get started with recruiting by creating your company's first job post.</AppText>
                </div>
                <div style={{ borderRight: "1.5px solid #eee", width:'1px' }}></div>
                <div className={'d-flex flex-column align-items-center h-100 px-5 py-4 w-50'}>
                    <AppText size="_16" color={'_c50'}>After creating your first job post, we will provide your company with some tools to help make the recruitment process easier.!</AppText>
                    <WelcomeBullet
                        title={'Analytics'}
                        text={'Track and monitor reports about job posts to improve your recruiting process'}
                        icon={'Pie'}
                    />
                    <WelcomeBullet
                        title={'In-App Messaging'}
                        text={'Instantly send direct messages to job candidates.'}
                        icon={'Forum'}
                    />
                    <WelcomeBullet
                        title={'Calendar'}
                        text={'See your interview schedules with potential employees.'}
                        icon={'Calendar'}
                    />
                    <div className={'mt-4 w-100 d-flex align-items-center'}>
                        <AppButton width='_full'> 
                            <Link to="/create-job">
                                <AppText color="_c0" size="_14" weight="_medium"> Create your first job</AppText>
                            </Link>
                        </AppButton>
                    </div>

                </div>
            </div>

        </div>
    );
}