import React, { useState } from "react";
import { TextField, makeStyles, InputAdornment, IconButton } from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";

export default function SearchInput({ onChange, value, name, onBlur, placeholder, onKeyUp, onClick }) {
  const [placeholderValue, isPlaceholderValue] = useState(placeholder);

  const handleOnFocus = () => {
    isPlaceholderValue("");
  };

  const handleOnBlur = () => {
    isPlaceholderValue(placeholder);
  };
  // const isDesktop = useMediaQuery({ minWidth: 992 });
  // const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 991 });
  const useSyles = makeStyles({
    label: { marginBottom: "10px" },
    root: {
      width: "100%",
      "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
        border: "none",
      },
      "& .MuiOutlinedInput-notchedOutline": {
        border: "none",
        maxHeight: "38px",
      },
    },
    input: {
      WebkitAppearance: "none",
      MozAppearance: "none",
      appearance: "none",
      fontFamily: "Roboto",
      marginTop: "-2px",
      height: "38px",
      color: "#3E3F42",
      border: "1px solid #D8DCE6",
      borderRadius: "4px",
    },
  });
  const classes = useSyles();

  return (
    <TextField
      variant='outlined'
      fullWidth
      name={name}
      value={value}
      onFocus={handleOnFocus}
      onBlur={handleOnBlur}
      onChange={onChange}
      className={classes.root}
      InputProps={{
        endAdornment: (
          <InputAdornment position='end'>
            <IconButton onClick={onClick} className={'d-flex align-items-center'} style={{ height: '32px', width: '32px' }}>
              <SearchIcon style={{ color: "#c9c9c9", fontSize: 20 }} />
            </IconButton>
          </InputAdornment>
        ),
        className: classes.input,
      }}
      placeholder={placeholderValue}
      onKeyUp={onKeyUp}
      size='small'
    />
  );
}
