import { TextField, makeStyles, InputAdornment } from "@material-ui/core";
import React from "react";
import AppText from "./AppText";

export default function AppInputIcon({ placeholder, value, onChange, name, formik }) {

  const useSyles = makeStyles({
    label: { marginBottom: "10px" },
    root: {
      width: "100%",
      backgroundColor: "#fff",
      "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
        border: "none",
      },
      "& .MuiOutlinedInput-notchedOutline": {
        border: "none",
        maxHeight: "38px",
      },
    },
    input: {
      WebkitAppearance: "none",
      MozAppearance: "none",
      appearance: "none",
      fontFamily: "Roboto",
      marginTop: "-2px",
      height: "39px",
      color: "#3E3F42",
      border: "1px solid #D8DCE6",
      borderRadius: "4px",
      boxShadow: "0 1px 2px #00000014",
      fontSize: 14,
    },
  });
  const classes = useSyles();

  return (
    <TextField
      variant='outlined'
      fullWidth
      name={name}
      value={value}
      onBlur={formik?.handleBlur(name || "")}
      onChange={onChange}
      className={classes.root}
      autoComplete='off'
      InputProps={{
        startAdornment: (
          <InputAdornment position='start'>
            <AppText size='_14' color='_c50'>
              £
            </AppText>
          </InputAdornment>
        ),
        className: classes.input,
      }}
      placeholder={placeholder}
      size='small'
    />
  );
}
