import React from 'react'
import AppText from '../atoms/AppText'
import AppButton from '../molecules/AppButton'


export default function HeaderContainer({ title, onClick, buttonTitle }) {
    return (
        <div>
            <div className={'d-flex justify-content-between p-3 pb-2 pt-2 align-items-center'}>
                <AppText size='_16' weight='_medium' >{title}</AppText>
                <AppButton color='_white' onClick={onClick}>
                    <AppText size='_14'>{buttonTitle}</AppText>
                </AppButton>
            </div>
            <div className='_sep_' />
        </div>
    )
}