import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useFormik } from "formik";
import CircularProgress from "@material-ui/core/CircularProgress";
import { fire } from "../../../firebase";

import AppText from "../../../components/atoms/AppText";
import AppInput from "../../../components/molecules/AppInput";
import AppPassword from "../../../components/molecules/AppPassword";
import AppButton from "../../../components/molecules/AppButton";
import { LoginShema } from '../../../utils/schema/login'

export default function Login() {

  const [hasError, setHasError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: LoginShema,
    onSubmit: async (values, { resetForm }) => {
      try {
        setIsLoading(true)
        const { email, password } = values;
        const results = await fire.auth().signInWithEmailAndPassword(email, password);
        if (results) {
          resetForm();
          setHasError(false)
        }

      } catch (e) {
        if (e) {
          setIsLoading(false)
          setHasError(true)
        }
      }
    },
  });

  return (
    <div>
      <div className='d-flex flex-column justify-content-center align-items-center background'>
        <div className={`d-flex flex-wrap w-60`}>
          <div className={` w-45 app-box`}>
            <div className='p-4'>
              <AppText size='_22'>Welcome back!</AppText>
              <div>
                <AppText size='_16' color='_c50'>
                  Log into your account
                </AppText>
              </div>

              <div className='mt-4' />
              <form onSubmit={formik.handleSubmit}>
                <div>
                  <AppInput
                    label='EMAIL ADDRESS'
                    placeholder='Email Address'
                    name='email'
                    value={formik.values.email}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                  <div>
                    {formik.touched.email && formik.errors.email ? (
                      <AppText size='_12' color='_r100'>
                        {formik.errors.email}
                      </AppText>
                    ) : null}
                  </div>
                </div>
                <div className='mt-3'>
                  <AppPassword
                    label='PASSWORD'
                    placeholder='Password'
                    name='password'
                    value={formik.values.password}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                  <div>
                    {formik.touched.password && formik.errors.password ? (
                      <AppText size='_12' color='_r100'>
                        {formik.errors.password}
                      </AppText>
                    ) : null}
                  </div>
                </div>
                <div className='mt-1'>
                  {hasError && (
                    <AppText color='_r100' size='_12'>
                      Email or Password is wrong!
                    </AppText>
                  )}
                </div>
                <div className='mt-4'>
                  <AppButton width='_full' type='submit'>
                    {isLoading ? (
                      <CircularProgress style={{ color: "#fff", height: "30px", width: "30px" }} />
                    ) : (
                      <AppText color='_c0' size='_14'>
                        Log In
                      </AppText>
                    )}
                  </AppButton>
                </div>
              </form>
            </div>
          </div>

          <div className='w-55 img-right'>
            <div className='d-flex flex-column justify-content-end pl-5 pb-4 h-100 pt-4' style={{ backgroundColor: "#FEEBD2" }}>
              <img src="ill.svg" alt="ill" height="300" className={'mb-4'} />
              <AppText size='_22' weight="_medium">
                Providing a unified recruitment experience
              </AppText>
            </div>
          </div>
          <div className='mt-4 pl-4'>
            <AppText size='_14' color='_c50'>
              Don't have an account?
              <Link to='/'>
                <span className='_g90 ml-1'>Create an Account</span>
              </Link>
            </AppText>
            <div className='mt-2'>
              <AppText size='_14' color='_c50'>
                Forgot your password?
                <Link to='/forgot-password'>
                  <span className='_g90 ml-1'>Request a new password</span>
                </Link>
              </AppText>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
