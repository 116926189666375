import React from 'react'
import AppText from '../atoms/AppText'

export default function JobFilter({ text, isSelect, onClick, number = 0, optKey, jobsPage = false }) {
    return (
        <header className={`chat_header d-flex align-items-center ${jobsPage ? 'px-2' : 'px-4'} pointer`} onClick={onClick}>
            <div className={`${optKey === isSelect ? '_cactive' : ''} d-flex align-items-center mr-2`}>
                <AppText size='_14' weight='_medium'>
                    {text}
                </AppText>
                {number > 0 ? (
                    <div className={'rounded-pill pl-2 pr-2 ml-2 d-flex align-items-center'} style={optKey === isSelect ? { backgroundColor: '#f2fcfb' } : { backgroundColor: '#eeeeee' }}>
                        <AppText size='_12' weight='_medium' color={optKey === isSelect ? '_g90' : ''}>
                            {number}
                        </AppText>
                    </div>
                ) : (
                    null
                )}
            </div>
        </header>
    )
}
