import React from 'react';
import AppText from '../atoms/AppText';
import PieChartOutlinedIcon from '@material-ui/icons/PieChartOutlined';
import ForumOutlinedIcon from '@material-ui/icons/ForumOutlined';
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';

export default function Welcome({ title, text, icon }) {
    return (
        <div className="pt-4 w-100 d-flex ">
            {(icon === 'Pie') && (
                <PieChartOutlinedIcon style={{ color: '#03CA98', fontSize: 16, marginRight: '5px', marginTop: '4px' }} />
            )}
            {(icon === 'Forum') && (
                <ForumOutlinedIcon style={{ color: '#03CA98', fontSize: 16, marginRight: '5px', marginTop: '4px' }} />
            )}
            {(icon === 'Calendar') && (
                <CalendarTodayIcon style={{ color: '#03CA98', fontSize: 16, marginRight: '5px', marginTop: '4px' }} />
            )}
            <div className={'d-flex flex-column'}>
                <AppText size="_16" color={'_c90'}>{title}</AppText>
                <AppText size="_16" color={'_c50'}>{text}</AppText>
            </div>
        </div>
    );
}