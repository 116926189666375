import React, { useEffect } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import CreateJob from "./templates/CreateJob";
import { useQuery, gql } from "@apollo/client";
import Dashboard from "./templates/Dashboard";
import Interviews from "./templates/Interviews";
import Jobs from "./templates/Jobs";
import { useSelector } from "react-redux";
import PostJob from "./templates/PostJob";
import ManageAccount from "./templates/ManageAccount";
import Messages from "./templates/Messages";
import Overview from "./templates/Overview";
import Profile from "./templates/Profile";
import CandidateProfile from "./templates/CandidateProfile";
import AppHeader from "../../components/orgnisms/AppHeader";
import HeaderLogin from "../../components/orgnisms/HeaderLogin";
import Welcome from "./templates/Welcome";
import Loading from "../../components/orgnisms/Loading";

const GET_MY_JOB_STATS = gql`
  query ($input: AccessInput) {
    getMyJobStats(input: $input) {
      jobs
      views
      applications
      shortlist
    }
  }
`;

export default function HomeStack() {
  const { user } = useSelector((state) => state.app);

  const { loading, data: jobsData } = useQuery(GET_MY_JOB_STATS, {
    variables: {
      input: {
        companyId: user?.roles[0].company?.id,
        managerId: user.id,
        AccessPrilivages: user?.roles[0].as,
      },
    },
    fetchPolicy: "no-cache",
    pollInterval: 2000,
  });

  useEffect(() => {}, [loading, jobsData]);

  if (!loading) {
    if (jobsData === undefined) {
      return (
        <Switch>
          <Route path="/" exact>
            <AppHeader starter={true} />
            <Welcome />
          </Route>
          <Route path="/manage-account">
            <AppHeader starter={true} />
            <ManageAccount />
          </Route>
          <Route path="/profile">
            <AppHeader starter={true} />
            <Profile />
          </Route>
          <Route path="/create-job">
            <HeaderLogin />
            <CreateJob />
          </Route>
          <Route path="/job/:id">
            <AppHeader />
            <PostJob />
          </Route>
          <Route>
            <Redirect to="/" />
          </Route>
        </Switch>
      );
    } else {
      return (
        <Switch>
          <Route path="/" exact>
            <AppHeader />
            <Overview />
          </Route>
          <Route path="/jobs">
            <AppHeader />
            <Jobs />
          </Route>
          <Route path="/job/:id">
            <AppHeader />
            <PostJob />
          </Route>
          <Route path="/dashboard">
            <AppHeader />
            <Dashboard />
          </Route>
          <Route path="/interviews">
            <AppHeader />
            <Interviews />
          </Route>
          <Route path="/messages">
            <AppHeader />
            <Messages />
          </Route>
          <Route path="/manage-account">
            <AppHeader />
            <ManageAccount />
          </Route>
          <Route path="/profile">
            <AppHeader />
            <Profile />
          </Route>
          <Route path="/candidate/:id">
            <AppHeader />
            <CandidateProfile />
          </Route>
          <Route path="/create-job">
            <HeaderLogin />
            <CreateJob />
          </Route>
          <Route>
            <Redirect to="/" />
          </Route>
        </Switch>
      );
    }
  }
  return (
    <div>
      <AppHeader starter={true} />
      <Loading />
    </div>
  );
}
