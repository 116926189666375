import React, { useState } from "react";
import AWS from "aws-sdk";
import { useMutation, gql } from "@apollo/client";
import { uid } from "uid";
import { useSelector } from "react-redux";
import ModalFooter from "../molecules/ModalFooter";
import { useAccountSetup } from "../../Context/AccountSetupContext";

const UPDATE_HEADER = gql`
  mutation ($input: _CompanyInputUpdate) {
    updateCompany(input: $input) {
      logo
    }
  }
`;

export default function ImageUploadLogo({ temp, onClose, blob }) {
  const { accountSetup, setAccountSetup } = useAccountSetup();

  const [updateHeader] = useMutation(UPDATE_HEADER);

  const [isLoading, setIsLoading] = useState(false);
  const { user } = useSelector((state) => state.app);

  const onSave = async () => {
    setIsLoading(true);
    const s3Bucket = new AWS.S3({
      accessKeyId: "AKIAJ3P5KOR27P6EV5MA",
      secretAccessKey: "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",
      region: "eu-west-2",
    });
    const params = {
      Bucket: "tapobucket",
      Key: `${uid(10)}.jpg`,
      Body: blob,
      ContentDisposition: "attachment",
    };
    const upload = s3Bucket.upload(params);

    upload.send((err, data) => {
      if (err) console.log(err);

      updateHeader({
        variables: {
          input: {
            companyId:
              (user && user?.roles[0].company.id) || accountSetup?.basic?.id,
            values: { logo: data.Location },
          },
        },
      }).then(({ data }) => {
        setAccountSetup({
          ...accountSetup,
          companyLogo: data.updateCompany.logo,
        });
        setIsLoading(false);
        onClose();
      });
    });
  };

  return (
    <div className="h-100">
      <div className="d-flex justify-content-center" style={{ maxHeight: 459 }}>
        <img alt="ss" src={temp} style={{ width: "50%" }} />
      </div>
      <ModalFooter
        onClose={onClose}
        isLoading={isLoading}
        hasErrors={false}
        onSave={onSave}
      />
    </div>
  );
}
