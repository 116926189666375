import React, { useState } from "react";
import AppText from "../atoms/AppText";
import Model from "../molecules/Model";
import AppInput from "../molecules/AppInput"
import { useFormik } from "formik";
import * as Yup from "yup";
import AppButton from "../molecules/AppButton";
import CheckRoundedIcon from '@material-ui/icons/CheckRounded';

export default function ModalInvitation({ title, onSend, onClose }) {
    const [emailSend, setEmailSend] = useState(false)
    const formik = useFormik({
        initialValues: {
            email: ""
        },
        validationSchema: Yup.object().shape({
            email: Yup.string().email("Email should be valid").required("Email is required"),
        }),
        onSubmit: (values) => {
            onSend(values.email)
            setEmailSend(true)
        },
    });

    const handleSubmit = () => {
        formik.submitForm()
    }

    const handleOnClose = () => {
        setEmailSend(false)
        onClose()
    }

    return (
        <>
            {!emailSend ? (
                <Model
                    title={title}
                    onClose={handleOnClose}
                    width="30%"
                >
                    <div className='_sep_ shadow-sm' />
                    <form className={'p-3 d-flex flex-column mb-3 px-3'}>
                        <AppInput
                            label='EMAIL ADDRESS'
                            placeholder='Email Address'
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.email}
                            name='email'
                            hasError={formik.touched.email && formik.errors.email ? true : false}
                        />
                        {formik.touched.email && formik.errors.email && (
                            <AppText size='_12' color='_r100'>
                                {formik.errors.email}
                            </AppText>
                        )}
                        <span className={'mt-2'}>
                            <AppText size='_12' color='_c50'>
                                An invitation will be sent to their email and they will be asked to create an account.
                                You will be notified via email when they do so.
                    </AppText>
                        </span>
                    </form>
                    <div className='_sep_ shadow-sm' />
                    {/* Footer */}
                    <footer className={'d-flex justify-content-end p-3'} style={{ backgroundColor: '#FBFBFD' }}>
                        <span className={'mr-3'}>
                            <AppButton
                                color='_white'
                                style={{ width: '70px' }}
                                width={'_custom'} onClick={onClose}
                            >
                                <AppText size='_14'>Cancel</AppText>
                            </AppButton>
                        </span>
                        <AppButton
                            style={{ width: '70px' }}
                            width={'_custom'} onClick={handleSubmit}
                        >
                            <AppText size='_14' color={'_c0'}>Add</AppText>
                        </AppButton>
                    </footer>
                </Model>
            ) :
                <Model
                    onClose={handleOnClose}
                >
                    <div className={'d-flex flex-column align-items-center w-100 mb-5 pb-2 px-4'}>
                        <span
                            style={{
                                borderRadius: "100%",
                                border: "2px solid #03CA9880",
                                height: '56px',
                                width: "56px",
                                background: "#03CA980D"
                            }}
                            className={'d-flex justify-content-center align-items-center mb-3'}
                        >
                            <CheckRoundedIcon
                                style={{ color: "#62cbb1", fontSize: "24px" }} />
                        </span>
                        <AppText size='_20' width={'_medium'}>Invitation sent!</AppText>
                        <AppText size='_14'>
                            An account creation link has been sent to
                        </AppText>
                        <AppText size='14' color='_g90'>
                            {formik.values.email}
                        </AppText>
                    </div>
                </Model>
            }
        </>
    );
}