import React, { useEffect, useState } from "react";
import { Avatar, Checkbox } from "@material-ui/core";
import { gql, useMutation } from "@apollo/client";
import moment from "moment";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';

import AppText from "../atoms/AppText";
import MatchJob from "../atoms/MatchJob";
import AppDropDownButton from "../molecules/AppDropDownButton";
import { UpperCaseFirst } from "../../utils/functions";
import AppButton from "../molecules/AppButton";
import { MailOutline } from "@material-ui/icons";

const UPDATE_JOB_STATUS = gql`
  mutation ($input: UpdateStatusInput) {
    updateApplicationStatus(input: $input)
  }
`;
const SEND_STATUS_NOTIFICATION = gql`
  mutation ($input: NoticationInput) {
    sendNotification(input: $input) {
      id
    }
  }
`;

const START_CHAT = gql`
mutation($input: ChatInput) {
  openChat(input: $input)
}
`

export default function Candidates({ candidate, isLastOne, refetch, job, onClick, selectedCandidate }) {
  const [isCheck, setIsCheck] = useState(false);
  const [skills] = useState(candidate.candidate.skills.sort((a,b) => (a.label > b.label) ? 1 : ((b.label > a.label) ? -1 : 0)))
  const { user } = useSelector((state) => state.app)
  const [showMore, setShowMore] = useState(false)
  const history = useHistory();

  const [updateApplicationStatus] = useMutation(UPDATE_JOB_STATUS);
  const [sendStatusNotification] = useMutation(SEND_STATUS_NOTIFICATION);
  const [startChat] = useMutation(START_CHAT)

  const [buttonOption, setButtonOption] = useState(candidate?.status === 1 ?
    "Change Status" :
    candidate?.status === 2 ? "Interview" :
      candidate?.status === 3 ? "Offer" : "Unsuccessful")

  const onChageButton = (option) => {
    const { id } = candidate;
    onClick(candidate, true)
    updateApplicationStatus({ variables: { input: { id, status: option.value } } }).then(() => {
      sendStatusNotification({
        variables: {
          input: {
            string: `${user.roles[0].company.name} changed the status of your application`,
            action: 3,
            toUser: candidate.candidate.id,
            senderFullName: user.fullName,
            senderPhotoUrl: user.AvatarUrl || "",
          },
        },
      });
      refetch({ variables: { input: job.id } });
    });
  };

  const { candidate: seeker } = candidate;
  const [workHere] = useState(seeker.experience.filter((el) => el.workHere === true))

  const handleCandidateProfile = (() => {
    history.push(`/candidate/${candidate.candidate.id}`, { job, candidate: candidate.candidate });
  })

  const openChat = () => {
    startChat({ variables: { input: { seeker: candidate.candidate.id, manager: user.id } } }).then(({ data }) => {
      history.push(`/messages/${data.openChat}`, {
        id: data.openChat, seeker: {
          id: candidate.candidate.id,
          firstName: candidate.candidate.firstName,
          lastName: candidate.candidate.lastName,
          photoUrl: candidate.candidate.photoUrl
        }
      })
    })
  }

  const handleClick = () => {
    onClick(candidate, isCheck)
    setIsCheck(!isCheck)
  }

  useEffect(() => {
    setIsCheck(selectedCandidate.filter((item) => candidate.candidate.id === item.candidate.id).length > 0 ? true : false)
    setButtonOption(candidate?.status === 1 ?
      "Change Status" :
      candidate?.status === 2 ? "Interview" :
        candidate?.status === 3 ? "Offer" : "Unsuccessful")
  }, [selectedCandidate, candidate])

  return (
    <div className={"d-flex"}>
      <div className={"pt-5"}>
        <Checkbox
          name='checkBox'
          value='checkBox'
          checked={isCheck}
          onChange={handleClick}
          icon={<CheckBoxOutlineBlankIcon style={{ color: "#D8DCE6" }} />}
          checkedIcon={<CheckBoxIcon style={{ color: "#03CA98" }} />}
          style={{
            color: "#eeeeee",
            padding: 0,
          }}
        />
      </div>
      <div className={`w-100 px-3 pb-4 ${!isLastOne ? "botm" : ""}`}>
        <div className={`pt-4 d-flex justify-content-between`}>
          <div className='d-flex w-100'>
            <div className='w-15'>
              <Avatar
                sizes='large'
                className={"mr-3"}
                src={seeker?.photoUrl || null}
                style={{ height: "70px", width: "70px" }}
              ></Avatar>
            </div>
            <div className='w-85 d-flex justify-content-between'>
              <div>
                <span onClick={handleCandidateProfile} style={{ cursor: 'pointer' }}>
                  <AppText size='_14' weight='_medium' >
                    {UpperCaseFirst(seeker.firstName)} {UpperCaseFirst(seeker.lastName)}
                  </AppText>
                </span>
                <div>
                  <div>
                    {seeker.experience
                      .filter((el) => el.workHere === true)
                      .map((ex) => (
                        <AppText size='_12' weight='_small' color='_c25' key={'role'}>
                          {ex.myRole} at {ex.company.name}
                        </AppText>
                      ))}
                  </div>
                  <AppText size='_12' weight='_small' color='_c25'>
                    {seeker.city}, {seeker.country}
                  </AppText>
                </div>
              </div>

              <div className='d-flex'>
                <AppDropDownButton
                  background="#03CA98"
                  onChange={(value) => onChageButton(value)}
                  buttonOption={buttonOption}
                  buttonOptions={[
                    { label: "Interview", value: 2 },
                    { label: "Offer", value: 3 },
                    { label: "Unsuccessful", value: 4 },
                  ]}
                />
                <div className="px-2" />
                <AppButton height="37px" color="_white" onClick={openChat}>
                  <MailOutline style={{ fontSize: "19px", marginRight: "0.4rem" }} />
                  <AppText size="_14">Message</AppText>
                </AppButton>
              </div>
            </div>
          </div>
        </div>
        <div className={"w-100"}>
          <div className='w-100 mt-3'>
            {(workHere.length > 0) && (
              <div className='w-100 d-flex'>
                <div className={"d-flex justify-content-end pr-2 w-15"}>
                  <AppText size='_12' weight='_small' color='_c25'>
                    CURRENT
                  </AppText>
                </div>
                <div className={"d-flex flex-column justify-content-start"}>
                  {seeker?.experience
                    ?.filter((el) => el.workHere === true)
                    .sort((a, b) => a.startDate > b.startDate ? -1 : a.startDate < b.startDate ? 1 : 0)
                    .map((exp) => (
                      <AppText size='_12' weight='_medium' key={exp.myRole}>
                        {exp.myRole}{" "}
                        <AppText size='_12' weight='_small' color='_c25'>
                          {moment(exp.startDate).format("MMM YYYY")} -{" "}
                          {exp.workHere ? "Present " : moment(exp.endDate).format("MMM YYYY")}
                        </AppText>
                      </AppText>
                    ))}
                </div>
              </div>
            )}
          </div>
        </div>
        <div className={"w-100"}>
          <div className='w-100 mt-2'>
            {(seeker.experience.filter((el) => el.workHere === false).length > 0) && (
              <div className='w-100 d-flex align-items-start'>
                <div className={"d-flex justify-content-end pr-2 w-15"}>
                  <AppText size='_12' weight='_small' color='_c25'>
                    PAST
                  </AppText>
                </div>
                <div className={"d-flex flex-column justify-content-start"}>
                  {seeker.experience
                    .filter((el) => el.workHere === false)
                    .sort((a, b) => a.startDate > b.startDate ? -1 : a.startDate < b.startDate ? 1 : 0)
                    .map((exps) => (
                      <div style={{ marginTop: "-5px" }} key={exps.myRole}>
                        <AppText size='_12' weight='_small'>
                          {exps.myRole}{" "}
                          <AppText size='_12' weight='_small' color='_c25'>
                            {moment(exps.startDate).format("MMM YYYY")} -{" "}
                            {moment(exps.endDate).format("MMM YYYY")}
                          </AppText>
                        </AppText>
                      </div>
                    ))}
                </div>
              </div>
            )}
          </div>
        </div>
        <div className={"w-100"}>
          <div className='w-100 mt-2'>
            {(seeker.education.length > 0) && (
              <div className='w-100 d-flex'>
                <div className={"d-flex justify-content-end pr-2 w-15"}>
                  <AppText size='_12' weight='_small' color='_c25'>
                    EDUCATION
                  </AppText>
                </div>
                <div className={"d-flex flex-column justify-content-start"}>
                  {seeker.education
                    .sort((a, b) => a.startDate > b.startDate ? -1 : a.startDate < b.startDate ? 1 : 0)
                    .map((ed) => (
                      <div style={{ marginTop: "-5px" }} key={ed.degree}>
                        <AppText size='_12' weight='_small'>
                          {ed.degree} at {ed.school}{" "}
                          <AppText size='_12' weight='_small' color='_c25'>
                            {moment(ed.startDate).format("MMM YYYY")} -{" "}
                            {moment(ed.endDate).format("MMM YYYY")}
                          </AppText>
                        </AppText>
                      </div>
                    ))}
                </div>
              </div>
            )}
          </div>
        </div>
        <div className={"w-70"}>
          {skills.length > 0 ? (
            <>
              {/* <span className={'ml-5 mt-2'}>
                <AppText size='_14' weight='_medium' color={'_c90'}>Matches to job post</AppText>
              </span> */}
              <div className='d-flex flex-wrap ml-5'>
                {skills.map((item, index) => {
                  if (index < 6) {
                    return (
                      <MatchJob type={'Skills'} value={item.label}></MatchJob>
                    )
                  } else if (showMore) {
                    return (
                      <MatchJob type={'Skills'} value={item.label}></MatchJob>
                    )
                  }
                  return null
                })}
                {(!showMore && skills.length > 6) ? (
                  <span
                    className={'pointer d-flex justify-content-center align-items-center mt-2'}
                    onClick={() => setShowMore(true)}
                  >
                    <AppText color={'_g90'} size={'_14'}> View more</AppText>
                  </span>
                ) : null}
                {(showMore && skills.length > 6) ? (
                  <span
                    className={'pointer d-flex justify-content-center align-items-center mt-2'}
                    onClick={() => setShowMore(false)}
                  >
                    <AppText color={'_g90'} size={'_14'}> Show less</AppText>
                  </span>
                ) : null}
              </div>
            </>
          ) : null}
        </div>
      </div>
    </div>
  );
}
