import React, { useImperativeHandle } from "react";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
import Popover from "@material-ui/core/Popover";
import { makeStyles } from "@material-ui/core"

const useStyles = makeStyles({
  root: {
    "& .MuiPopover-paper": {
      backgroundColor: "#fff",
      boxShadow: "0 1px 2px #00000014!important",
      border: "1px solid #D8DCE6",
    }
  }
})

const Appddown = React.forwardRef(
  ({ vertical = "bottom", horizontal = "start", children }, ref) => {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const classes = useStyles()

    const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
      setAnchorEl(null);
    };

    useImperativeHandle(ref, () => ({
      close: () => {
        handleClose()
      }
    }))

    const open = Boolean(anchorEl);
    const id = "simple-popover";

    return (
      <div>
        <span onClick={handleClick}>
        <MoreHorizIcon style={{ color: "#bcbcbc", fontSize: 20, marginRight: "12px" }} />
        </span>
        <Popover
          className={classes.root}
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          onClick={handleClose}
          anchorOrigin={{
            vertical: vertical,
            horizontal: horizontal,
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
        >
          {children}
        </Popover>
      </div>
    );
  }
);

export default Appddown;
