import React from "react";
import AppText from "../atoms/AppText";
import AppButton from "../molecules/AppButton";
import { gql, useMutation } from "@apollo/client";

const START_TRIAL = gql`
  mutation ($companyId: ID!) {
    careerPagesSubscription(companyId: $companyId)
  }
`;

export default function EmbedJobs({ company, careerStatus, refresh }) {
  const [startYourTrial] = useMutation(START_TRIAL);

  const codes = `
    <iframe title="Tap jobs"
            style="height:auto;width:100%;border:none;"
            src="https://iframe-olive.vercel.app/${careerStatus?.getCareerPageStatus?.id}"></iframe>
    `;

  const startTrial = async () => {
    const start = await startYourTrial({
      variables: { companyId: company.id },
    });
    if (start.data.careerPagesSubscription) {
      refresh();
    }
  };

  return (
    <div>
      <div className={"card_style mb-4"}>
        {/* Header */}
        <header className={"d-flex flex-column p-4"}>
          <AppText size="_18" weight="_medium">
            Embed Jobs
          </AppText>
          <AppText size="_14" color={"_c50"}>
            Copy code snippet and paste it anywhere in your code.
          </AppText>
        </header>
        <div className="_sep_" />
        <div className="p-4">
          {careerStatus?.getCareerPageStatus?.status === "trial" ||
          careerStatus?.getCareerPageStatus?.JobsToIframe.length ? (
            <code style={{ cursor: "pointer" }}>{codes}</code>
          ) : careerStatus?.getCareerPageStatus?.status === "expired" ? (
            "Your trial expired"
          ) : (
            <AppButton color="_white" onClick={startTrial}>
              <AppText size="_14">Start your 14days trial</AppText>
            </AppButton>
          )}
        </div>
      </div>
    </div>
  );
}
