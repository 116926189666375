import React, { useState } from "react";
import { Avatar } from "@material-ui/core";
import { useMutation, gql } from "@apollo/client";
import { useHistory } from "react-router-dom";
import { isValidImage, randomColor } from "../../../utils/functions/index";
import DeleteOutlineRoundedIcon from "@material-ui/icons/DeleteOutlineRounded";
import query from "query-string";
import { useDispatch } from "react-redux";

import AppText from "../../../components/atoms/AppText";
import AppButton from "../../../components/molecules/AppButton";
import UploadButton from "../../../components/atoms/UploadButton";
import Model from "../../../components/molecules/Model";
import ProfileUploadModal from "../../../components/orgnisms/ProfileUploadModal";
import { setUser } from "../../ducks";

const UPDATE_USER = gql`
  mutation ($input: UpdateManagerInput) {
    updateManager(input: $input) {
      id
    }
  }
`;

const UPDATE_MANAGER_AFTER = gql`
  mutation ($input: UpdateManagerInput) {
    updateManager(input: $input) {
      id
      emailVerified
      accountComplete
      fullName
      email
      firstName
      lastName
      country
      avatarUrl
      city
      roles {
        company {
          id
          name
          size
          services
          websiteLink
          instagramLink
          facebookLink
          linkedinLink
          twitterLink
          culture
          logo
          header
          founded
          bio
          overview
          location
        }
        as
      }
    }
  }
`;
export default function SetupStack({
  avatarUrl,
  onChange,
  displayName,
  uid,
  onNext,
}) {
  const [temp, setTemp] = useState(null);
  const [previewImage, setPreviewImage] = useState(false);
  const [saveProfileImage] = useMutation(UPDATE_USER);
  const [updateManger] = useMutation(UPDATE_MANAGER_AFTER);

  const qc = query.parse(window.location.search);
  const history = useHistory();
  const dispatch = useDispatch();

  const handleImage = (file) => {
    const { temp } = isValidImage(file[0]);
    setTemp(temp);
    setPreviewImage(true);
  };

  const removePhoto = () => {
    saveProfileImage({ variables: { input: { avatarUrl: null, uid: uid } } });
    onChange("");
  };

  const handleClick = () => {
    if (qc.token && qc.ci) {
      updateManger({
        variables: { input: { uid, accountComplete: true } },
      }).then((newManager) => {
        dispatch(setUser({ ...newManager.data.updateManager }));
      });
    } else {
      onNext(1);
      history.push("/basic");
    }
  };

  return (
    <div className="d-flex justify-content-center w-100">
      <div className="card_style d-flex flex-column w-80">
        {/* Header */}
        <header className={"p-4"}>
          <AppText size="_18" weight="_medium">
            Add a profile photo
          </AppText>
        </header>
        <div className="_sep_" />
        <div className="d-flex flex-row p-4 align-items-center">
          <Avatar
            src={avatarUrl}
            style={{
              height: "80px",
              width: "80px",
              background: randomColor(displayName),
            }}
          />
          {!avatarUrl ? (
            <div className="ml-4">
              <UploadButton
                label="Change Photo"
                showsIcon={false}
                onChange={(file) => handleImage(file)}
              >
                <AppButton color="_white">
                  <AppText size="_14">Upload Photo</AppText>
                </AppButton>
              </UploadButton>
            </div>
          ) : (
            <div className="ml-4">
              <AppButton color="_white" onClick={removePhoto}>
                <span className={"mr-2"}>
                  <DeleteOutlineRoundedIcon
                    style={{ fontSize: "16px", color: "#E6492D" }}
                  />
                </span>
                <AppText color={"_r100"} size="_14">
                  Remove Photo
                </AppText>
              </AppButton>
            </div>
          )}
        </div>
        {/* Footer */}
        <div className="_sep_" />
        <footer
          className={"d-flex justify-content-end p-4"}
          style={{ background: "#FBFBFD" }}
        >
          <AppButton
            color={`_green`}
            style={{ width: "120px" }}
            width={"_custom"}
            // state={avatarUrl ? false : true}
            onClick={handleClick}
          >
            <AppText size="_14" color={`${avatarUrl ? "_c0" : "_c0"}`}>
              Continue
            </AppText>
          </AppButton>
        </footer>
      </div>
      {previewImage && (
        <Model onClose={() => setPreviewImage(false)}>
          <ProfileUploadModal
            temp={temp}
            onClose={() => setPreviewImage(false)}
            isAccountSetup={true}
            onChange={onChange}
          />
        </Model>
      )}
    </div>
  );
}
