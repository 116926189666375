import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { useFormik } from "formik";
import { useSelector } from "react-redux";
import { useMutation, gql } from "@apollo/client";
import { useMediaQuery } from "react-responsive";
import AppInput from "../../../components/molecules/AppInput";
import AppTextArea from "../../../components/molecules/AppTextArea";
import BoardingPanel from "../../../components/orgnisms/BoardingPanel";
import AppDropdown from "../../../components/molecules/AppDropdown";
import AppText from "../../../components/atoms/AppText";
import { basicSchema } from "../../../utils/schema/accountSetupSchema";
import { useAccountSetup } from "../../../Context/AccountSetupContext";
import { fire } from "../../../firebase";
import { addHttp } from '../../../utils/functions/index'
import { industrys as services } from "../../../utils/industrys"

const CREATE_COMPANY = gql`
  mutation($input: _CompanyInput) {
    createCompany(input: $input) { 
      id
    }
  }
`;

const SET_COMPANY_ADMIN = gql`
  mutation($input: Role) {
    addRole(input: $input)
  }
`;

export default function Basic({ onNext, step }) {
  const state = {};
  const [createOrUpdateCompany] = useMutation(CREATE_COMPANY);
  const [setCompanyAdmin] = useMutation(SET_COMPANY_ADMIN);
  const { accountSetup, setAccountSetup } = useAccountSetup();


  const employeesList = ["1 - 4", "5 - 10", "11 - 50", "51 - 99", "100 +"];
  const companyCultureList = ["Formal", "Casual"];
  const monthList = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  const locationList = ["London, United Kingdom"];
  // *****

  const isDesktop = useMediaQuery({ minWidth: 992 });
  const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 991 });

  const [isLoading, setIsLoading] = useState(false);
  const { user } = useSelector((state) => state.app);

  const company = user?.roles[0].company;
  const history = useHistory();

  if (step !== 1) {
    history.push('/')
  }

  const formik = useFormik({
    initialValues: {
      companyName: accountSetup?.basic?.companyName || company?.name || "",
      services: accountSetup?.basic?.services || company?.services || "",
      companyBio: accountSetup?.basic?.companyBio || company?.bio || "",
      companySummary: accountSetup?.basic?.companySummary || company?.overview || "",
      numberEmployees: accountSetup?.basic?.numberEmployees || company?.size || "",
      webLink: accountSetup?.basic?.webLink || company?.websiteLink || "",
      month: accountSetup?.basic?.month || company?.founded.split(",")[0] || "",
      year: accountSetup?.basic?.year.trim() || company?.founded.split(",")[1].trim() || "",
      companyLocation: accountSetup?.basic?.companyLocation || company?.location || "",
      companyCulture: accountSetup?.basic?.companyCulture || company?.culture || "",
    },
    validationSchema: basicSchema,
    onSubmit: (values) => {
      const {
        companyName,
        companyBio,
        companySummary,
        companyCulture,
        companyLocation,
        services,
        webLink,
        month,
        year,
        numberEmployees,
      } = values;

      const basic = {
        name: companyName,
        services,
        bio: companyBio,
        overview: companySummary,
        size: numberEmployees,
        websiteLink: webLink,
        culture: companyCulture,
        founded: `${month}, ${year}`,
        location: companyLocation,
      };
      if (accountSetup?.basic?.id === undefined) {
        basic.id = company?.id;
      }
      setIsLoading(true);
      createOrUpdateCompany({ variables: { input: basic } }).then(({ data }) => {
        const { id } = data.createCompany;
        const { uid } = fire.auth().currentUser;
        values.id = id;
        setCompanyAdmin({ variables: { input: { companyId: id, uid, as: "Admin " } } }).then(() => {
          setAccountSetup({ basic: values });
          onNext(2);
          setIsLoading(false);
          history.push("/social");
        });
      });
    },
  });

  const handleChangeValue = (value, type) => {
    formik.setFieldValue(type, addHttp(value))
  }

  return (
    <div>
      <BoardingPanel
        heading='Basic Information'
        subHeading='Let people know more about your company'
        onSubmit={formik.handleSubmit}
        isLoading={isLoading}
        onSubmitText={"Next"}
      >
        <form>
          <div className='d-flex flex-wrap justify-content-between'>
            <div className={isDesktop || isTablet ? "w-45" : "w-100"}>
              {/* Company Name */}
              <AppInput
                label='COMPANY NAME'
                placeholder='Your company name'
                editable={state === "preview" ? false : true}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.companyName}
                name='companyName'
                hasError={formik.touched.companyName && formik.errors.companyName ? true : false}
              />
              {formik.touched.companyName && formik.errors.companyName && (
                <AppText size='_12' color='_r100'>
                  {formik.errors.companyName}
                </AppText>
              )}
            </div>
            {/* Company Services */}
            <div className={isDesktop || isTablet ? "w-45" : "w-100 mt-3"}>
              <AppDropdown
                defaultValue={formik.values.services}
                formik={formik}
                name='services'
                label='SERVICES'
                placeholder='What your company offers'
                items={services}
                onChange={(value) => formik.setFieldValue("services", value)}
              />
            </div>
            {/* Company Bio */}
            <div className={"w-100 mt-3"}>
              <AppTextArea
                label='COMPANY BIO'
                placeholder='A little introduction about your company'
                editable={state === "preview" ? false : true}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.companyBio}
                name='companyBio'
                hasError={formik.touched.companyBio && formik.errors.companyBio ? true : false}
                height={"75px"}
              />
              {formik.touched.companyBio && formik.errors.companyBio && (
                <AppText size='_12' color='_r100'>
                  {formik.errors.companyBio}
                </AppText>
              )}
            </div>
            {/* Company Summary */}
            <div className={"w-100 mt-3"}>
              <AppTextArea
                label='COMPANY SUMMARY'
                placeholder='A summary about your company and what it is you do'
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.companySummary}
                name='companySummary'
                hasError={
                  formik.touched.companySummary && formik.errors.companySummary ? true : false
                }
                height={"120px"}
              />
              {formik.touched.companySummary && formik.errors.companySummary && (
                <AppText size='_12' color='_r100'>
                  {formik.errors.companySummary}
                </AppText>
              )}
            </div>
            {/* Number of Employees */}
            <div className={"w-45 mt-3"}>
              <AppDropdown
                defaultValue={formik.values.numberEmployees}
                formik={formik}
                name='numberEmployees'
                label='NUMBER OF EMPLOYEES'
                placeholder='Number of employees in your company'
                items={employeesList}
                onChange={(value) => formik.setFieldValue("numberEmployees", value)}
                onlyNumber={true}
              />
            </div>
            {/* Web Link */}
            <div className={"w-45 mt-3"}>
              <AppInput
                label='WEBSITE LINK'
                placeholder='https://samplelink.com/'
                editable={state === "preview" ? false : true}
                onChange={(e) => handleChangeValue(e.target.value, 'webLink')}
                onBlur={formik.handleBlur}
                value={formik.values.webLink}
                name='webLink'
                hasError={formik.touched.webLink && formik.errors.webLink ? true : false}
              />
              {formik.touched.webLink && formik.errors.webLink && (
                <AppText size='_12' color='_r100'>
                  {formik.errors.webLink}
                </AppText>
              )}
            </div>
            {/* Company Culture */}
            <div className={"w-45 mt-3"}>
              <AppDropdown
                defaultValue={formik.values.companyCulture}
                formik={formik}
                name='companyCulture'
                label='ORGANIZATION CULTURE (OPTIONAL)'
                placeholder="Your company's culture"
                items={companyCultureList}
                onChange={(value) => formik.setFieldValue("companyCulture", value)}
              />
            </div>
            {/* Month and Year*/}
            <div className={"w-45 mt-3"}>
              <div className={'d-flex'}>
                <div className='mr-2'>
                  <AppDropdown
                    defaultValue={formik.values.month}
                    formik={formik}
                    name='month'
                    label='FOUNDED'
                    placeholder='Month'
                    items={monthList}
                    onChange={(value) => formik.setFieldValue("month", value)}
                    error={false}
                  />
                </div>
                <div>
                  <AppInput
                    label=''
                    placeholder='1990'
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.year}
                    name='year'
                    onlyNumber={true}
                    hasError={formik.touched.year && formik.errors.year ? true : false}
                  />
                </div>
              </div>
              {(formik.touched.month && formik.errors.month && !formik.errors.year) && (
                <AppText size='_12' color='_r100'>
                  {formik.errors.month}
                </AppText>
              )}
              {(formik.touched.year && formik.errors.year && !formik.errors.month) && (
                <AppText size='_12' color='_r100'>
                  {formik.errors.year}
                </AppText>
              )}
              {(formik.touched.year && formik.errors.year && formik.errors.month && formik.touched.month) && (
                <AppText size='_12' color='_r100'>
                  {formik.errors.year}
                </AppText>
              )}
            </div>
            {/* Location */}
            <div className={"w-45 mt-3"}>
              <AppDropdown
                defaultValue={formik.values.companyLocation}
                formik={formik}
                name='companyLocation'
                label='LOCATION'
                placeholder="Your company's location"
                items={locationList}
                onChange={(value) => formik.setFieldValue("companyLocation", value)}
              />
            </div>
          </div>

          <div className='mt-3' />
        </form>
      </BoardingPanel>
    </div>
  );
}
