const SAVE_TOKENS = "SAVE_TOKENS";

const initialState = {};

const AuthReducer = (state = initialState, action) => {
  switch (action.type) {
    case SAVE_TOKENS:
      return { ...state, ...action.payload };
    default:
      return state;
  }
};

export const saveTokens = (data) => {
  return {
    type: SAVE_TOKENS,
    payload: data,
  };
};

export default AuthReducer;
