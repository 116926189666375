import * as Yup from "yup";

export const basicSchema = Yup.object().shape({
  companyName: Yup.string()
    .required("Company Name is required"),
  services: Yup.string()
    .required("Services is required"),
  companyBio: Yup.string()
    .required("Company bio is required"),
  companySummary: Yup.string()
    .required("Company summary is required"),
  numberEmployees: Yup.string()
    .oneOf(["1 - 4","5 - 10", "11 - 50", "51 - 99", "100 +"], "Please select a number of employees")
    .required("Number of Employees is required"),
  webLink: Yup.string()
    .url()
    .required("Website link is required"),
  month: Yup.string()
    .required("Month and year is required"),
  year: Yup.number()
    .required("Month and year is required"),
  companyLocation: Yup.string()
    .required("Location is required")
})

export const socialSchema = Yup.object().shape({
  facebook: Yup.string()
    .url(),
  twitter: Yup.string()
    .url(),
  instagram: Yup.string()
    .url(),
  linkedIn: Yup.string()
    .url(),
})