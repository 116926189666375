import React from "react";
import { useFormik } from "formik";
import { useMutation, gql } from "@apollo/client";
import AppInput from "../molecules/AppInput";
import AppTextArea from "../molecules/AppTextArea";
import AppDropdown from "../molecules/AppDropdown";
import AppButton from "../molecules/AppButton";
import AppText from "../atoms/AppText";
import { basicSchema } from "../../utils/schema/accountSetupSchema";
import { CircularProgress } from "@material-ui/core";
import { industrys as services } from "../../utils/industrys"

const UPDATE_COMPANY = gql`
  mutation ($input: _CompanyInputUpdate) {
    updateCompany(input: $input) {
      id
    }
  }
`;

export default function CompanyInformation({ company, iam }) {
  const employeesList = ["1 - 4", "5 - 10", "11 - 50", "51 - 99", "100 +"];
  const companyCultureList = ["Formal", "Casual"];
  const monthList = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  const locationList = ["London, United Kingdom"];
  const [updateCompany, { loading }] = useMutation(UPDATE_COMPANY);

  const formik = useFormik({
    initialValues: {
      companyName: company?.name,
      services: company?.services,
      companyBio: company?.bio,
      companySummary: company?.overview,
      numberEmployees: company?.size,
      webLink: company?.websiteLink,
      month: company?.founded.split(",")[0],
      year: company?.founded.split(",")[1].trim(),
      companyLocation: company?.location,
      companyCulture: company?.culture,
    },
    validationSchema: basicSchema,
    onSubmit: (values) => {
      const {
        companyName,
        companyBio,
        companySummary,
        companyCulture,
        companyLocation,
        services,
        webLink,
        month,
        year,
        numberEmployees,
      } = values;

      updateCompany({
        variables: {
          input: {
            companyId: company.id,
            values: {
              name: companyName,
              services: services,
              bio: companyBio,
              overview: companySummary,
              size: numberEmployees,
              websiteLink: webLink,
              culture: companyCulture,
              founded: `${month}, ${year.trim()}`,
              location: companyLocation,
            },
          },
        },
      });
    },
  });

  const handleSubmit = () => {
    formik.submitForm();
  };

  return (
    <div className={"card_style my-4"}>
      {/* Header */}
      <header className={"d-flex flex-column p-4"}>
        <AppText size='_18' weight='_medium'>
          Basic Information
        </AppText>
        <AppText size='_14' color={"_c50"}>
          Let Job Seekers know more about your company
        </AppText>
      </header>
      <div className='_sep_' />
      <form className={"p-4"}>
        <div className='d-flex flex-wrap justify-content-between'>
          <div className={"w-45"}>
            {/* Company Name */}
            <AppInput
              editable={iam.trim() === "Admin" ? true : false}
              label='COMPANY NAME'
              placeholder='Your company name'
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.companyName}
              name='companyName'
              hasError={formik.touched.companyName && formik.errors.companyName ? true : false}
            />
            {formik.touched.companyName && formik.errors.companyName && (
              <AppText size='_12' color='_r100'>
                {formik.errors.companyName}
              </AppText>
            )}
          </div>
          {/* Company Services */}
          <div className={"w-45"}>
            <AppDropdown
              editable={iam.trim() === "Admin" ? true : false}
              defaultValue={formik.values.services}
              formik={formik}
              name='services'
              label='SERVICES'
              placeholder='What your company offers'
              items={services}
              onChange={(value) => formik.setFieldValue("services", value)}
            />
          </div>
          {/* Company Bio */}
          <div className={"w-100 mt-3"}>
            <AppTextArea
              editable={iam.trim() === "Admin" ? true : false}
              label='COMPANY BIO'
              placeholder='A little introduction about your company'
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.companyBio}
              name='companyBio'
              hasError={formik.touched.companyBio && formik.errors.companyBio ? true : false}
              height={"75px"}
            />
          </div>
          {/* Company Summary */}
          <div className={"w-100 mt-3"}>
            <AppTextArea
              editable={iam.trim() === "Admin" ? true : false}
              label='COMPANY SUMMARY'
              placeholder='A summary about your company and what it is you do'
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.companySummary}
              name='companySummary'
              hasError={
                formik.touched.companySummary && formik.errors.companySummary ? true : false
              }
              height={"155px"}
            />
          </div>
          {/* Number of Employees */}
          <div className={"w-45 mt-3"}>
            <AppDropdown
              defaultValue={formik.values.numberEmployees}
              formik={formik}
              name='numberEmployees'
              label='NUMBER OF EMPLOYEES'
              placeholder='Number of employees in your company'
              items={employeesList}
              onChange={(value) => formik.setFieldValue("numberEmployees", value)}
              onlyNumber={true}
            />
          </div>
          {/* Web Link */}
          <div className={"w-45 mt-3"}>
            <AppInput
              editable={iam.trim() === "Admin" ? true : false}
              label='WEBSITE LINK'
              placeholder='https://samplelink.com/'
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.webLink}
              name='webLink'
              hasError={formik.touched.webLink && formik.errors.webLink ? true : false}
            />
            {formik.touched.webLink && formik.errors.webLink && (
              <AppText size='_12' color='_r100'>
                {formik.errors.webLink}
              </AppText>
            )}
          </div>
          {/* Company Culture */}
          <div className={"w-45 mt-3"}>
            <AppDropdown
              editable={iam.trim() === "Admin" ? true : false}
              defaultValue={formik.values.companyCulture}
              formik={formik}
              name='companyCulture'
              label='ORGANIZATION CULTURE'
              placeholder="Your company's culture"
              items={companyCultureList}
              onChange={(value) => formik.setFieldValue("companyCulture", value)}
            />
          </div>
          {/* Month and Year*/}
          <div className={"w-45 mt-3"}>
            <div className={'d-flex'}>
              <div className='mr-2'>
                <AppDropdown
                  defaultValue={formik.values.month}
                  formik={formik}
                  name='month'
                  label='FOUNDED'
                  placeholder='Month'
                  items={monthList}
                  onChange={(value) => formik.setFieldValue("month", value)}
                  error={false}
                />
              </div>
              <div>
                <AppInput
                  label=''
                  placeholder='1990'
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.year}
                  name='year'
                  onlyNumber={true}
                  hasError={formik.touched.year && formik.errors.year ? true : false}
                />
              </div>
            </div>
            {(formik.touched.month && formik.errors.month && !formik.errors.year) && (
              <AppText size='_12' color='_r100'>
                {formik.errors.month}
              </AppText>
            )}
            {(formik.touched.year && formik.errors.year && !formik.errors.month) && (
              <AppText size='_12' color='_r100'>
                {formik.errors.year}
              </AppText>
            )}
            {(formik.touched.year && formik.errors.year && formik.errors.month && formik.touched.month) && (
              <AppText size='_12' color='_r100'>
                {formik.errors.year}
              </AppText>
            )}
          </div>
          {/* Location */}
          <div className={"w-45 mt-3"}>
            <AppDropdown
              editable={iam.trim() === "Admin" ? true : false}
              defaultValue={formik.values.companyLocation}
              formik={formik}
              name='companyLocation'
              label='LOCATION'
              placeholder="Your company's location"
              items={locationList}
              onChange={(value) => formik.setFieldValue("companyLocation", value)}
            />
          </div>
        </div>
        <div className='mt-3' />
      </form>
      {/* Footer */}
      {iam.trim() === "Admin" && (
        <>
          <div className='_sep_' />
          <footer
            className={"d-flex justify-content-end p-3"}
            style={{ backgroundColor: "#FBFBFD" }}
          >
            <AppButton
              color='_white'
              style={{ width: "70px" }}
              width={"_custom"}
              onClick={handleSubmit}
              state={Object.keys(formik.errors).length ? true : false}
            >
              {loading ? (
                <CircularProgress style={{ height: "30px", width: "30px", color: "#03E0A9" }} />
              ) : (
                  <AppText size='_14'>Save</AppText>
                )}
            </AppButton>
          </footer>
        </>
      )}
    </div>
  );
}
