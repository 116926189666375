
import * as Yup from "yup";

export const createJobSchema = Yup.object().shape({
    jobTitle: Yup.string()
        .required("Job title is required"),
    experienceLevel: Yup.string()
        .oneOf(['Entry Level', 'Intermediate', 'Mid Level', 'Senior'], 'Please select a experience experien level')
        .required("Experience Level is required"),
    location: Yup.string()
        .required("Please enter a location"),
    employmentType: Yup.string()
        .oneOf(['Full-Time', 'Part-Time', 'Contract', 'Internship', 'remote'], "Please select a experience")
        .required("Employment type is required"),
    salaryRange: Yup.object().shape({
        start: Yup.string()
            .required("A minimum is required"),
        end: Yup.string()
            .required("A maximum is required"),
    }),
    applyLinkUrl: Yup.string()
        .url("Must be a valid URL"),
    description: Yup.string()
        .required("description is required")
})