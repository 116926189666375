import React, { useState, forwardRef } from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import PlayArrowRoundedIcon from '@material-ui/icons/PlayArrowRounded'
import Slider from "@material-ui/core/Slider";
import Tooltip from "@material-ui/core/Tooltip";
import VolumeDownRoundedIcon from '@material-ui/icons/VolumeDownRounded';
import VolumeMuteRoundedIcon from '@material-ui/icons/VolumeMuteRounded';
import FullScreenIcon from "@material-ui/icons/Fullscreen";
import IconButton from "@material-ui/core/IconButton";
import PauseIcon from '@material-ui/icons/Pause';
import Button from "@material-ui/core/Button";
import AppText from "../../components/atoms/AppText";

const useStyles = makeStyles({
    controlsWrapper: {
        position: "absolute",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        zIndex: 1,
    },
    bottomIcons: {
        height: '24px',
        width: "24px",
        color: "#9EA0A5",
        "&:hover": {
            color: "#fff",
        },
    },
    volumeSlider: {
        // position: 'absolute',
        color: "#fff"
    },
});

function ValueLabelComponent(props) {
    const { children, open, value } = props;

    return (
        <Tooltip open={open} enterTouchDelay={0} placement="top" title={value}>
            {children}
        </Tooltip>
    );
}

const PrettoSlider = withStyles({
    thumb: {
        height: 20,
        width: 20,
        backgroundColor: "#fff",
        border: "1px solid #9EA0A5",
        marginTop: -8,
        marginLeft: -12,
        "&:focus, &:hover, &$active": {
            boxShadow: "inherit",
        },
    },
    active: {},
    valueLabel: {
        left: "calc(-50% + 4px)",
    },
    track: {
        height: 4,
        borderRadius: 4,
        backgroundColor: '#fefefe'
    },
    rail: {
        height: 4,
        borderRadius: 4,
        backgroundColor: '#f0f0f0'
    },
})(Slider);

const VolumeSlider = withStyles({
    thumb: {
        height: 12,
        width: 12,
        backgroundColor: "#fff",
        border: "1px solid #9EA0A5",
        marginTop: -8,
        marginLeft: -12,
        "&:focus, &:hover, &$active": {
            boxShadow: "inherit",
        },
        boxShadow: '0 1px 6px #9EA0A5'
    },
    active: {},
    valueLabel: {
        left: "calc(-50% + 4px)",
    },
    track: {
        height: 4,
        borderRadius: 4,
        backgroundColor: '#fefefe',
        boxShadow: '0 1px 6px #9EA0A5'
    },
    rail: {
        height: 4,
        borderRadius: 4,
        boxShadow: '0 1px 6px #9EA0A5'
    },
})(Slider);

const PlayerControls = forwardRef(({
    onPlayerPause,
    playing,
    muted,
    onVolumeChange,
    volume,
    onToggleFullScreen,
    played,
    onSeek,
    onSeekMouseDown,
    onSeekMouseUp,
    elapsedTime,
    totalDuration
}, ref) => {
    const classes = useStyles();
    const [isClick, setIsClick] = useState(false)
    
    const handleMouseDown = () => {
        setIsClick(false)
    }
    return (
        <div className={`${classes.controlsWrapper} h-100 d-flex flex-column-reverse`} ref={ref}>
            {/* bottom controls */}
            <Grid
                container
                direction="row"
                justify="space-between"
                alignItems="center"
                className={'p-3 d-flex align-items-end'}
            >
                {isClick && (
                    <Grid className={'px-2 d-flex align-items-end mb-1'}>
                        <PlayArrowRoundedIcon style={{ fontSize: "24px", color: 'rgba(0, 0, 0, 0.0)' }} className={`mr-2`} />
                        <VolumeSlider
                            min={0}
                            max={100}
                            value={(volume * 100)}
                            className={`${classes.volumeSlider}`}
                            onChange={onVolumeChange}
                            onChangeCommitted={onVolumeChange}
                            orientation={'vertical'}
                            style={{ height: '50px' }}
                            onMouseLeave={handleMouseDown}
                        />
                    </Grid>
                )}
                <Grid
                    item
                    xs={12}
                    className={'d-flex align-items-center p-1 px-2'}
                    style={{ background: 'rgba(0, 0, 0, 0.9)', borderRadius: '8px' }}>
                    <IconButton className={`${classes.bottomIcons} mr-2`}>
                        {playing ? (
                            <PauseIcon
                                style={{ fontSize: "24px", cursor: 'pointer' }}
                                className={`${classes.bottomIcons}`}
                                onClick={onPlayerPause}
                            />
                        ) : (
                            <PlayArrowRoundedIcon
                                style={{ fontSize: "24px", cursor: 'pointer' }}
                                className={`${classes.bottomIcons}`}
                                onClick={onPlayerPause}
                            />
                        )}

                    </IconButton>
                    <IconButton className={`${classes.bottomIcons} mr-3`}>
                        {muted ? (
                            <VolumeMuteRoundedIcon
                                style={{ fontSize: "24px", cursor: 'pointer' }}
                                className={`${classes.bottomIcons}`}
                                onClick={() => { setIsClick(!isClick) }}
                            />
                        ) : (
                            <VolumeDownRoundedIcon
                                style={{ fontSize: "24px", cursor: 'pointer' }}
                                className={`${classes.bottomIcons}`}
                                onClick={() => { setIsClick(!isClick) }}
                            />
                        )}

                    </IconButton>
                    <PrettoSlider
                        min={0}
                        max={100}
                        value={played * 100}
                        ValueLabelComponent={(props) => (
                            <ValueLabelComponent {...props} value={elapsedTime} />)}
                        className={'ml-1'}
                        onChange={onSeek}
                        onMouseDown={onSeekMouseDown}
                        onChangeCommitted={onSeekMouseUp}
                    />
                    <Button variant="text" style={{ color: "#fff", marginLeft: 16 }}>
                        <AppText size='_14' color={'_c0'}>
                            {totalDuration}
                        </AppText>
                    </Button>
                    <IconButton className={`${classes.bottomIcons} ml-2`}>
                        <FullScreenIcon
                            style={{ fontSize: "24px", cursor: 'pointer' }}
                            className={`${classes.bottomIcons}`}
                            onClick={onToggleFullScreen}
                        />
                    </IconButton>
                </Grid>
            </Grid>
        </div>
    );
});

export default PlayerControls