import React from 'react'
import AppText from '../atoms/AppText'
import { Link } from 'react-scroll'

export default function Select({ text, isSelect, optKey }) {
    return (
        <Link
            className={'p-1 rounded pointer'}
            to={optKey}
            style={optKey === isSelect ? { backgroundColor: '#03CA98' } : { backgroundColor: '' }}
            spy={true}
            smooth={true}
            offset={-71}
        >
            <AppText size='_14' weight='_medium' color={optKey === isSelect ? '_c0' : '_c50'}>
                {text}
            </AppText>
        </Link>
    )
}