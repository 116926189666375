import React from "react";
import AppText from '../atoms/AppText'
import { getWindowDimensions } from "../../utils/functions/index";

export default function Chat() {
    const screenSize = getWindowDimensions()

    return (
        <div className='d-flex justify-content-center mt-5 w-100 align-items-center' style={{ height: screenSize.height - 50 }}>
            <div className={'d-flex flex-column w-40 align-items-center'}>
                <img alt={"message"} src='/Group-2128.svg' className={'mb-5'} height={'142'} width={'190'}></img>
                <AppText size='_18' weight='_medium' color={'_c100'}>
                    It's awfully quiet in here...
                </AppText>
                <AppText size='_16' color={'_c50'} align={'_center'}>
                When you receive or send a message it will appear here.
                </AppText>
            </div>
        </div>
    )
}