import { Avatar, CircularProgress } from "@material-ui/core";
import { useSelector } from "react-redux";
import { useMutation, gql } from "@apollo/client";
import { useFormik } from "formik";
import * as Yup from "yup";
import React, { useState } from "react";
import AppText from "../../../components/atoms/AppText";
import AppButton from "../../../components/molecules/AppButton";
import AppInput from "../../../components/molecules/AppInput";
import AppPassword from "../../../components/molecules/AppPassword";
import AppDropdown from "../../../components/molecules/AppDropdown";
import { isValidImage } from "../../../utils/functions";
import { fire } from "../../../firebase";
import UploadButton from "../../../components/atoms/UploadButton";
import Model from "../../../components/molecules/Model";
import ProfileUploadModal from "../../../components/orgnisms/ProfileUploadModal";

const UPDATE_USER = gql`
  mutation ($input: UpdateManagerInput) {
    updateManager(input: $input) {
      id
    }
  }
`;

export default function Profile() {
  const { user } = useSelector((state) => state.app);
  const uid = fire.auth().currentUser.uid;
  // const [showEditPassword, setEditPassword] = useState(false);
  const [temp, setTemp] = useState(null);
  const [previewImage, setPreviewImage] = useState(false);
  const [changingPassword, setIsChangingPassword] = useState(false);
  // const [isSuccess, setIsSuccess] = useState(false);
  // const [error, setIsError] = useState(false);

  const [updateUser, { loading }] = useMutation(UPDATE_USER);

  const formik = useFormik({
    initialValues: {
      firstName: user?.firstName || "",
      lastName: user?.lastName || "",
      email: user?.email || "",
      country: user?.country || "",
      city: user?.city || "",
    },
    validationSchema: Yup.object().shape({
      firstName: Yup.string()
        .min(3, "Minimum of 3 characters")
        .trim()
        .required("Please enter your First Name")
        .matches("^[^0-9]+$", "Must not contain numbers")
        .matches(
          "^[^<>/$@%#&+'?,/.|()*]+$",
          "Must not contain special characters"
        ),
      lastName: Yup.string()
        .min(3, "Minimum of 3 characters")
        .trim()
        .required("Please enter your Last Name")
        .matches("^[^0-9]+$", "Must not contain numbers")
        .matches(
          "^[^<>/$@%#&+'?,/.|()*]+$",
          "Must not contain special characters"
        ),
      email: Yup.string().required(),
      country: Yup.string().required(),
      city: Yup.string().required(),
    }),
    onSubmit: (values) => {
      updateUser({ variables: { input: { ...values, uid } } });
    },
  });

  const formik2 = useFormik({
    initialValues: {
      oldPassword: "",
      newPassword: "",
      confirmPassword: "",
    },
    validationSchema: Yup.object().shape({
      oldPassword: Yup.string().required("Old password is required"),
      newPassword: Yup.string()
        .min(6, "New password short should'nt be less than 6")
        .matches(/\d+/, "New password must contain a Number")
        .required("New password is required"),
      confirmPassword: Yup.string()
        .required()
        .oneOf(
          [Yup.ref("newPassword"), null],
          "passwords must match with new password"
        ),
    }),
    onSubmit: (value, { resetForm }) => {
      setIsChangingPassword(true);
      const user = fire.auth().currentUser;
      user
        .updatePassword(value.newPassword)
        .then(() => {
          setIsChangingPassword(false);
          // setIsSuccess(true);
          resetForm();
        })
        .catch((err) => {
          if (err.code === "auth/requires-recent-login") {
            setIsChangingPassword(false);
            const credential = fire.auth.EmailAuthProvider.credential(
              user.email,
              value.oldPassword
            );
            user
              .reauthenticateWithCredential(credential)
              .then(() => {
                user.updatePassword(value.newPassword).then(() => {
                  setIsChangingPassword(false);
                  // setIsSuccess(true);
                  resetForm();
                });
              })
              .catch((e) => {
                if (e) {
                  // setIsError(true);
                }
              });
          }
        });
    },
  });

  const handleImage = (file) => {
    const { temp } = isValidImage(file[0]);
    setTemp(temp);
    setPreviewImage(true);
  };

  return (
    <div className="d-flex justify-content-center mt-5">
      <div className="w-75 pt-4 d-flex justify-content-between">
        <div className="w-48">
          <div className="card_style p-4 align-items-center d-flex">
            <div className="pr-4">
              <Avatar
                src={user?.avatarUrl}
                style={{
                  height: "140px",
                  width: "140px",
                  background: "#34AA44",
                }}
              />
            </div>
            <div>
              <AppText size="_26">{user.fullName}</AppText>
              <div className="mb-2">
                <AppText size="_14" color="_c90">
                  {user?.roles[0]?.as === "Admin "
                    ? "Company Admin"
                    : "Hiring Manager"}
                </AppText>
              </div>
              <UploadButton
                label="Change Photo"
                showsIcon={false}
                onChange={(file) => handleImage(file)}
              >
                <AppButton color="_white">
                  <AppText size="_14">Change Photo</AppText>
                </AppButton>
              </UploadButton>
            </div>
          </div>
          <div className="mt-4">
            <div className="card_style">
              <div className="p-4">
                <AppText weight="_medium">Basic Information</AppText>
                <div>
                  <AppText size="_14" color="_c90">
                    Let people know more about you
                  </AppText>
                </div>
              </div>
              <div className="w-100 _sep" style={{ height: 1 }} />
              <div className="px-4 py-3">
                <div className="d-flex justify-content-between flex-wrap">
                  <div className="w-48">
                    <AppInput
                      label="FIRST NAME"
                      placeholder="First name"
                      name="firstName"
                      value={formik.values.firstName}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      hasError={
                        formik.touched.firstName && formik.errors.firstName
                          ? true
                          : false
                      }
                    />
                  </div>
                  <div className="w-48">
                    <AppInput
                      label="LAST NAME"
                      placeholder="Last name"
                      name="lastName"
                      value={formik.values.lastName}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      hasError={
                        formik.touched.lastName && formik.errors.lastName
                          ? true
                          : false
                      }
                    />
                  </div>
                  <div className="w-48 mt-3">
                    <AppInput
                      editable={false}
                      label="EMAIL ADDRESS"
                      placeholder="Email"
                      name="email"
                      value={formik.values.email}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      hasError={
                        formik.touched.email && formik.errors.email
                          ? true
                          : false
                      }
                    />
                  </div>

                  <div className="w-48 mt-3">
                    <AppDropdown
                      defaultValue={formik.values.country}
                      formik={formik}
                      name="country"
                      label="COUNTRY"
                      placeholder="Country"
                      items={["United Kingdom"]}
                      onChange={(value) =>
                        formik.setFieldValue("country", value)
                      }
                    />
                  </div>
                  <div className="w-48 mt-3">
                    <AppDropdown
                      defaultValue={formik.values.city}
                      formik={formik}
                      name="city"
                      label="CITY"
                      placeholder="City"
                      items={["London"]}
                      onChange={(value) => formik.setFieldValue("city", value)}
                    />
                  </div>
                </div>
              </div>
              <div style={{ background: "#FBFBFD" }} className="mt-2">
                <div className="w-100 _sep" style={{ height: 1 }} />
                <div className="px-4 py-3 d-flex justify-content-end">
                  <AppButton color="_white" onClick={formik.handleSubmit}>
                    {loading ? (
                      <CircularProgress
                        style={{
                          height: "30px",
                          width: "30px",
                          color: "#03E0A9",
                        }}
                      />
                    ) : (
                      <AppText size="_14">Save</AppText>
                    )}
                  </AppButton>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="w-48">
          <div className="card_style">
            <div className="d-flex justify-content-between align-items-center p-4">
              <div>
                <AppText weight="_medium">Password</AppText>
                <div>
                  <AppText size="_14" color="_c90">
                    Change your account password
                  </AppText>
                </div>
              </div>
              {/* <span onClick={() => setEditPassword(true)} className='pointer'>
                <AppText size='_14' color='_g100' weight='_medium'>
                  Change
                </AppText>
              </span> */}
            </div>

            <div>
              <div className="w-100 _sep" style={{ height: 1 }} />
              <div className="px-4 py-3">
                <AppPassword
                  label="ENTER OLD PASSWORD"
                  placeholder="ENTER OLD PASSWORD"
                  name="oldPassword"
                  value={formik2.values.oldPassword}
                  onChange={formik2.handleChange}
                  hasError={
                    formik2.touched.oldPassword && formik2.errors.oldPassword
                      ? true
                      : false
                  }
                />
                <div>
                  {formik2.touched.oldPassword && formik2.errors.oldPassword ? (
                    <AppText size="_12" color="_r100">
                      {formik2.errors.oldPassword}
                    </AppText>
                  ) : null}
                </div>
                <div className="py-2" />
                <AppPassword
                  label="ENTER NEW PASSWORD"
                  placeholder="New Password"
                  name="newPassword"
                  value={formik2.values.newPassword}
                  onChange={formik2.handleChange}
                  hasError={
                    formik2.touched.newPassword && formik2.errors.newPassword
                      ? true
                      : false
                  }
                />
                <div>
                  {formik2.touched.newPassword && formik2.errors.newPassword ? (
                    <AppText size="_12" color="_r100">
                      {formik2.errors.newPassword}
                    </AppText>
                  ) : null}
                </div>
                <div className="py-2" />
                <AppPassword
                  label="CONFIRM NEW PASSWORD"
                  placeholder="CONFIRM NEW PASSWORD"
                  name="confirmPassword"
                  value={formik2.values.confirmPassword}
                  onChange={formik2.handleChange}
                  hasError={
                    formik2.touched.confirmPassword &&
                    formik2.errors.confirmPassword
                      ? true
                      : false
                  }
                />
                <div>
                  {formik2.touched.confirmPassword &&
                  formik2.errors.confirmPassword ? (
                    <AppText size="_12" color="_r100">
                      {formik2.errors.confirmPassword}
                    </AppText>
                  ) : null}
                </div>
              </div>
              <div style={{ background: "#FBFBFD" }} className="mt-2">
                <div className="w-100 _sep" style={{ height: 1 }} />
                <div className="px-4 py-3 d-flex justify-content-end">
                  <AppButton color="_white" onClick={formik2.handleSubmit}>
                    {changingPassword ? (
                      <CircularProgress
                        style={{
                          height: "30px",
                          width: "30px",
                          color: "#03E0A9",
                        }}
                      />
                    ) : (
                      <AppText size="_14">Save</AppText>
                    )}
                  </AppButton>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {previewImage && (
        <Model onClose={() => setPreviewImage(false)}>
          <ProfileUploadModal
            temp={temp}
            onClose={() => setPreviewImage(false)}
          />
        </Model>
      )}
    </div>
  );
}
