import { TextField, makeStyles } from "@material-ui/core";
import React, { useRef, useState, useEffect } from "react";
import AppText from "../atoms/AppText";

export default function AppSearchDropdown({ label, onBlur, items = [], onChange }) {
  const [showsDropdown, setShowdropdown] = useState(false);
  const [searchItems, setSearchItems] = useState([...items]);
  const [value, setValue] = useState("");

  const useSyles = makeStyles({
    label: { marginBottom: "10px" },
    root: {
      width: "100%",
      "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
        border: "none",
      },
      "& .MuiOutlinedInput-notchedOutline": {
        border: "none",
        maxHeight: "38px",
      },
    },
    input: {
      paddingTop: "12px",
      height: "39px",
      paddingBottom: "12px",
      fontFamily: "Roboto",
      color: "#3E3F42",
      border: "1px solid #D8DCE6",
      boxShadow: "0 1px 2px #00000014",
    },
    btn: {
      padding: "0!important",
      "&:hover": {
        background: "none",
      },
      "&:focus": {
        outline: 0,
      },
    },
    drop: {
      background: "#fff",
      border: "1px solid #D8DCE6",
      boxShadow: "0 1px 2px #00000014",
      borderRadius: "4px",
      marginTop: "0.2rem",
      position: "absolute",
      width: "100%",
      zIndex: "999",
      maxHeight: "240px",
      minHeight: "35px",
      display: showsDropdown ? "block" : "none",
      overflow: "auto"
    },
  });

  const classes = useSyles();
  const inputDropRef = useRef(null);
  const elRef = useRef(null);

  useEffect(() => {
    document.addEventListener("mousedown", (e) => {
      if (
        inputDropRef.current &&
        !inputDropRef.current.contains(e.target) &&
        elRef.current &&
        !elRef.current.contains(e.target)
      ) {
        setShowdropdown(false);
      }
    });
  }, []);

  const handleTyping = (e) => {
    if (value !== "") {
      setShowdropdown(true);
      const newItems = [...items];
      const matcher = new RegExp(`^${value.toLowerCase()}`, "g");
      const filtered = newItems.filter(({ label }) => label.toLowerCase().match(matcher));
      setSearchItems(filtered);
    } else {
      setShowdropdown(false);
    }
    if (e.keyCode === 13) {
      if (value.trim() !== "") {
        onChange({ label: value, value: "others" });
        setShowdropdown(false);
        setValue("");
      }
    }
  };

  const handleClick = (v) => {
    onChange(v);
    setShowdropdown(false);
    setValue("");
  };

  return (
    <div className='position-relative'>
      <div className='mb-2'>
        <AppText size='_12' color='_c50' weight='_medium'>
          Skills
        </AppText>
      </div>

      <div>
        <TextField
          value={value}
          variant='outlined'
          placeholder='Search for a skill'
          fullWidth
          size='small'
          onChange={(e) => setValue(e.target.value)}
          onBlur={onBlur}
          onKeyUp={handleTyping}
          ref={inputDropRef}
          className={classes.root}
          InputProps={{
            className: classes.input,
          }}
        />
      </div>
      <div className={classes.drop} ref={elRef}>
        {searchItems.map((s, i) => (
          <div className='py-2 px-3 pointer' key={i} onClick={() => handleClick(s)}>
            <AppText size=''>
              {`${s.label} `} <span className='_c50'>{`(${s.value})`}</span>
            </AppText>
          </div>
        ))}
        <div className='px-3 pt-1'>
          {searchItems.length === 0 && <AppText size='_14'>Press enter to add the skill</AppText>}
        </div>
      </div>
    </div>
  );
}
