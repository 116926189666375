import React, { useState, useRef, useEffect } from 'react'
import AppText from '../atoms/AppText'
import ReactPlayer from 'react-player'
import PlayerControls from '../molecules/PlayerControls'
import { makeStyles } from "@material-ui/core/styles";
import screenfull from 'screenfull'
import { format } from '../../utils/functions/index'

const useStyles = makeStyles({
    playerWrapper: {
        position: "relative",
    },
});

export default function VideoCV({ url, title, key, isSelect }) {
    let count = 0
    const classes = useStyles();
    const [playerState, setPlayerState] = useState({
        playing: false,
        muted: false,
        volume: 1,
        played: 0,
        seeking: false
    })

    const playerRef = useRef(null)
    const playerContainerRef = useRef(null)
    const controlsRef = useRef(null)
    const elRef = useRef(null);

    const handlePlayerPause = () => {
        setPlayerState({ ...playerState, playing: !playerState.playing })
    }

    const handleVolumeChange = (e, newValue) => {
        setPlayerState({
            ...playerState,
            volume: parseFloat(newValue / 100),
            muted: newValue === 0 ? true : false
        })
    }

    const toggleFullScreen = () => {
        screenfull.toggle(playerContainerRef.current)
    }

    const handleProgress = (changeState) => {
        if (count > 3) {
            controlsRef.current.style.visibility = "hidden";
            count = 0;
        }
        if (controlsRef.current.style.visibility === "visible") {
            count += 1;
        }
        if (!playerState.seeking) {
            setPlayerState({ ...playerState, ...changeState })
        }
    }

    const handleSeekChange = (e, newValue) => {
        setPlayerState({ ...playerState, played: parseFloat(newValue / 100) })
    }

    const handleSeekMouseDown = (e) => {
        setPlayerState({ ...playerState, seeking: true })
    }

    const handleSeekMouseUp = (e, newValue) => {
        setPlayerState({ ...playerState, seeking: false })
        playerRef.current.seekTo(newValue / 100)
    }

    const handleMouseMove = () => {
        controlsRef.current.style.visibility = "visible";
        count = 0;
    };

    const hanldeMouseLeave = () => {
        controlsRef.current.style.visibility = "hidden";
        count = 0;
    };

    const currentTime = playerRef && playerRef.current ? playerRef.current.getCurrentTime() : "00:00"
    const duration = playerRef && playerRef.current ? playerRef.current.getDuration() : "00:00"
    const elapsedTime = format(currentTime)
    const totalDuration = format(duration)

    
    useEffect(() => {
        const handleScroll = () => {
            if (elRef?.current?.getBoundingClientRect()?.top - 100 <= 0 && 100 + elRef?.current?.getBoundingClientRect()?.top >= 1) { 
                isSelect('Video')
            }
        }
        document.addEventListener('scroll', handleScroll);
        return () => {
            document.removeEventListener('scroll', handleScroll);
        }
    }, [isSelect])

    return (
        <div className={`mt-3 w-100 card_style`} key={key} id={'Video'} ref={elRef}>
            {/* Header */}
            <header className={'p-4'}>
                <AppText size='_18' weight='_medium'>
                    {title}
                </AppText>
            </header>
            <div className='_sep_' />
            <div className={'d-flex justify-content-center p-3'}>
                <div
                    className={`${classes.playerWrapper} card_style p-2`}
                    ref={playerContainerRef}
                    style={{ maxHeight: '415px', minHeight: '415px', background: 'rgba(0, 0, 0, 0.1)', width: '100%' }}
                    onMouseMove={handleMouseMove}
                    onMouseLeave={hanldeMouseLeave}
                >
                    <ReactPlayer
                        ref={playerRef}
                        url={url}
                        muted={playerState.muted}
                        playing={playerState.playing}
                        volume={playerState.volume}
                        onProgress={handleProgress}
                        width={'100%'}
                        height={'100%'}
                    />
                    <PlayerControls
                        ref={controlsRef}
                        onPlayerPause={handlePlayerPause}
                        playing={playerState.playing}
                        muted={playerState.muted}
                        onVolumeChange={handleVolumeChange}
                        volume={playerState.volume}
                        onToggleFullScreen={toggleFullScreen}
                        played={playerState.played}
                        onSeek={handleSeekChange}
                        onSeekMouseDown={handleSeekMouseDown}
                        onSeekMouseUp={handleSeekMouseUp}
                        elapsedTime={elapsedTime}
                        totalDuration={totalDuration}
                    />
                </div>
            </div>
        </div>
    )
}