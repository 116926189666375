import { CircularProgress } from "@material-ui/core";
import React from "react";
import AppText from "../atoms/AppText";
import AppButton from "./AppButton";

export default function ModalFooter({
  onClose,
  onSave,
  hasErrors = true,
  canSave = true,
  isLoading = false,
  closeText = "Cancel",
  saveText = " Save",
  isModal = true,
  job = false
}) {
  return (
    <div>
      <div className='px-4 py-3 topm _darc'>
        <div className='d-flex justify-content-between'>
          <AppButton onClick={onClose} color='_gredient'>
            <span className='mx-2'>
              <AppText size='_14'>{closeText}</AppText>
            </span>
          </AppButton>
          {!canSave ? null : (
            <AppButton
              type='submit'
              color={hasErrors && isModal ? "_white" : hasErrors && !isModal ? job ? "_gc" : "_rc" : "_gc"}
              onClick={onSave}
            >
              {isLoading ? (
                <CircularProgress style={{ color: "#fff", height: "30px", width: "30px" }} />
              ) : (
                <span className='mx-2'>
                  <AppText
                    size='_14'
                    color={hasErrors && isModal ? null : !hasErrors && !isModal ? "_c0" : "_c0"}
                  >
                    {saveText}
                  </AppText>
                </span>
              )}
            </AppButton>
          )}
        </div>
      </div>
    </div>
  );
}
