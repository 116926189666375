import React, { useState, useEffect } from "react";
import { CircularProgress, Checkbox } from "@material-ui/core";
import { useQuery, gql, useMutation } from "@apollo/client";
import { useHistory, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import ArrowBackRoundedIcon from "@material-ui/icons/ArrowBackRounded";
import LocationOnOutlinedIcon from "@material-ui/icons/LocationOnOutlined";
import WorkOutlineOutlinedIcon from "@material-ui/icons/WorkOutlineOutlined";
import MonetizationOnOutlinedIcon from "@material-ui/icons/MonetizationOnOutlined";
import CreateOutlinedIcon from "@material-ui/icons/CreateOutlined";
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import { EmailOutlined } from '@material-ui/icons';
import numbro from "numbro";

import AppTextArea from "../../../components/molecules/AppTextArea"
import AppText from "../../../components/atoms/AppText";
import AppButton from "../../../components/molecules/AppButton";
import JobFilter from "../../../components/molecules/JobFilter";
import { AccessTime } from "@material-ui/icons";
import Candidates from "../../../components/orgnisms/Candidates";
import { isClosed, getDate } from "../../../utils/functions/index"
import AppDropDownButton from "../../../components/molecules/AppDropDownButton";
import MessageModal from "../../../components/templates/MessageModal";
import SendModal from "../../../components/templates/SendModal";
import Recipients from "../../../components/atoms/Recipients"

const GET_APPLICATIONS = gql`
  query($input: ID!) {
    getApplicationById(jobId: $input) {
      id
      candidate {
        id
        photoUrl
        videoUrl
        embedCode
        firstName
        lastName
        city
        country
        embedCode
        summary
        experience {
          myRole
          company {
            name
            logoUrl
            location
          }
          startDate
          endDate
          workHere
        }
        education {
          degree
          field
          school
          startDate
          endDate
        }
        skills {
          label
          value
        }
      }
      status
    }
  }
`;

const SET_PUBLISH_JOB = gql`
  mutation($input: _JobUpdateInput) {
    updateJobOne(input: $input) {
      id
    }
  }
`;

const UPDATE_JOB_STATUS_MULT = gql`
  mutation ($input: UpdateStatusMultipleInput) {
    updateApplicationStatusMultiple(input: $input)
  }
`;

const SEND_STATUS_NOTIFICATION = gql`
  mutation ($input: NoticationInput) {
    sendNotification(input: $input) {
      id
    }
  }
`;

const START_CHAT = gql`
mutation($input: ChatInput) {
  openChat(input: $input)
}
`
const SEND_MESSEGE = gql`
  mutation ($input: MessageInput) {
    sendMessage(input: $input) {
      id
    }
  }
`;

export default function PostJob() {
  const { state } = useLocation();
  const history = useHistory();
  if (state === undefined) {
    history.push('/')
  }
  const job = state;
  const { user } = useSelector((state) => state.app);
  const [currentFilter, setCurrentFilter] = useState("all");
  const [isCheck, setIsCheck] = useState(false);
  const [selectedCandidate, setSelectedCandidate] = useState([])
  const [publishJob] = useMutation(SET_PUBLISH_JOB);
  const [isLoading, setIsLoading] = useState(false);
  const [isPublish, setIsPublish] = useState(job?.published);
  const [messageText, setMessageText] = useState('')
  const [showMore, setShowMore] = useState(false)
  const [startChat] = useMutation(START_CHAT)
  const [openMessageModal, setOpenMessageModal] = useState(false)
  const [openSendModal, setOpenSendModal] = useState(false)
  const [applications, setApplications] = useState({
    all: [],
    shortListed: [],
    new: [],
    interview: [],
    offered: [],
    rejected: [],
  });
  const closeDate = job?.closingDate ? getDate(job?.closingDate) : null


  const { loading, data, refetch } = useQuery(GET_APPLICATIONS, {
    variables: { input: job?.id },
    fetchPolicy: "no-cache"
  });

  const [updateApplicationStatusMultiple] = useMutation(UPDATE_JOB_STATUS_MULT);
  const [sendStatusNotification] = useMutation(SEND_STATUS_NOTIFICATION);
  const [sendMessage] = useMutation(SEND_MESSEGE);

  const navOption = [
    { name: "All Candidates", count: applications.all.length, key: "all" },
    // { name: "Shortlisted Candidates", count: applications.shortListed.length, key: "shortListed" },
    { name: "New", count: applications.new.length, key: "new" },
    { name: "Interview", count: applications.interview.length, key: "interview" },
    { name: "Offered", count: applications.offered.length, key: "offered" },
    { name: "Unsuccessful", count: applications.rejected.length, key: "rejected" },
  ];

  useEffect(() => {
    if (!loading && data) {
      const all = data.getApplicationById;
      const brandNew = data?.getApplicationById?.filter((el) => el.status === 1);
      const interview = data?.getApplicationById?.filter((el) => el.status === 2);
      const offered = data?.getApplicationById?.filter((el) => el.status === 3);
      const rejected = data?.getApplicationById?.filter((el) => el.status === 4);
      setApplications({ all, shortListed: [], new: brandNew, interview, offered, rejected });
    }
  }, [loading, data, refetch]);

  const goBack = () => {
    history.push(`/jobs`);
  };
  const handleEditJob = () => {
    history.push(`/create-job`, job);
  };
  const handlePublish = () => {
    setIsLoading(true)
    setIsPublish(!isPublish)
    publishJob({ variables: { input: { id: job?.id, published: !isPublish } } });
    setIsLoading(false)
  };

  const handleClick = () => {
    if (!isCheck === false) {
      setSelectedCandidate([])
    }
    else if (!isCheck === true) {
      setSelectedCandidate(applications[currentFilter])
    }
    setIsCheck(!isCheck)
  }

  const handleIndividualClick = (candidate, state) => {
    if (!state === false) {
      setSelectedCandidate(selectedCandidate.filter((item) => candidate.candidate.id !== item.candidate.id))
      setIsCheck(selectedCandidate.filter((item) => candidate.candidate.id !== item.candidate.id).length ? true : false)
    }
    else if (!state === true) {
      setSelectedCandidate([...selectedCandidate, candidate])
      setIsCheck(true)
    }
  }

  const handleChangeFilter = (value) => {
    setCurrentFilter(value)
    setSelectedCandidate([])
    setIsCheck(false)
  }

  const onChageButton = (option) => {
    const ids = selectedCandidate.map((item) => {
      return item?.id
    });
    const selected = selectedCandidate
    setSelectedCandidate([])
    setIsCheck(false)
    updateApplicationStatusMultiple({ variables: { input: { ids, status: option.value } } }).then(() => {
      refetch({ variables: { input: job.id } })
      for (let i in selected) {
        sendStatusNotification({
          variables: {
            input: {
              string: `${user.roles[0].company.name} changed the status of your application`,
              action: 3,
              toUser: selected[i].candidate.id,
              senderFullName: user.fullName,
              senderPhotoUrl: user.AvatarUrl || "",
            },
          },
        });
      }
    })
  };

  const handleCloseModal = () => {
    setOpenMessageModal(false)
    setShowMore(false)
    setOpenSendModal(false)
  }

  const openChat = (candidate) => {
    setOpenMessageModal(true)
    setMessageText('')
    if (!selectedCandidate.length) {
      setSelectedCandidate([candidate])
      setIsCheck(true)
    }
  }

  const handlleSendMessage = () => {
    if (messageText) {
      for (let i in selectedCandidate) {
        startChat({ variables: { input: { seeker: selectedCandidate[i].candidate.id, manager: user.id } } }).then(({ data }) => {
          sendMessage({
            variables: {
              input: {
                senderId: user.id,
                receiverId: selectedCandidate[i].candidate.id,
                conversationId: data.openChat,
                messageType: "text",
                messageContent: messageText,
              },
            },
          }).then(() => {
            sendStatusNotification({
              variables: {
                input: {
                  string: messageText,
                  action: 1,
                  toUser: selectedCandidate[i].candidate.id,
                  senderFullName: user.fullName,
                  senderPhotoUrl: user.avatarUrl || "",
                },
              },
            });
          });
        })
      }
      setOpenSendModal(true)
    }
    setOpenMessageModal(false)
    setShowMore(false)
  }

  const handleRemoveMessage = (candidate) => {
    setSelectedCandidate(selectedCandidate.filter((item) => candidate.candidate.id !== item.candidate.id))
    setIsCheck(selectedCandidate.filter((item) => candidate.candidate.id !== item.candidate.id).length ? true : false)
    if (selectedCandidate.length <= 1) {
      setOpenMessageModal(false)
      setShowMore(false)
    }
  }

  const handleGoToMessage = () => {
    setOpenMessageModal(false)
    setShowMore(false)
    setOpenSendModal(false)
    history.push('/messages')
  }

  return (
    <div className={"d-flex flex-column overview_background mt-5"}>
      {/* SubHeader */}
      <div className='w-100 bg-white _create_job shadow-sm d-flex justify-content-center'>
        <div className='w-80 pt-3 pb-3 mt-3 mb-3'>
          <div className={"d-flex justify-content-between align-items-center"}>
            <div className='d-flex'>
              <ArrowBackRoundedIcon
                style={{ color: "#6B6C6F", fontSize: 25, marginRight: "20px", cursor: "pointer" }}
                onClick={goBack}
              />
              <div>
                <AppText size='_18'>{job?.jobTitle}</AppText>
                <div className={"d-flex mt-2"}>
                  <div className={"d-flex align-items-center mr-4"}>
                    <LocationOnOutlinedIcon
                      style={{ color: "#a0a2a6", fontSize: 15, marginRight: "5px" }}
                    />
                    <AppText size='_12' weight='_small' color='_c25'>
                      {job?.location}
                    </AppText>
                  </div>
                  <div className={"d-flex align-items-center mr-4"}>
                    <WorkOutlineOutlinedIcon
                      style={{ color: "#a0a2a6", fontSize: 15, marginRight: "5px" }}
                    />
                    <AppText size='_12' weight='_small' color='_c25'>
                      {job?.employmentType}
                    </AppText>
                  </div>
                  <div className={"d-flex align-items-center mr-4"}>
                    <MonetizationOnOutlinedIcon
                      style={{ color: "#a0a2a6", fontSize: 15, marginRight: "5px" }}
                    />
                    {job?.salaryRange?.defaultType !== 'hourly' ? (
                      <AppText size='_12' weight='_small' color='_c25'>
                        £
                        {job?.salaryRange?.start !== 0 ?
                          (job?.salaryRange?.start < 1000 ?
                            numbro(job?.salaryRange?.start).format({ mantissa: 2 }) :
                            numbro(job?.salaryRange?.start).format({ thousandSeparated: true, average: true, })
                          ) : null
                        }
                        {job?.salaryRange?.start !== 0 ? " - £" : ""}
                        {job?.salaryRange?.end < 1000 ?
                          numbro(job?.salaryRange?.end).format({ mantissa: 2 }) :
                          numbro(job?.salaryRange?.end).format({
                            thousandSeparated: true,
                            average: true,
                          })}
                      </AppText>
                    ) : (
                      <AppText size='_12' weight='_small' color='_c25'>
                        £
                        {job?.salaryRange?.end < 1000 ?
                          numbro(job?.salaryRange?.end).format({ mantissa: 2 }) :
                          numbro(job?.salaryRange?.end).format({
                            thousandSeparated: true,
                            average: true,
                          })}/hr
                      </AppText>
                    )
                    }
                  </div>
                  {job?.closingDate && (
                    <div className={"d-flex align-items-center mr-4"}>
                      <AccessTime style={{ color: "#a0a2a6", fontSize: 15, marginRight: "5px" }} />
                      <AppText size='_12' weight='_small' color='_c25'>
                        {isClosed(job?.closingDate)} {closeDate}
                      </AppText>
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className={"d-flex"}>
              <div className={"mr-3"}>
                <button
                  className={
                    "border-1 shadow-sm rounded bg-white d-flex align-items-center p-2 justify-content-center pl-4 pr-4"
                  }
                  onClick={handleEditJob}
                  style={{ border: "1px solid #d8dce6", height: "40px" }}
                >
                  <CreateOutlinedIcon
                    style={{ color: "#a0a2a6", fontSize: 15, marginRight: "5px" }}
                  />
                  <AppText size='_14' color='_c50' weight='_medium'>
                    Edit
                  </AppText>
                </button>
              </div>
              <AppButton type='submit' onClick={handlePublish}>
                {isLoading ? (
                  <AppText color='_c0' size='_14' weight='_medium'>
                    loading...
                  </AppText>
                ) : (
                  <AppText color='_c0' size='_14' weight='_medium'>
                    {!isPublish ? "Publish" : "Unpublish Job"}
                  </AppText>
                )}
              </AppButton>
            </div>
          </div>
        </div>
      </div>

      <div className='w-100 mt-4 d-flex justify-content-center'>
        <div
          className='w-80 bg-white shadow-sm rounded mb-4'
          style={{ border: "1px solid #eaedf3" }}
        >
          <div className='d-flex botm flex-wrap'>
            {navOption.map((opt) => (
              <JobFilter
                key={opt.name}
                text={opt.name}
                number={opt.count}
                optKey={opt.key}
                onClick={() => handleChangeFilter(opt.key)}
                isSelect={currentFilter}
              />
            ))}
          </div>
          {applications[currentFilter]?.length > 0 ? (
            <div className='d-flex p-3 botm align-items-center'>
              <Checkbox
                className={'mr-2'}
                name='checkBox'
                value='checkBox'
                checked={isCheck}
                onChange={handleClick}
                icon={<CheckBoxOutlineBlankIcon style={{ color: "#D8DCE6" }} />}
                checkedIcon={<CheckBoxIcon style={{ color: "#03CA98" }} />}
                style={{
                  color: "#eeeeee",
                  padding: 0,
                }}
              />
              {selectedCandidate.length ? (
                <AppText size='_16' weight='_small' color='_c100'>
                  {selectedCandidate.length} Candidates Selected
                </AppText>
              ) : null}
              {selectedCandidate.length > 1 ? (
                <div className="ml-3 d-flex align-items-center pointer" onClick={openChat}>
                  <AppText size='_14' weight='_medium' color='_c100'>
                    Message ({selectedCandidate.length})
                  </AppText>
                </div>
              ) : null}
              {selectedCandidate.length ? (
                <AppDropDownButton
                  onChange={(value) => onChageButton(value)}
                  border={false}
                  buttonOption={"Change Status"}
                  buttonOptions={[
                    {
                      label: (
                        <div className={'d-flex align-items-center'}>
                          <AppText size='_20' weight={'_medium'}>•</AppText>
                          <spam className={'ml-2'}>
                            <AppText size='_14'>Interview</AppText>
                          </spam>
                        </div>),
                      value: 2
                    },
                    {
                      label: (
                        <div className={'d-flex align-items-center'}>
                          <AppText size='_20' color={'_g100'} weight={'_medium'}>•</AppText>
                          <spam className={'ml-2'}>
                            <AppText size='_14'>Offer</AppText>
                          </spam>
                        </div>),
                      value: 3
                    },
                    {
                      label: (
                        <div className={'d-flex align-items-center'}>
                          <AppText size='_20' weight={'_medium'} color={'_r100'}>•</AppText>
                          <spam className={'ml-2'}>
                            <AppText size='_14'>Unsuccessful</AppText>
                          </spam>
                        </div>),
                      value: 4
                    }
                  ]}
                />
              ) : null}

            </div>
          ) : loading ? (
            <div className='pt-4 d-flex justify-content-center'>
              <CircularProgress style={{ color: "#EAECEE" }} />
            </div>
          ) : (
            <div className='pt-4 d-flex justify-content-center'>
              <AppText>This job has no {currentFilter} candidates</AppText>
            </div>
          )}
          <div className='p-3'>
            {applications[currentFilter]?.map((candidate, i) => {
              let isLastOne = false;
              if (i + 1 === applications[currentFilter].length) {
                isLastOne = true;
              }
              return (
                <div key={candidate.candidate.id}>
                  <Candidates
                    candidate={candidate}
                    isLastOne={isLastOne}
                    refetch={refetch}
                    job={job}
                    onClick={handleIndividualClick}
                    selectedCandidate={selectedCandidate}
                  />
                </div>
              );
            })}
          </div>
        </div>
      </div>
      {openMessageModal ? (
        <MessageModal title={'New Message'} onClose={() => handleCloseModal()} width={'100%'}>
          <div className={'d-flex flex-column'}>
            <div className={'px-3'}>
              <AppTextArea
                label='MESSAGE'
                placeholder='Type your Message'
                editable={true}
                onChange={(e) => setMessageText(e.target.value)}
                value={messageText}
                name='companyBio'
                height={"225px"}
              />
            </div>
            <div className={'d-flex flex-column _recipients py-4 mt-4'}>
              <span className={'px-3'}>
                <AppText size={'_12'} weight={'_medium'}>RECIPIENTS</AppText>
              </span>
              <div className={'px-3 d-flex flex-wrap'}>
                {selectedCandidate.map((candidate, index) => {
                  if (index <= 2) {
                    return (
                      <Recipients candidate={candidate} onClick={handleRemoveMessage} />
                    )
                  } else if (showMore) {
                    return (
                      <Recipients candidate={candidate} onClick={handleRemoveMessage} />
                    )
                  }
                  return null
                })}
                {(!showMore && selectedCandidate.length > 3) ? (
                  <span
                    className={'pointer d-flex justify-content-center align-items-center mt-2'}
                    onClick={() => setShowMore(true)}
                  >
                    <AppText color={'_g100'} size={'_14'}> +{selectedCandidate.length - 3} more</AppText>
                  </span>
                ) : null}
                {(showMore && selectedCandidate.length > 3) ? (
                  <span
                    className={'pointer d-flex justify-content-center align-items-center mt-2'}
                    onClick={() => setShowMore(false)}
                  >
                    <AppText color={'_g100'} size={'_14'}> show less</AppText>
                  </span>
                ) : null}
              </div>
            </div>
            <div className={'d-flex py-3 justify-content-end align-items-center'}>
              <div className={'mr-2'}>
                <AppButton color='_white' onClick={() => handleCloseModal()}>
                  <AppText size='_14'>Cancel</AppText>
                </AppButton>
              </div>
              <div className={'mr-3'}>
                <AppButton color='_white' onClick={() => handlleSendMessage()}>
                  <AppText size='_14'>Send</AppText>
                </AppButton>
              </div>
            </div>
          </div>
        </MessageModal>
      ) : null}
      {openSendModal ? (
        <SendModal onClose={() => handleCloseModal()} width={'100%'}>
          <div className={'d-flex flex-column justify-content-center align-items-center'}>
            <EmailOutlined
              style={{ color: "#07BF91", fontSize: 40, marginBottom: '10px' }}
            />
            <AppText size={'_14'}>
              Your message has been sent!
            </AppText>
            <div className={'d-flex justify-content-around align-items-center mt-3 pb-3 w-100'}>
              <AppButton color='_white' onClick={() => handleGoToMessage()}>
                <AppText size='_14'>Go to messages</AppText>
              </AppButton>
              <AppButton color='_white' onClick={() => handleCloseModal()}>
                <AppText size='_14'>Continue viewing candidates</AppText>
              </AppButton>
            </div>
          </div>
        </SendModal>
      ) : null}
    </div>
  );
}
