import React, { useState, useLayoutEffect, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";
import ReactInputVerificationCode from "react-input-verification-code";
import PinCode from "generate-pincode";
import { gql, useLazyQuery, useMutation } from "@apollo/client";
import query from "query-string";
import BasicAlerts from "../../../components/molecules/BasicAlerts";
import AppText from "../../../components/atoms/AppText";
import AppButton from "../../../components/molecules/AppButton";
import { changeAppState } from "../../ducks";
import { fire } from "../../../firebase";
import { CircularProgress } from "@material-ui/core";

const SEND_CODE = gql`
  query sendCode($input: Code) {
    sendCodeVerification(inputCode: $input)
  }
`;
const SET_USER = gql`
  mutation ($input: ManagerInput) {
    createManager(input: $input) {
      id
    }
  }
`;

// export const SEND_SLACK_ALERT = gql`
// query($name: String!, $platform: Int!) {
//   sendSlackAlert(name: $name, platform: $platform)
// }`

const VERIFY_INVITE = gql`
  mutation ($input: verifyInviteInput) {
    verifyInvitation(input: $input) {
      id
      fullName
      email
      emailVerified
      accountComplete
      avatarUrl
      messagesLastSeen
      notificationsLastSeen
      roles {
        id
        company {
          id
        }
        as
      }
    }
  }
`;

export default function EmailVerification() {
  const isDesktop = useMediaQuery({ minWidth: 992 });
  const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 991 });

  const pin = PinCode(5);
  const qc = query.parse(window.location.search);

  const [code, setCode] = useState("");
  const [error, setError] = useState(null);
  const [sendCode, { called, loading, data, refetch }] =
    useLazyQuery(SEND_CODE);
  // const [sendSlackAlert] = useLazyQuery(SEND_SLACK_ALERT);
  const [createManager] = useMutation(SET_USER);
  const [verifyInvite] = useMutation(VERIFY_INVITE);
  // const [updateManger] = useMutation(UPDATE_MANAGER);
  const [isLoading, setIsLoading] = useState(false);
  const [resent, setIsResent] = useState(false);
  const auth = useSelector((state) => state.auth);

  const dispatch = useDispatch();

  useLayoutEffect(() => {
    if (fire.auth().currentUser) {
      const { email } = fire.auth().currentUser;
      sendCode({ variables: { input: { email, value: pin } } });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const verifyCode = () => {
    setIsLoading(true);
    const { displayName, email, uid } = fire.auth().currentUser;
    if (called && !loading && data) {
      if (data.sendCodeVerification.trim() === code.trim()) {
        if ((auth.token || qc.token) && (auth.ci || qc.ci)) {
          createManager({
            variables: {
              input: {
                fullName: displayName,
                firstName: displayName.split(" ")[0],
                lastName: displayName.split(" ")[1],
                email: email,
                uid,
              },
            },
          }).then((data) => {
            verifyInvite({
              variables: {
                input: {
                  companyId: auth.ci || qc.ci,
                  uid,
                  as: "Member",
                  token: auth.token || qc.token,
                },
              },
            }).then(() => {
              dispatch(changeAppState({ emailverified: true }));
            });
            // sendSlackAlert({
            //   variables: {
            //     name: displayName,
            //     platform: 2,
            //   },
            // });
          });
        } else {
          createManager({
            variables: {
              input: {
                fullName: displayName,
                firstName: displayName.split(" ")[0],
                lastName: displayName.split(" ")[1],
                email: email,
                uid,
              },
            },
          }).then(() => {
            dispatch(changeAppState({ emailverified: true }));
            setIsLoading(false);
          });
          // sendSlackAlert({
          //   variables: {
          //     name: displayName,
          //     platform: 2,
          //   },
          // });
        }
      } else {
        setIsLoading(false);
        setError("Incorrect verification code entered");
      }
    }
  };

  const resendCode = () => {
    const email = fire.auth().currentUser.email;
    refetch({ variables: { input: { email, value: pin } } });
    setIsResent(true);

    // setIsChanged(true);
  };

  const handleCloseAlert = () => {
    setIsResent(false)
  }

  useEffect(() => {
    if (resent) {
      setTimeout(() => {
        setIsResent(false);
      }, 3000);
    }
  }, [resent]);

  return (
    <div className={'background d-flex flex-column w-100'}>
      {resent && (
        <div className={"w-100 d-flex align-items-center justify-content-center position-absolute"} style={{marginTop:'33px'}}>
          <BasicAlerts text={'Verification code has been resent to your email address'} onClose={handleCloseAlert}></BasicAlerts>
        </div>
      )}
      <div className="background d-flex flex-column align-items-center justify-content-center">
        <div className={isDesktop ? "w-25" : isTablet ? "w-60" : "w-90"}>
          <div className="bg-white p-4 boxy shadow-sm rounded">
            <div>
              <AppText size="_22">Verify Account</AppText>
              <div>
                <AppText size="_16" color="_c50">
                  Enter the 5-digits sent to your email
                </AppText>
              </div>
            </div>
            <div className="my-3 custom-styles">
              <ReactInputVerificationCode
                className="boxx"
                length={5}
                onChange={(value) => setCode(value)}
              />
            </div>
            {error ? (
              <div className={"mb-1"}>
                <AppText size="_16" color="_r100">
                  {error}
                </AppText>
              </div>
            ) : null}

            <AppButton onClick={verifyCode} width="_full">
              {isLoading ? (
                <CircularProgress
                  style={{ color: "#fff", height: "30px", width: "30px" }}
                />
              ) : (
                <AppText size="_14" color="_c0">
                  Verify Code
                </AppText>
              )}
            </AppButton>
          </div>
          <div className="mt-4 pl-4">
            <AppText size="_14" color="_c50">
              Didn't receive a code?{" "}
              <span onClick={resendCode} className="_g90 pointer">
                Resend Code
              </span>
            </AppText>
          </div>
        </div>
      </div>
    </div>
  );
}
