import React, { useRef, useEffect } from 'react'
import AppText from '../atoms/AppText'
import moment from 'moment'
import { WorkOutline } from "@material-ui/icons";

export default function Experiences({ title, experiences, isSelect }) {
    const elRef = useRef(null);
    useEffect(() => {
        const handleScroll = () => {
            if (elRef?.current?.getBoundingClientRect()?.top - 100 <= 0 && 100 + elRef?.current?.getBoundingClientRect()?.top >= 1) {
                isSelect('Work-Experience')
            }
        }
        document.addEventListener('scroll', handleScroll);
        return () => {
            document.removeEventListener('scroll', handleScroll);
        }
    }, [isSelect])

    return (
        <div className={`mt-3 mb-4 w-100 card_style`} key={'Experiences'} id={'Work-Experience'} ref={elRef}>
            {/* Header */}
            <header className={'p-4'}>
                <AppText size='_18' weight='_medium'>
                    {title}
                </AppText>
            </header>
            <div className='_sep_' />
            {experiences
                .sort((a, b) => a.startDate > b.startDate ? -1 : a.startDate < b.startDate ? 1 : 0)
                .map((item, i) => (
                    <div className={'p-3'}>
                        <div className={'d-flex'}>
                            <div className='mr-3'>
                                {item.company.logoUrl === "0" ? (
                                    <div className='_emp_logo'>
                                        <WorkOutline style={{ fontsSize: "22px" }} />
                                    </div>
                                ) : (
                                    <img
                                        src={item.company.logoUrl}
                                        alt='com'
                                        height={"46"}
                                        width={"46"}
                                    />
                                )}
                            </div>
                            <div className={'w-100 d-flex justify-content-between'}>
                                <div className={'d-flex flex-column'}>
                                    <AppText size='_18' weight='_medium'>
                                        {item.myRole}
                                    </AppText>
                                    <div className={'d-flex'}>
                                        <AppText size='_14' weight='_medium'>
                                            {item.company.name} {' '}
                                            <AppText size='_14' weight='_medium' color={'_c50'}>
                                                • {item.company.location}
                                            </AppText>
                                        </AppText>
                                    </div>
                                </div>
                                <div className={'d-flex flex-column'}>
                                    <div className={'d-flex justify-content-end'}>
                                        <span className={'p-1 px-2 border rounded shadows-sm d-flex justify-content-center'}>
                                            <AppText size='_12' weight='_medium'>
                                                Full time
                                            </AppText>
                                        </span>
                                    </div>
                                    <AppText size='_14' color={'_c50'}>
                                        {moment(item.startDate).format('MMM YYYY')}
                                        {' - '}
                                        {item.workHere ? 'Present' : moment(item.endDate).format('MMM YYYY')}
                                    </AppText>
                                </div>
                            </div>
                        </div>
                        {i + 1 !== experiences.length ? (<div className='_sep_ mt-3' />) : <div className='mt-3' />}
                    </div>
                ))}
        </div>
    )
}