import { gql } from "@apollo/client";

const GET_MY_JOBS = gql`
  query ($input: MyJobInput) {
    getMyJobs(input: $input) {
      count
      jobs {
        id
        jobTitle
        location
        createdAt
        experienceLevel
        employmentType
        applicationsCount
        applyLinkUrl
        mapCordinates {
          coordinates
        }
        published
        industry
        skills
        description
        salaryRange {
          defaultType
          start
          end
        }
        closingDate
        saved
        remotePossible
        assignedTo {
          id
          fullName
          avatarUrl
          email
        }
      }
    }
  }
`;
const GET_CAREER_PAGES_STATUS = gql`
  query ($companyId: ID!) {
    getCareerPageStatus(companyId: $companyId) {
      id
      status
      JobsToIframe
    }
  }
`;

export { GET_MY_JOBS, GET_CAREER_PAGES_STATUS };
